import axios from 'axios'

//const API_URL = "https://www.apilaquinta.site/";
const API_URL = "https://www.apilados.site/";

const instance = axios.create({
    withCredentials: false,
    baseURL: API_URL,
    headers: {
        'Authorization': localStorage.getItem("token"),
        'Content-Type': 'application/json'
    }
})

export default instance