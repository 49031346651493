import InstaceAxios  from "./Api";

export const getPlanesPago = async () => {
    const result = await InstaceAxios.get(`plan_pago/planes`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getPlanPago = async (id_plan = null) => {
    const result = await InstaceAxios.get(`plan_pago/planes?id_plan_pago=${id_plan}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const guardarPlanPago = async (data) => {
    const result = await InstaceAxios.post('plan_pago/crear', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const editarPlanPago = async (data) => {
    const result = await InstaceAxios.post('plan_pago/editar', data)
        .then(res => res)
        .catch(error => error)
    return result
}
