import React from 'react';
import { connect } from 'react-redux';
import { setIdPlanPago } from '../../../../actions'

function Tabla(props){

    return(
        <React.Fragment>
            <div className="table-responsive">
                <table className="table table-hover table-sm">
                    <thead>
                        <tr>
                            <th className="text-center">ID</th>
                            <th className="text-left">Nombre</th>
                            <th className="text-left">Fecha Ceacion</th>
                            <th className="text-center">Estado</th>
                            <th className="text-center">Modalidades</th>
                            <th className="text-center">Ver</th>
                        </tr>
                    </thead>
                    <tbody>
                    {Array.isArray(props.tablaPlanesPago) ? props.tablaPlanesPago.map((plan, index) => (
                        <tr key={index}>
                            <td className="text-center">{plan.id_plan_pago}</td>
                            <td className="text-left">{plan.nombre}</td>
                            <td className="text-center">{plan.fecha_creacion}</td>
                            <td className="text-center">
                                {plan.estado_id == '1' ?<p className='text-success'>activo</p> : <p className='text-danger'>inactivo</p>}
                            </td>
                            <td className="text-center">{plan.modalidades.length}</td>
                            <td className="text-center">
                                <button 
                                    type="button" 
                                    className="btn btn-outline-primary btn-sm " 
                                    title="Ver Loteria" 
                                    data-bs-toggle="modal" 
                                    data-bs-target="#modalEditarPlan"
                                    onClick={() => props.setIdPlanPago(plan.id_plan_pago)}
                                    >
                                    <i className="bi bi-gear"></i>
                                </button>
                            </td>
                        </tr>
                    )) : <tr></tr>}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        tablaPlanesPago: state.tablaPlanesPago
    }
}

const mapDispatchToProps  = {
    setIdPlanPago
};

export default  connect(mapSateToProps, mapDispatchToProps)(Tabla);