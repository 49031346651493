import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setIdComercio,setIdComercioGestionado, setGestionando, setIdComercioSeleccionado } from '../../../../actions'
import { alerta, toast, validarEmail, cerrarModal } from '../../../../services/utilities';
import { getUsuarios } from '../../../../services/root'
import { compose } from "redux";

function Tabla (props){

    useEffect(() => {
        handleCargarDataModulo();
    },[]);

    const [spiner, setSpiner] = useState(false);
    const [filter, setFilter] = useState('');
    const [usuarios, setUsuarios] = useState([]);

    const handleComercioSeleccionado = (id_comercio) => {
        props.setIdComercio(id_comercio)
        props.setIdComercioSeleccionado(id_comercio)
    }

    const handleGestionComercio = (id_usuario) => {
        props.setGestionando('SI');
        props.setIdComercioGestionado(id_usuario);
    }

    const handleCargarDataModulo = async () => {

        setSpiner(true);

        try {
            await getUsuarios().then(response => {
                if(response.status === 200){
                    setUsuarios(response.data.usuarios)
                }else{
                    alerta('No fue posible cargar la lista de usuarios','','warning');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de usuarios  (root/lista/usuarios) ->'+ error);
        }finally {
            setSpiner(false);
        }
    }

    const filteredData = usuarios.filter((item) => {

        var aliasMatch  = '';

        if(item.alias !== null)
            aliasMatch = item.alias.toLowerCase().includes(filter.toLowerCase());

        const tipoMatch = item.tipo.toLowerCase().includes(filter.toLowerCase());

        const nicknameMatch = item.nickname.toLowerCase().includes(filter.toLowerCase());
        
        const idMatch = item.id_comercio.toString().includes(filter);

        return idMatch || nicknameMatch || tipoMatch;

    });
    
    return(
        <React.Fragment>
            <div className='col-md-3 mb-4'>
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
                    <input 
                        type="text" 
                        className="form-control form-control-sm" 
                        placeholder="Buscar..." 
                        aria-label="Buscar" 
                        aria-describedby="basic-addon1" 
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)} 
                    />
                </div>
            </div>
            <div className="col-md-12">
                <div className="table-responsive">
                    <table  id="tablaComercios" className="table table-hover table-sm" style={{fontSize:'12px'}}>
                        <thead>
                            <tr className="text-center">
                                <th>Id</th>
                                <th>Usuario</th>
                                <th>Nombre</th>
                                <th>Alias</th>
                                <th>Estado</th>
                                <th>Tipo</th>
                                <th>Empresa</th>
                                <th>Ultimo Ingreso</th>
                                <th>Fecha Crreación</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {Array.isArray(usuarios) ? filteredData.map((usuario, index) => (
                            <tr key={index} className="text-center" >
                                <td>{usuario.id_comercio}</td>
                                <td>{usuario.nickname}</td>
                                <td>{usuario.nombre}</td>
                                <td>{usuario.alias}</td>
                                <td>
                                    {usuario.estado_id == '1' ?<p className='text-success'>Activo</p> : <p className='text-danger'>Inactivo</p>}
                                </td>
                                <td>{usuario.tipo}</td>
                                <td>{usuario.empresa}</td>
                                <td>{usuario.ultimo_ingreso}</td>
                                <td>{usuario.fecha_creacion}</td>                                
                                <td>
                                    <button 
                                        type="button" 
                                        className="btn btn-outline-primary btn-sm " 
                                        title="Editar" 
                                        data-bs-toggle="modal" 
                                        data-bs-target="#modalEditarUsuario"
                                        data-id={usuario.id_usuario}
                                        onClick={(e) => handleComercioSeleccionado(e.target.getAttribute('data-id'))}
                                        >
                                        <i 
                                            className="bi bi-gear"  
                                            data-id={usuario.id_usuario}
                                            onClick={(e) => handleComercioSeleccionado(e.target.getAttribute('data-id'))}
                                        >
                                        </i>
                                    </button>
                                </td>
                                <td>
                                { props.gestionando === 'NO' && usuario.tipo !=='VENDEDOR' ?
                                    <button 
                                        type="button" 
                                        className="btn btn-outline-dark btn-sm " 
                                        title="Gestion Usuario" 
                                        data-id={usuario.id_usuario}
                                        onClick={(e) => handleGestionComercio(e.target.getAttribute('data-id'))}
                                        >
                                        <i 
                                            className="bi bi-eye-fill"  
                                            data-id={usuario.id_usuario} 
                                            onClick={(e) => handleGestionComercio(e.target.getAttribute('data-id'))}
                                        >
                                        </i>
                                    </button>
                                    :''
                                }
                                </td>
                            </tr>
                        )) : <tr></tr>}
                        </tbody>
                    </table>
                    {spiner ?
                        <div className="d-flex justify-content-center align-items-center" style={{height:200}}>
                            <div className="spinner-border" role="status"></div>                 
                        </div>
                    :''}
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        gestionando: state.gestionando,
        tablaComercios: state.tablaComercios,
        data_sesion: state.data_sesion,
        data_usuario: state.data_usuario,
    }
}

const mapDispatchToProps  = {
    setIdComercio,
    setIdComercioGestionado,
    setIdComercioSeleccionado,
    setGestionando
};

export default connect(mapSateToProps,mapDispatchToProps)(Tabla)