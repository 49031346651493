import React, {useEffect, useState} from "react";
import { connect } from 'react-redux';
import { setIdComercio,setIdComercioGestionado, setGestionando, setIdComercioSeleccionado } from '../../../../actions'
import { getListaEmpresas } from '../../../../services/root'
import { alerta, toast, formatDate } from '../../../../services/utilities';

function Lista(props){

    useEffect(() => {
        //handleCargarDataModulo();
    },[]);

    const [spiner, setSpiner] = useState(false);
    const [listaLoterias, setListaLoterias] = useState([]);
    

    return(
        <React.Fragment>
            <>
                <div className="card pt-4">
                    <div className="card-body">
                        <table className="table table-hover">
                            <thead>
                                <th>Nombre</th>
                                <th>Prefijo</th>
                                <th>Oficial</th>
                                <th>Astro</th>
                                <th>Estado</th>
                                <th>WS</th>
                                <th></th>
                            </thead>
                            <tbody>

                            </tbody>
                        </table>
                    </div>
                </div>
                {spiner ?
                    <div className="d-flex justify-content-center align-items-center" style={{height:300}}>
                        <div className="spinner-border" role="status"></div>                 
                    </div>
                :''}
                
            </>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    setIdComercio,
    setIdComercioGestionado,
    setIdComercioSeleccionado,
    setGestionando
};

export default connect(null,mapDispatchToProps)(Lista);