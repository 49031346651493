import InstaceAxios  from "./ApiFile";

export const crearRifa = async (data) => {
    const result = await InstaceAxios.post('rifa/crear', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const editarRifa = async (data) => {
    const result = await InstaceAxios.post('rifa/editar', data)
        .then(res => res)
        .catch(error => error)
    return result
}
