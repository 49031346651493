import React from "react";
import Item from "./item_menu";

function Administracion(props){

    let tiene_rifa = false;

    if(Array.isArray(props.modalidades)){
        tiene_rifa = props.modalidades.some(function(elemento) {
            return elemento.id_categoria_modalidad_juego === 5;
        });
    }

    return(
        <React.Fragment>
            <li className="nav-item">
                <a className="nav-link collapsed" data-bs-target="#administracion-nav" data-bs-toggle="collapse" href="#">
                    <i className="bi bi-gear-wide"></i>
                    <span>Administración</span>
                    <i className="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="administracion-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                    <Item nombre="Loterias" ruta="/admnistracion/loterias"></Item>
                    { parseInt(props.rol_id) === 0 ?
                        <>
                        <Item nombre="Zonas" ruta="/admnistracion/zonas"></Item>
                        <Item nombre="Planes de pago" ruta="/admnistracion/planes"></Item>
                        <Item nombre="Configuración Topes" ruta="/admnistracion/bloqueos"></Item>
                        </>
                    :''}
                    {tiene_rifa ? <Item nombre="Rifas" ruta="/admnistracion/rifas"></Item>:''}
                    <Item nombre="Resultados" ruta="/admnistracion/resultados"></Item>
                    { parseInt(props.rol_id) !== 3 ?
                        <Item nombre="Usuarios" ruta="/admnistracion/usuarios"></Item>
                    :''}
                </ul>
            </li>
        </React.Fragment>
    );
}

export default Administracion;