import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Header from "../../../header/Header";
import Aside from "../../../aside/Aside";
import HeaderModulo from "../../../header/HeaderModulo";
import { alerta, toast, formatDate } from '../../../../services/utilities';
import { getReporteConsumoSMS } from '../../../../services/Reportes'

function Sms(props){

    const [dataConsumo, setSDataConsumo] = useState([]); 
    const [verTable, setVerTable] = useState(false);
    const [spinerTabla, setSpinerTabla] = useState(false);
    const [verMnesaje, setVerMensaje] = useState(true);
    const [filtro, setFiltro] = useState({
        "fecha_ini": formatDate(new Date()),
        "fecha_fin": formatDate(new Date()),
        "agrupado": ""
    });

    const handleInputFiltroChange = (event) => {
        const { name, value } = event.target;
        setFiltro({ ...filtro, [name]: value });
    };

    const handleBuscarSms = async () => {

        setVerMensaje(false);
        setSpinerTabla(true);
        setVerTable(false);

        try {
            await getReporteConsumoSMS(filtro.fecha_ini, filtro.fecha_fin, filtro.agrupado).then( response => {

                if(response.status !== undefined && response.status === 200){  
                    if(response.data.data.length > 0){
                        setSDataConsumo(response.data.data);
                        setVerTable(true);
                    }else{
                        setVerMensaje(true)
                        setVerTable(false);
                    }
                }else{
                    toast('Error al ejecutar la consulta','');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de sms  (operaciones/sms/handleBuscarSms) ->'+ error);
        }finally {
            setSpinerTabla(false);
        }
    }

    var cantidad = 0;

    if(Array.isArray(dataConsumo)){
        dataConsumo.map((datos) => {
            cantidad = cantidad + datos.cantidad_sms;
        });
    }

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <HeaderModulo nombre_modulo={props.modulo}></HeaderModulo>
                <section className="section">
                    <div className="row">
                        <div className='col-md-3 mb-3'>
                            <label className="mb-1">Desde:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_ini" 
                                    name="fecha_ini" 
                                    value={filtro.fecha_ini}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label className="mb-1">Hasta:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_fin" 
                                    name="fecha_fin" 
                                    value={filtro.fecha_fin}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Agrupar</label>
                            <div className='input-group'>
                                <select 
                                    className="form-select form-control"
                                    name="agrupado"
                                    value={filtro.agrupado}
                                    onChange={handleInputFiltroChange}
                                >
                                    <option value="">Todo</option>
                                    <option value="VENDEDOR">Por Vendedor</option>
                                    <option value="PROMOTOR">Por Promotor</option>
                                    <option value="ADMIN-EXTERNO">Por Socio Emp</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-1">
                            <label className="mb-1">&emsp;</label>
                            <div className="input-group">
                                <button 
                                    type="button" 
                                    className="btn btn-outline-primary btn-md"
                                    onClick={handleBuscarSms}
                                >
                                    Buscar
                                </button>
                            </div>
                        </div>
                    </div>                   
                    {verTable ?
                        <>
                            <div className="div-card-totales">
                                <div className="card-totales">
                                    <h6 className="m-0"><b>Total SMS</b></h6>
                                    <h6 className="text-secondary m-0"><b>{cantidad}</b></h6>
                                </div>
                            </div>
                            <div className="card" style={{ overflow : 'auto', height:"500px"}}>
                                <div className="card-body">
                                    <div className='table-container'>
                                        <div className="table-responsive-sm">
                                            <table className='table table-hover text-center'>
                                                <thead>
                                                    <tr>
                                                        <td><b># Venta</b></td>
                                                        <td><b># Juego</b></td>
                                                        <td><b>Fecha</b></td>
                                                        <td><b>Hora</b></td>
                                                        <td><b>Vendedor</b></td>
                                                        <td><b>Promotor</b></td>
                                                        <td><b>Socio Empresa</b></td>
                                                        <td><b>Cantidad SMS</b></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {Array.isArray(dataConsumo) ? dataConsumo.map((data, index) => (
                                                    <tr key={index}>
                                                        <td><span className="badge text-bg-warning"># {data.id_transaccion}</span></td>
                                                        <td><span className="badge text-bg-primary"># {data.id_juego}</span></td>
                                                        <td><span className="badge text-bg-dark">{data.fecha}</span></td>
                                                        <td><span className="badge text-bg-dark">{data.hora}</span></td>
                                                        <td style={{fontSize : '12px'}}>{data.nombre_comercio}</td>
                                                        <td style={{fontSize : '12px'}}>{data.nombre_promotor}</td>
                                                        <td style={{fontSize : '12px'}}>{data.nombre_admin_externo}</td>
                                                        <td>{data.cantidad_sms}</td>
                                                    </tr>
                                                )) : ''}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    :''}
                    {spinerTabla ?
                    <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '200px'}}>
                        <div className="spinner-border" role="status"></div>                 
                    </div>
                    :''}
                    {verMnesaje ?
                    <div className='text-center text-secondary my-4'>
                        <h3>Sin resultado de búsqueda para los filtros aplicados.</h3>
                        <p>Por favor elija una combinación de filtros diferente.</p>
                    </div>
                    :''}
                </section>
            </main>
        </React.Fragment>
    );
}

export default Sms;