import InstaceAxios  from "./Api";

export const data_general_usuario = async (id_usuario) => {
    const result = await InstaceAxios.get(`usuario/data_general_usuario?id_usuario=${id_usuario}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const validar_nickname = async (nicknam) => {
    const result = await InstaceAxios.get(`usuario/validar_nickname?nickname=${nicknam}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const editarUsuarioPerfil = async (data) => {
    const result = await InstaceAxios.post('usuario/editar_perfil', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const cambiarPassword = async (data) => {
    const result = await InstaceAxios.post('usuario/cambiar_password', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const crerarComercio = async (data) => {
    const result = await InstaceAxios.post('usuario/crear/comercio', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const editarComercio = async (data) => {
    const result = await InstaceAxios.post('usuario/editar/comercio', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getComercios = async () => {
    const result = await InstaceAxios.get(`usuario/comercios`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getComercio = async (id_comercio = false) => {
    const result = await InstaceAxios.get(`usuario/comercio?id_comercio=${id_comercio}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getCategoriasModalidadJuego = async () => {
    const result = await InstaceAxios.get(`usuario/categorias_modalidad_juego`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getModalidadJuegoEmpresa = async () => {
    const result = await InstaceAxios.get(`usuario/modalidad_juego_empresa`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const prestamoComercio = async (data) => {
    const result = await InstaceAxios.post('usuario/prestamo', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getSaldos = async (id_comercio) => {
    const result = await InstaceAxios.get(`usuario/saldos?id_comercio=${id_comercio}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const ajusteComercio = async (data) => {
    const result = await InstaceAxios.post('usuario/ajuste', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const recargaPrepago = async (data) => {
    const result = await InstaceAxios.post('usuario/prepago', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const validarSegundaClave = async (segunda_clave) => {
    const result = await InstaceAxios.get(`usuario/validar_segunda_clave?segunda_clave=${segunda_clave}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getResumenSaldosDashboard = async () => {
    const result = await InstaceAxios.get(`usuario/dashboard`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const get_comercio = async (id_comercio = 0) => {
    const result = await InstaceAxios.get('usuario/comercio')
        .then(res => res)
        .catch(error => error)
    return result
}
