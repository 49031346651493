export const setDataSesion = payload => ({
    type: "SET_DATA_SESION",
    payload,
});

export const setDataUsuario = payload => ({
    type: "SET_DATA_USUARIO",
    payload,
});

export const setTablaLoteria = payload => ({
    type: "SET_TABLA_LOTERIAS",
    payload,
});

export const setIdLoteria = payload => ({
    type: "SET_ID_LOTERIA",
    payload,
});

export const setLoteria = payload => ({
    type: "SET_LOTERIA",
    payload,
});

export const setLoteriasHoy = payload => ({
    type: "SET_LOTERIAS_HOY",
    payload,
});

export const setListaZonas = payload => ({
    type: "SET_LISTA_ZONAS",
    payload,
});

export const setIdZona = payload => ({
    type: "SET_ID_ZONA",
    payload,
});

export const setTablaPlanesPago = payload => ({
    type: "SET_TABLA_PLANES_PAGO",
    payload,
});

export const setPlanPago = payload => ({
    type: "SET_PLAN_PAGO",
    payload,
});

export const setIdPlanPago = payload => ({
    type: "SET_ID_PLAN_PAGO",
    payload,
});

export const setListaModalidades = payload => ({
    type: "SET_LISTA_MODALIDADES",
    payload,
});

export const setListaRifas = payload => ({
    type: "SET_LISTA_RIFAS",
    payload,
});

export const setIdRifa = payload => ({
    type: "SET_ID_RIFA",
    payload,
});


export const setListaEncabezadoResultado = payload => ({
    type: "SET_LISTA_ENCABEZADO_RESULTADO",
    payload,
});

export const setIdEncabezadoResultado = payload => ({
    type: "SET_ID_ENCABEZADO_RESULTADO",
    payload,
});


export const setTablaBloqueos = payload => ({
    type: "SET_TABLA_BLOQUEOS",
    payload,
});

export const setIdBloqueo = payload => ({
    type: "SET_ID_BLOQUEO",
    payload,
});

export const setTablaComercios = payload => ({
    type: "SET_TABLA_COMERCIOS",
    payload,
});

export const setIdComercio = payload => ({
    type: "SET_ID_COMERCIO",
    payload,
});

export const setGestionando = payload => ({
    type: "SET_GESTIONANDO",
    payload,
});

export const setIdComercioGestionado = payload => ({
    type: "SET_ID_COMERCIO_GESTIONADO",
    payload,
});

export const setIdComercioSeleccionado = payload => ({
    type: "SET_ID_COMERCIO_SELECCIONADO",
    payload,
});

export const setResumenCartera = payload => ({
    type: "SET_RESUMEN_CARTERA",
    payload,
});

export const setResumenCarteraIndividual = payload => ({
    type: "SET_RESUMEN_CARTERA_INDIVIDUAL",
    payload,
});

export const setGestionLoterias = payload => ({
    type: "SET_GESTION_LOTERIAS",
    payload,
});

export const setIdTransaccion = payload => ({
    type: "SET_ID_TRANSACCION",
    payload,
});

export const setIdCategoriaModalidadJuego = payload => ({
    type: "SET_ID_CATEGORIA_MODALIDAD_JUEGO",
    payload,
});

