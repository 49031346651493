import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { setTablaPlanesPago } from '../../../../actions'
import Swal  from 'sweetalert2';
import { guardarPlanPago, getPlanesPago } from '../../../../services/PlanesDePago';
import { alerta, toast, cerrarModal } from '../../../../services/utilities';

function Nuevo(props){

    const [lista_modalidades_juego, setListaModalidadesJuego] = useState();
    const [captionTablaModalidades, setCaptionTablaModalidades] = useState('');
    const [btn_guardar, setBtnGuardar] = useState(true);

    const [item_plan_pago, setItemPlanPago] = useState({
        "id_modalidad_juego":"",
        "valor": ""
    });

    const [plan, setPlan] = useState({
        "nombre": "",
        "item_plan_pago": [],
    });

    const handleInputPlan = (event) => {
        const { name, value } = event.target;
        setPlan({ ...plan, [name]: value });
    };

    const handleInputItemPlan = (event) => {
        const { name, value } = event.target;
        setItemPlanPago({ ...item_plan_pago, [name]: value });
    };

    const handleAgregarItem= () => {

        if(plan.nombre == ''){
            alerta('El nombre del plan no debe estar vacío.','Por favor digite el nombre del plan');
            return;
        }else if(item_plan_pago.id_modalidad_juego == ''){
            alerta('Seleccione una modalidad de juego','Por favor seleccione una modalidad valida');
            return;
        }else if(item_plan_pago.valor == ''){
            alerta('Debe digitar un valor igual o mayor a cero','Por favor digite un valor valido');
            return;
        }else if(plan.item_plan_pago.find(item => item.id_modalidad_juego === item_plan_pago.id_modalidad_juego) !== undefined){
            alerta('La modalidad de juego ya esta agregada en la lista','Por favor seleccione otra modalidad');
            return;
        }

        const nuevo_plan = {
            ...plan,
            item_plan_pago: [...plan.item_plan_pago, item_plan_pago],
        };

        setPlan(nuevo_plan);
        
        setItemPlanPago({
            "id_modalidad_juego":"",
            "valor": ""
        })

        handleArmarBodyItems(nuevo_plan.item_plan_pago, nuevo_plan);
    }

    const handleNombreModalidad = (id_modalidad_juego) =>{
        const item_plan = props.listaModalidades.find(item => item.id_modalidad_juego == id_modalidad_juego);
        return item_plan.nombre;
    }

    const handleArmarBodyItems = (items, plan) =>{

        setListaModalidadesJuego(items.map((r, indice) => (
            <tr key={indice} id={'tr-item-'+indice}>
                <td>{handleNombreModalidad(r.id_modalidad_juego)}</td>
                <td>{r.valor}</td>
                <td>
                    <button 
                        type="button" 
                        className="btn btn-danger btn-sm" 
                        title="Eliminar item" 
                        onClick={() => handleDelteItem(indice,plan)}>
                        <i className="bi bi-trash"></i>
                    </button>
                </td>
            </tr>
        )));
        
        setCaptionTablaModalidades(items.length == 0 ? '' : 'd-none');
        setBtnGuardar(items.length == 0 ? true : false);

    }

    const handleDelteItem = (indice,plan) => {
        plan.item_plan_pago.splice(indice, 1);
        handleArmarBodyItems(plan.item_plan_pago, plan);
        setPlan(plan);
    }

    const handleRefrescarTablaPlanesPago = () => {

        getPlanesPago().then(response => {
            if(response.status === 200){
                props.setTablaPlanesPago(response.data.data)
            }else{
                alerta('No se cargo la tabla de planes de pago o no hay actualemente creadas',response.response.data.status_message??response.message,'warning');
            }
        });
    }

    const handleGuardarPlanPago = () => {

        Swal.fire({
            title: "¿ Esta seguro de guardar la información del Plan de Pagos ?",
            text: "Si esta seguro presione el boton Guardar de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Guardar'
        }).then((result) => {
            if (result.isConfirmed) {
                guardarPlanPago(plan).then(response => {
                    if(response.status == 201){
                        setPlan({"nombre": "","item_plan_pago":[]});
                        handleRefrescarTablaPlanesPago();
                        handleArmarBodyItems([], plan);
                        cerrarModal('modalNuevoPlan');
                        toast('¡ Perfecto !','Plan de pago creado exitosamente','success');
                    }else{
                        alerta('Se presento un error al intentar guardar el plan de pagos',response.response.data.status_message??response.message);
                    }
                });
            }
        });
    }

    return(
        <React.Fragment>
            <div className="modal fade" id="modalNuevoPlan" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalNuevoPlan" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Crear Nuevo Plan de Pagos</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="nombre" name="nombre" value={plan.nombre} onChange={handleInputPlan} />
                                        <label htmlFor="floatingName">Nombre Plan: <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                            </div>
                            <h5 className="card-title text-left">Items:</h5>
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="form-floating mb-3">
                                        <select className="form-select" id="id_modalidad_juego" value={item_plan_pago.id_modalidad_juego} name="id_modalidad_juego" aria-label="State" onChange={handleInputItemPlan}>
                                            <option value="">-- Seleccion --</option>
                                            {props.listaModalidades.map(opcion => (
                                                <option key={opcion.id_modalidad_juego} value={opcion.id_modalidad_juego}>{opcion.nombre}</option>
                                            ))}
                                        </select>
                                        <label htmlFor="id_modalidad_juego">Modalidad de Juego: <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-floating mb-3">
                                        <input type="number" className="form-control input-sm" value={item_plan_pago.valor} id="valor" name="valor" onChange={handleInputItemPlan}   />
                                        <label htmlFor="floatingName">Valor: <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-md-3 pt-3">
                                    <button type="button" className="btn btn-success" onClick={handleAgregarItem}>Agregar</button>
                                </div>
                            </div>
                            <div className="row p-3">
                                <table className="table table-sm table-hover table-borderless"style={{ width : "90%"}}>
                                    <caption className={captionTablaModalidades}>
                                        <h5 className='text-secondary text-center'>No hay modalidades agregadas</h5>
                                    </caption>
                                    <tbody>
                                        {lista_modalidades_juego}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" disabled={btn_guardar} onClick={handleGuardarPlanPago}>Guardar</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    setTablaPlanesPago
};

const mapSateToProps = state => {

    return {
        listaModalidades: state.listaModalidades
    }
}

export default  connect(mapSateToProps, mapDispatchToProps)(Nuevo);