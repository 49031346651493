import React from "react";
import Item from "./item_menu";

function Operaciones(props){

    return(
        <React.Fragment>
            <li className="nav-item">
                <a className="nav-link collapsed" data-bs-target="#operaciones-nav" data-bs-toggle="collapse" href="#">
                    <i className="bi bi-briefcase"></i>
                    <span>Operaciones</span>
                    <i className="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="operaciones-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                    <Item nombre="Premios" ruta="/operaciones/premios"></Item>
                    { parseInt(props.rol_id) === 0 ?
                        <>
                        <Item nombre="SMS" ruta="/operaciones/sms"></Item>
                        </>
                    : ''}
                </ul>
            </li>
        </React.Fragment>
    );
}

export default Operaciones;