import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Header from "../../header/Header";
import HeaderModulo from "../../header/HeaderModulo";
import Aside from "../../aside/Aside";
import { alerta, toast, formatDate } from '../../../services/utilities';
import { getLoterias } from '../../../services/Loterias';
import { getReporteVentaLoteria } from '../../../services/Reportes';
import DetalleVenta from './DetalleVenta';
import { setIdTransaccion} from '../../../actions'

function VentaPorLoteria(props){

    useEffect(() => {
        handleConsultarLoterias();
    }, []);

    const [spinerLoterias, setSpinerLoterias] = useState(true);
    const [listaLoterias, setListaLoterias] = useState([]);
    const [listaVentas, setlistaVentas] = useState([]);
    const [spinerTabla, setSpinerTabla] = useState(false);
    const [verMensaje, setVerMensaje] = useState(true);
    const [verTable, setVerTable] = useState(false);
    const [filterTabla, setFilterTabla] = useState('');

    const [filtro, setFiltro] = useState({
        "fecha_ini": formatDate(new Date()),
        "fecha_fin": formatDate(new Date()),
        "id_loteria": "",
        "id_categoria_modalidad_juego" : ""
    });

    const formatearMoneda = (value) =>{
        const valor = parseFloat(value);
        if (!isNaN(valor)) { 
          value = valor.toLocaleString('es-CO', {minimumFractionDigits: 0});
        }else{
            value = 0;
        }
        return value;
    }

    const handleInputFiltroChange = (event) => {
        const { name, value } = event.target;
        setFiltro({ ...filtro, [name]: value });
    };

    const filterDataTabla = listaVentas.filter((item) => {

        const loteriaMatch = item.loteria !== null && item.loteria  ? item.loteria.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const tipoJuegoMatch = item.tipo_juego !== null && item.tipo_juego ? item.tipo_juego.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const modalidadMatch = item.modalidad !== null && item.modalidad ? item.modalidad.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const idMatch =  item.id_transaccion !== null && item.id_transaccion ?  item.id_transaccion.toString().includes(filterTabla) : '';
        const numeroMatch = item.numero !== null && item.numero  ?  item.numero.toString().includes(filterTabla) : '';
        const valorMatch = item.valor !== null && item.valor  ?  item.valor.toString().includes(filterTabla) : '';
        return loteriaMatch || tipoJuegoMatch || idMatch || modalidadMatch || numeroMatch || valorMatch;

    });

    const handleConsultarLoterias = async () => {
       
        try {
            await getLoterias().then( response => {

                if(response.status !== undefined && response.status === 200){
                    setListaLoterias(response.data.data);  
                }else{
                    toast('No tiene loterias creadas','');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación del select de loterias (reportes/ventaPorLoteria/handleConsultarLoterias) ->'+ error);
        }finally {
            setSpinerLoterias(false);
        }
    }

    const handleBuscarVentas = async () => {

        setVerMensaje(false);
        setSpinerTabla(true);
        setVerTable(false);

        try {
            await getReporteVentaLoteria(filtro.fecha_ini, filtro.fecha_fin, filtro.id_loteria, filtro.id_categoria_modalidad_juego).then( response => {

                if(response.status !== undefined && response.status === 200){
                    
                    if(response.data.ventas.length > 0){
                        setlistaVentas(response.data.ventas);
                        setVerTable(true);
                    }else{
                        setVerMensaje(true)
                        setVerTable(false);
                    }

                }else{
                    toast('Error al ejecutar la consulta','');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de cargas prepago  (reportes/prepago/handleBuscarCargas) ->'+ error);
        }finally {
            setSpinerTabla(false);
        }
    }

    var total = 0;
    var cantidad = 0;

    if(Array.isArray(listaVentas)){
        filterDataTabla.map((venta) => {
            total = total + venta.valor;
            cantidad = cantidad + venta.cantidad_venta;
        });
    }

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <HeaderModulo nombre_modulo={props.modulo}></HeaderModulo>
                <section className="section">
                    <div className='row'>
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Desde:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_ini" 
                                    name="fecha_ini" 
                                    value={filtro.fecha_ini}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Hasta:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_fin"
                                    name="fecha_fin"
                                    value={filtro.fecha_fin}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Modalidades:</label>
                           
                            <div className='input-group'>
                                <select 
                                    className="form-select form-control"
                                    name="id_categoria_modalidad_juego"
                                    value={filtro.id_categoria_modalidad_juego}
                                    onChange={handleInputFiltroChange}
                                >   
                                    <option value="">Todas</option>    
                                    <option value="1">Apuesta</option>
                                    <option value="2">Quinta</option>                          
                                    <option value="3">Astros</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Loterias</label>
                            {spinerLoterias ?
                                <div className="d-flex">
                                    <div className="spinner-border" role="status"></div>                 
                                </div>
                            :
                                <div className='input-group'>
                                    <select 
                                        className="form-select form-control"
                                        name="id_loteria"
                                        value={filtro.id_loteria}
                                        onChange={handleInputFiltroChange}
                                    >  
                                        <option value="">Todas</option>
                                        {Array.isArray(listaLoterias) ? listaLoterias.map((loteria, index) => (
                                            <option value={loteria.id_loteria} key={index}>{loteria.prefijo}</option>
                                        )) : ''}
                                    </select>
                                </div>
                            }
                        </div>
                        { verTable ?
                        <div className="col-md-3">
                            <label className="mb-1">&emsp;</label>
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
                                <input 
                                    type="text" 
                                    className="form-control form-control-sm" 
                                    placeholder="Buscar..." 
                                    aria-label="Buscar" 
                                    aria-describedby="basic-addon1" 
                                    value={filterTabla}
                                    onChange={(e) => setFilterTabla(e.target.value)} 
                                />
                            </div>
                        </div>
                        : ''}
                        <div className="col-md-1">
                            <label className="mb-1">&emsp;</label>
                            <div className="input-group">
                                <button 
                                    type="button" 
                                    className="btn btn-outline-primary btn-md"
                                    onClick={handleBuscarVentas}
                                >
                                    Buscar
                                </button>
                            </div>
                        </div>
                    </div>                    
                    {verTable ?
                    <>
                        <div className="div-card-totales">
                            <div className="card-totales">
                                <h6 className="m-0"><b>Total:</b></h6>
                                <h6 className="text-secondary m-0"><b>$ {formatearMoneda(total)}</b></h6>
                            </div>
                            <div className="card-totales">
                                <h6 className="m-0"><b>Cantidad:</b></h6>
                                <h6 className="text-secondary m-0"><b>{cantidad}</b></h6>
                            </div>
                        </div>                       

                        <div className="card" style={{ overflow : 'auto', height:"500px"}}>
                            <div className="card-body">
                                <div className='table-container'>
                                    <div className="table-responsive-sm">
                                    <table className='table table-hover text-center'>
                                            <thead>
                                            <tr>
                                                <td><b>Fecha</b></td>
                                                <td><b>Loteria</b></td>
                                                <td><b>Cantidad Juegos</b></td>
                                                <td><b>Tipo Juego</b></td>
                                                <td><b>Modalidad</b></td>
                                                <td className='text-start'><b>Numero</b></td>
                                                <td><b>Acumulado</b></td>
                                            </tr>
                                            </thead>
                                            <tbody>                                            
                                            {Array.isArray(listaVentas) ? filterDataTabla.map((venta, index) => (                                
                                                <tr key={index}>
                                                    <td><span className="badge text-bg-dark">{venta.fecha}</span></td>
                                                    <td>{venta.loteria}</td>
                                                    <td><span className="badge text-bg-warning"># {venta.cantidad_venta}</span></td>
                                                    <td>{venta.tipo_juego}</td>
                                                    <td>{venta.modalidad}</td>
                                                    <td className='text-start'><span className="badge text-bg-info">{venta.numero}</span> {venta.tipo_juego === 'QUINTA' ? ' - '+venta.serie :''} {venta.tipo_juego === 'ASTROS' ? ' - '+venta.astro :''}</td>
                                                    <td>${formatearMoneda(venta.valor)}</td>
                                                </tr>
                                            )) : <tr></tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :''}
                    {spinerTabla ?
                    <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '200px'}}>
                        <div className="spinner-border" role="status"></div>                 
                    </div>
                    :''}
                    {verMensaje ?
                    <div className='text-center text-secondary my-4'>
                        <h3>Sin resultado de búsqueda para los filtros aplicados.</h3>
                        <p>Por favor elija una combinación de filtros diferente.</p>
                    </div>
                    :''}
                </section>
            </main>
        </React.Fragment>
    );
}

const mapSateToProps = state => {
    return {
        data_sesion: state.data_sesion,
    }
}

export default connect(mapSateToProps, null)(VentaPorLoteria)