import React, {useEffect, useState} from "react";
import { connect } from 'react-redux';
import Header from "../../../header/Header";
import Aside from "../../../aside/Aside";
import { getLog } from '../../../../services/root'
import { alerta, toast, formatDate } from '../../../../services/utilities';


function Dashboard(props){

    useEffect(() => {
        handleCargarLog();
    },[]);

    const [spiner, setSpiner] = useState(false);
    const [listaLog, setListaLog] = useState([]);

    const handleCargarLog = async () => {

        setSpiner(true);

        try {
            await getLog().then(response => {
                if(response.status === 200){
                    setListaLog(response.data.log)
                }else{
                    alerta('No fue posible cargar la lista de logs','','warning');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de logs  (/root/log) ->'+ error);
        }finally {
            setSpiner(false);
        }
    }

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <section className="section">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="card">
                                <div className="card-body" style={{fontSize:'12px'}}>
                                    <h5 className="card-title">Proceso Automaticos</h5>
                                    <div className="activity-item d-flex">
                                        <i className='bi bi-circle-fill activity-badge text-success align-self-start'></i>
                                        <div className="activity-content mx-2">
                                            <b>RESULTADOS AUTOMATICOS</b>
                                            <ul>
                                                <li>12:30 PM</li>
                                                <li>02:00 PM</li>
                                                <li>05:30 PM</li>
                                                <li>09:45 PM</li>
                                                <li>11:40 PM</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="activity-item d-flex">
                                        <i className='bi bi-circle-fill activity-badge text-success align-self-start'></i>
                                        <div className="activity-content mx-2">
                                            <b>ESCRUTIÑO</b> (cada hora)
                                            <ul>
                                                <li>11:00 AM - 11:45 PM</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="activity-item d-flex">
                                        <i className='bi bi-circle-fill activity-badge text-success align-self-start'></i>
                                        <div className="activity-content mx-2">
                                            <b>CARTERA GENERAL</b> (una vez al día)
                                            <ul>
                                                <li>11:50 PM</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="activity-item d-flex">
                                        <i className='bi bi-circle-fill activity-badge text-success align-self-start'></i>
                                        <div className="activity-content mx-2">
                                            <b>CORREOS BACKUP</b>
                                            <ul>
                                                <li>11:00 AM</li>
                                                <li>02:00 PM</li>
                                                <li>04:00 PM</li>
                                                <li>06:00 PM</li>
                                                <li>08:00 PM</li>
                                                <li>10:00 PM</li>
                                                <li>11:48 PM</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Resumen de logs</h5>
                                    <div style={{height:'350px', overflow: 'auto'}}>
                                        <table className="table table-hover table-sm" style={{fontSize:'12px'}}>
                                            <thead>
                                                <tr>
                                                    <th>Fecha</th>
                                                    <th>Tipo</th>
                                                    <th>Empresa</th>
                                                    <th>Descripción</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {Array.isArray(listaLog) && listaLog.length > 0  ? listaLog.map((log, index) => (
                                                <tr key={index}>
                                                    <td>{log.fecha}</td>
                                                    <td>{log.tipo_log}</td>
                                                    <td>{log.empresa}</td>
                                                    <td>{log.descripcion}</td>
                                                </tr>
                                            )) : <p className="text-center">cargando...</p>}
                                            </tbody>                                        
                                        </table>
                                    </div>
                                    {spiner ?
                                        <div className="d-flex justify-content-center align-items-center" style={{height:200}}>
                                            <div className="spinner-border" role="status"></div>                 
                                        </div>
                                    :''}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment>
    );
}

/*const mapDispatchToProps  = {
    setIdComercio
};

const mapSateToProps = state => {

    return {
        data_sesion: state.data_sesion,
    }
}*/

export default connect(null,null)(Dashboard);