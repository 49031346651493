import InstaceAxios  from "./Api";

export const getZona = async (id_zona) => {
    const result = await InstaceAxios.get(`zona/zona?id_zona=${id_zona}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getZonas = async () => {
    const result = await InstaceAxios.get(`zona/zonas`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const crearZona = async (data) => {
    const result = await InstaceAxios.post('zona/crear', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const editarZona = async (data) => {
    const result = await InstaceAxios.post('zona/editar', data)
        .then(res => res)
        .catch(error => error)
    return result
}