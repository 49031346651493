import React from "react";
import logospace from '../../assets/img/logospace.png'

const NotFound = () => (
    <main>
    <div className="container">
      <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
        <h1>404</h1>
        <h2>La página que estás buscando no existe</h2>
        <a className="btn" href="/dashboard">Ir al inicio</a>
        <div className="credits">
        </div>
      </section>
    </div>
  </main>
);

export default NotFound
