
import React, {useState}  from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/css/style.css'
import './assets/js/main.js'
import { validateToken } from "./services/Login";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'


/*MODULOS INDEPENDIENTES*/
import Login from './components/login/Login';
import Dashboard from "./components/pages/dashboard/Dashboard";
import DashboardRoot from "./components/pages/root/dashboard/Dashboard";
import NotFound from "./components/http_code/NotFound"
import Unauthorized from "./components/http_code/Unauthorized"

/*MODULOS DE ADMINISTRACION*/
import PlanesDePago from "./components/pages/administracion/planes_de_pago/PlanesDePago";
import Rifas from "./components/pages/administracion/rifas/Rifas";
import Resultados from "./components/pages/administracion/resultados/resultados";
import Bloqueos from "./components/pages/administracion/bloqueos/Bloqueos";
import Usuarios from "./components/pages/administracion/usuarios/Usuarios";
import Loterias from "./components/pages/administracion/loterias/loterias"
import Zonas from "./components/pages/administracion/zonas/Zonas"

/*MODULOS DE OPERACIONES*/
import Premios from "./components/pages/operaciones/premios/Premios";
import Sms from "./components/pages/operaciones/sms/Sms";
import Perfil from "./components/pages/usuario/Perfil";
import Password from "./components/pages/usuario/Password";
import Footer from "./components/footer/Footer";

/*MODULOS DE CARTERA*/
import Cartera from "./components/pages/cartera/Cartera";
import Promotor from "./components/pages/cartera/Promotor";
import MiCartera from "./components/pages/cartera/MiCartera.jsx";

/*MODULOS DE REPORTERS*/
import VentaDetallada from "./components/pages/reportes/VentaDetallada.jsx";
import Prestamos from "./components/pages/reportes/Prestamos.jsx";
import IngresosEgresos from "./components/pages/reportes/IngresosEgresos.jsx";
import Prepago from "./components/pages/reportes/Prepago.jsx";
import VentaPorLoteria from "./components/pages/reportes/VentaPorLoteria.jsx";
import Liquidacion from "./components/pages/reportes/Liquidacion.jsx";
import Cubierta from "./components/pages/reportes/Cubierta.jsx";
import Ticket from "./components/pages/reportes/Ticket.jsx";
import PrestamosSolicitados from "./components/pages/reportes/prestamosSolicitados";
import Abonos from "./components/pages/reportes/Abonos.jsx";

/* PAGINAS ROOT */
import Empresas from "./components/pages/root/empresas/empresas";
import UsuariosRoot from "./components/pages/root/usuarios/Usuarios";
import Eliminadas from "./components/pages/root/ventas/eliminadas.jsx";
import LoteriasRoot from "./components/pages/root/loterias/Loterias";
import Bono from "./components/pages/root/bono/bono.jsx";

import Venta from "./components/pages/vendedor/Venta.jsx";

/* PAGINAS BONOS REPORTE EMPRESAS */
import LiquidacionBono from "./components/pages/bono/Liquidacion.jsx";
import PremiosBono from "./components/pages/bono/Premios.jsx";

function App() {

  const token = localStorage.getItem("token")
  const ruta = window.location.pathname;
  const rutas_no_token = ['/','/notFound','/unauthorized','/reporte/ticket'];
  const [tipo_comercio, setTipoComercio] = useState(false);

  /*Validacion del token almacenado en el localStorage para manejo de sesiones y peticiones*/
  if(!rutas_no_token.includes(ruta)){
    validateToken(token).then(response => {
        if(response.status !== 200){
          window.location.href = '/unauthorized';
        }else{
          localStorage.setItem("token", response.data.jwt)
          setTipoComercio(response.data.data.tipo_comercio);
        }
    });
    
  }

  return (
    <React.Fragment>
      <Router>
        <Routes>

          <Route path='/' exact element={<Login/>}></Route>

          { tipo_comercio != "VENDEDOR" ?
            <> 
              <Route key="password" path='/password' exact element={ <Password modulo="Cambiar contraseña" />}></Route>
              
              <Route key="dashboard" path='/dashboard' exact element={ <Dashboard modulo="Dashboard" />}></Route>
              <Route key="dashboard" path='/root/dashboard' exact element={ <DashboardRoot modulo="Dashboard" />}></Route>
              
              <Route key="loterias" path='/admnistracion/loterias' exact element={ <Loterias modulo="Loterias" />}></Route>
              <Route key="zonas" path='/admnistracion/zonas' exact element={ <Zonas modulo="Zonas" />}></Route>
              <Route key="planes" path='/admnistracion/planes' exact element={ <PlanesDePago modulo="Planes" />}></Route>
              <Route key="rifas" path='/admnistracion/rifas' exact element={ <Rifas modulo="Rifas" />}></Route>
              <Route key="resultados" path='/admnistracion/resultados' exact element={ <Resultados modulo="Resultados" />}></Route>
              <Route key="bloqueos" path='/admnistracion/bloqueos' exact element={ <Bloqueos modulo="Topes" />}></Route>
              <Route key="usuarios" path='/admnistracion/usuarios' exact element={ <Usuarios modulo="Usuarios" />}></Route>

              <Route key="premios" path='/operaciones/premios' exact element={ <Premios modulo="Premios" />}></Route>
              <Route key="prepago" path='/operaciones/prepago' exact element={ <Prepago modulo="Prepago" />}></Route>
              <Route key="sms" path='/operaciones/sms' exact element={ <Sms modulo="SMS" />}></Route>

              <Route key="comercio" path='/cartera/cartera'  exact element={ <MiCartera modulo="Cartera" />}></Route>
              <Route key="comercio" path='/cartera/liquidacion' exact element={ <Cartera modulo="Cartera" />}></Route>
              <Route key="promotor" path='/cartera/promotor' exact element={ <Promotor modulo="Promtor" />}></Route>

              <Route key="reporte" path='/reporte/ventaDetallada' exact element={ <VentaDetallada modulo="Reporte Venta Detallada" />}></Route>
              <Route key="reporte" path='/reporte/ventaLoteria' exact element={ <VentaPorLoteria modulo="Reporte Venta Por Loteria" />}></Route>
              <Route key="reporte" path='/reporte/liquidacion' exact element={ <Liquidacion modulo="Reporte Liquidación" />}></Route>
              <Route key="reporte" path='/reporte/cubierta' exact element={ <Cubierta modulo="Cubierta" />}></Route>
              <Route key="reporte" path='/reporte/ingresosEgresos' exact element={ <IngresosEgresos modulo="Reporte Ingresos / Egresos" />}></Route>
              <Route key="reporte" path='/reporte/prestamos' exact element={ <Prestamos modulo="Reporte Prestamos Realizados" />}></Route>
              <Route key="reporte" path='/reporte/prestamosSolicitados' exact element={ <PrestamosSolicitados modulo="Reporte Prestamos Solicitados"/>}></Route>
              <Route key="reporte" path='/reporte/abonos' exact element={ <Abonos modulo="Reporte de Abonos" />}></Route>
              <Route key="reporte" path='/reporte/prepago' exact element={ <Prepago modulo="Reporte Cargas Prepago" />}></Route>
              <Route key="reporte" path='/reporte/ticket' exact element={ <Ticket modulo="Tiecket" />}></Route>

              <Route key="root" path='/root/empresas' exact element={ <Empresas modulo="Empresas" />}></Route>
              <Route key="root" path='/root/usuarios' exact element={ <UsuariosRoot modulo="Usuarios" />}></Route>
              <Route key="root" path='/root/ventas' exact element={ <Eliminadas modulo="Eliminadas" />}></Route>
              <Route key="root" path='/root/loterias' exact element={ <LoteriasRoot modulo="Loterias" />}></Route>
              <Route key="root" path='/root/bono' exact element={ <Bono modulo="Bono" />}></Route>

              <Route key="bono" path='/bono/liquidacion' exact element={ <LiquidacionBono modulo="Bono Millonario Liquidacion" />}></Route>
              <Route key="bono" path='/bono/premios' exact element={ <PremiosBono modulo="Bono Millonario Premios" />}></Route>
            </>
          :''}

          <Route key="venta" path='/venta' exact element={ <Venta modulo="Vender" />}></Route>

          <Route key="perfil" path='/perfil' exact element={ <Perfil modulo="Mi Perfil" />}></Route>
          
          <Route key="notFound" path="*" element={<NotFound></NotFound>}></Route>
          <Route key="unauthorized" path="/unauthorized" element={<Unauthorized></Unauthorized>}></Route>

        </Routes>
      </Router>
      <Footer></Footer>
    </React.Fragment>
  );
}

export default App;
