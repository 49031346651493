import React from "react";
import { Link } from "react-router-dom";

function Item_menu(props){
    return(
        <React.Fragment>
            <li>
                <Link to={props.ruta}>
                    <i className="bi bi-circle"></i><span>{props.nombre}</span>
                </Link>
            </li>
        </React.Fragment>
    );
}

export default Item_menu;