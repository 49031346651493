import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { setTablaLoteria, setGestionLoterias, setLoteria } from '../../../../actions';
import { getLoterias } from '../../../../services/Loterias';
import { editarLoteria} from '../../../../services/Loterias';
import { validarSegundaClave } from '../../../../services/Usuario';
import { alerta, toast, cerrarModal } from '../../../../services/utilities';
import Swal  from 'sweetalert2';

function Editar(props){

    useEffect(() => {

         if(Array.isArray(props.loteria.horario)){
            setListaHorarios(props.loteria.horario.map((r, indice) => (
                <tr key={indice} id={'tr-horario-'+indice}>
                    <td>
                        {r.nombre_dia === "LUNES" && <span className="badge rounded-pill text-bg-dark">{r.nombre_dia}</span>}
                        {r.nombre_dia === "MARTES" && <span className="badge rounded-pill text-bg-info">{r.nombre_dia}</span>}
                        {r.nombre_dia === "MIERCOLES" && <span className="badge rounded-pill text-bg-warning">{r.nombre_dia}</span>}
                        {r.nombre_dia === "JUEVES" && <span className="badge rounded-pill text-bg-danger">{r.nombre_dia}</span>}
                        {r.nombre_dia === "VIERNES" && <span className="badge rounded-pill text-bg-success">{r.nombre_dia}</span>}
                        {r.nombre_dia === "SABADO" && <span className="badge rounded-pill text-bg-secondary">{r.nombre_dia}</span>}
                        {r.nombre_dia === "DOMINGO" && <span className="badge rounded-pill text-bg-primary">{r.nombre_dia}</span>}
                    </td>
                    <td>
                        {r.hora_inicial}
                    </td>
                    <td>
                        {r.hora_final}
                    </td>
                    <td>
                    { parseInt(props.rol_id) === 0 ?
                        <button 
                            type="button" 
                            className="btn btn-danger btn-sm" 
                            title="Eliminar item" 
                            onClick={() => handleDelteHorario(indice)}>
                            <i className="bi bi-trash"></i>
                        </button>
                    :''}
                    </td>
                </tr>
            )));
        }

        setEstadoLoteria(props.loteria.estado_id);

    }, [props.loteria]);

    const [estadoLoteria, setEstadoLoteria] = useState(props.loteria.estado_id);
    const [spiner, setSpiner] = useState(false);
    const [verMensaje, setVerMensaje] = useState(false);
    const [lista_horarios, setListaHorarios] = useState();
    const [captionTableHorarios, setcaptionTableHorarios] = useState('d-none');
    const [btn_guardar, setBtnGuardar] = useState(false);
    const [segundaClave, setSegundaClave] = useState('');
    const [horario_loteria, setHorarioLoteria] = useState({
        "nombre_dia": "",
        "hora_inicial": "",
        "hora_final": "",
    });

    const handleInputHorarioChange = (event) => {
        const { name, value } = event.target;
        setHorarioLoteria({ ...horario_loteria, [name]: value });
    };

    const handleInputSegundaClave = (event) => {
        const { name, value } = event.target;
        setSegundaClave(value);
    };

    const handleDelteHorario = (indice) =>{

        let horarios = props.loteria.horario
        horarios.splice(indice, 1)
        setListaHorarios(horarios.map((r, indice) => (
            <tr key={indice} id={'tr-horario-'+indice}>
                <td>
                    {r.nombre_dia === "LUNES" && <span className="badge rounded-pill text-bg-dark">{r.nombre_dia}</span>}
                    {r.nombre_dia === "MARTES" && <span className="badge rounded-pill text-bg-info">{r.nombre_dia}</span>}
                    {r.nombre_dia === "MIERCOLES" && <span className="badge rounded-pill text-bg-warning">{r.nombre_dia}</span>}
                    {r.nombre_dia === "JUEVES" && <span className="badge rounded-pill text-bg-danger">{r.nombre_dia}</span>}
                    {r.nombre_dia === "VIERNES" && <span className="badge rounded-pill text-bg-success">{r.nombre_dia}</span>}
                    {r.nombre_dia === "SABADO" && <span className="badge rounded-pill text-bg-secondary">{r.nombre_dia}</span>}
                    {r.nombre_dia === "DOMINGO" && <span className="badge rounded-pill text-bg-primary">{r.nombre_dia}</span>}
                </td>
                <td>
                    {r.hora_inicial}
                </td>
                <td>
                    {r.hora_final}
                </td>
                <td>
                    { parseInt(props.rol_id) === 0 ?
                    <button 
                        type="button" 
                        className="btn btn-danger btn-sm" 
                        title="Eliminar item" 
                        onClick={() => handleDelteHorario(indice)}>
                        <i className="bi bi-trash"></i>
                    </button>
                    : '' }
                </td>
            </tr>
        )))

        setcaptionTableHorarios(horarios.length == 0 ? '' : 'd-none');
        setBtnGuardar(horarios.length == 0 ? true : false);
    }

    const handleAgregarHorario = () => {

        if(horario_loteria.dia_semana == ''){
            alerta('Seleccione un dia de la semana', '');
            return;
        }else if(horario_loteria.hora_inicial == ''){
            alerta('Debe indicar la hora inicial', '');
            return;
        }else if(horario_loteria.hora_final == ''){
            alerta('Debe indicar la hora final', '');
            return;
        }else if(props.loteria.horario.find(item => item.nombre_dia === horario_loteria.nombre_dia) !== undefined){
            alerta('Ya existe un horario para el dia '+horario_loteria.nombre_dia+', por favor seleccione otro dia.','');
            return;
        }

        if(Array.isArray(props.loteria.horario)){

            props.loteria.horario.push(horario_loteria);

            setListaHorarios(props.loteria.horario.map((r, indice) => (
                <tr key={indice} id={'tr-horario-'+indice}>
                    <td>
                        {r.nombre_dia === "LUNES" && <span className="badge rounded-pill text-bg-dark">{r.nombre_dia}</span>}
                        {r.nombre_dia === "MARTES" && <span className="badge rounded-pill text-bg-info">{r.nombre_dia}</span>}
                        {r.nombre_dia === "MIERCOLES" && <span className="badge rounded-pill text-bg-warning">{r.nombre_dia}</span>}
                        {r.nombre_dia === "JUEVES" && <span className="badge rounded-pill text-bg-danger">{r.nombre_dia}</span>}
                        {r.nombre_dia === "VIERNES" && <span className="badge rounded-pill text-bg-success">{r.nombre_dia}</span>}
                        {r.nombre_dia === "SABADO" && <span className="badge rounded-pill text-bg-secondary">{r.nombre_dia}</span>}
                        {r.nombre_dia === "DOMINGO" && <span className="badge rounded-pill text-bg-primary">{r.nombre_dia}</span>}
                    </td>
                    <td>
                        {r.hora_inicial}
                    </td>
                    <td>
                        {r.hora_final}
                    </td>
                    <td>
                    { parseInt(props.rol_id) === 0 ?
                        <button 
                            type="button" 
                            className="btn btn-danger btn-sm" 
                            title="Eliminar item" 
                            onClick={() => handleDelteHorario(indice)}>
                            <i className="bi bi-trash"></i>
                        </button>
                    :''}
                    </td>
                </tr>
            )))

            setcaptionTableHorarios('d-none')
            setBtnGuardar(false);
        }
    }

    const handleInputEstadoChange = (event) =>{
        setEstadoLoteria(event.target.value);
        props.setLoteria({ ...props.loteria, estado_id: event.target.value });
    }

    const handleRefrescarTablaLoteria = () => {

        var selectElement = document.getElementById("estado_id");
        selectElement.selectedIndex = 0;

        getLoterias().then(response => {
            if(response.status === 200){
                props.setTablaLoteria(response.data)
            }else{
                alerta('No se refresco la tabla de loterias, para ver los cambios por favor actulice la pagina.',response.response.data.status_message??response.message,'warning');
            }
        });
    }

    const handleGuardarLoteria = () =>{

        Swal.fire({
            title: "¿ Esta seguro de guardar los cambios ?",
            text: "Si esta seguro presione el boton Guardar de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Guardar'
        }).then((result) => {
            setBtnGuardar(true);
            if (result.isConfirmed) {
                editarLoteria(props.loteria).then(response => {
                    if(response.status == 200){
                        cerrarModal('modalEditarLoteria');
                        handleRefrescarTablaLoteria();
                        toast('¡ Perfecto !','Loteria actulizada exitosamente','success');
                        setBtnGuardar(false);
                    }else{
                        alerta('Se presento un error al intentar guardar los cambios',response.response.data.status_message??response.message);
                    }
                });
            }
        });
    }

    const handleValidarSegundaClave = async () => {

        if(segundaClave.length < 4){
            alerta('Ingrese una clave valida', '');
            return;
        }

        setSpiner(true);
        setVerMensaje(false);

        try {

            await validarSegundaClave(segundaClave).then( response => {
                if(response.status !== undefined && response.status === 200){
                    
                    if(response.data.status_message === 'OK'){
                        props.setGestionLoterias(true);
                        toast('¡ Perfecto !','Clave validada','success');
                    }else{
                        props.setGestionLoterias(false);
                        setVerMensaje(true);
                    }
                }else{
                    alerta('Error al validar la segunda clave en la base de datos');
                }
            });

         }catch (error) {
            alerta('Error no controlado al validar la segunda clave (loterias/nueva/handleValidarSegundaClave) ->'+ error);
        }finally {
            setSpiner(false);
        }
    }

    return(
        <React.Fragment>
            <div className="modal fade" id="modalEditarLoteria" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalEditarLoteria" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Editar Loteria</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {parseInt(props.rol_id) !== 0 || props.gestion_loterias ?
                                <>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" id="prefijo" value={props.loteria.nombre} disabled/>
                                                <label htmlFor="id_loteria_sugerida">Loteira:</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" id="prefijo" value={props.loteria.prefijo} disabled/>
                                                <label htmlFor="floatingName">Prefijo:</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" id="oficial"  value={props.loteria.oficial == 1 ? 'SI' : 'NO'} disabled/>
                                                <label htmlFor="floatingName">¿ Es oficial ?</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-floating mb-3">
                                                <select 
                                                    className="form-select" 
                                                    id="estado_id" 
                                                    name="estado_id" 
                                                    aria-label="State" 
                                                    onChange={handleInputEstadoChange}
                                                    value={estadoLoteria}
                                                    disabled={parseInt(props.rol_id) === 0 ? false : true}
                                                    >
                                                    <option >-- Seleccione --</option>
                                                    <option value="1">Activo</option>
                                                    <option value="3">Inactivo</option>
                                                </select>
                                                <label htmlFor="estado_id">Estado:</label>
                                            </div>
                                        </div>
                                    </div>
                                    { parseInt(props.rol_id) === 0 ? 
                                    <>
                                        <h5 className="card-title text-left">Horario:</h5>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-floating mb-3">
                                                    <select 
                                                        className="form-select" 
                                                        id="nombre_dia" 
                                                        name="nombre_dia" 
                                                        aria-label="State" 
                                                        defaultValue="N/A" 
                                                        onChange={handleInputHorarioChange}
                                                    >
                                                        <option value="N/A" disabled>-- Seleccion --</option>
                                                        <option value="DOMINGO">DOMINGO</option>
                                                        <option value="LUNES">LUNES</option>
                                                        <option value="MARTES">MARTES</option>
                                                        <option value="MIERCOLES">MIERCOLES</option>
                                                        <option value="JUEVES">JUEVES</option>
                                                        <option value="VIERNES">VIERNES</option>
                                                        <option value="SABADO">SABADO</option>
                                                    </select>
                                                    <label htmlFor="nombre_dia">Dia: <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-floating mb-3">
                                                    <input type="time" className="form-control" id="hora_inicial" value={horario_loteria.hora_inicial} name="hora_inicial" onChange={handleInputHorarioChange}  />
                                                    <label htmlFor="floatingName">Hora Apertura: <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-floating mb-3">
                                                    <input type="time" className="form-control" id="hora_final" name="hora_final" value={horario_loteria.hora_final}  onChange={handleInputHorarioChange}  />
                                                    <label htmlFor="floatingName">Hora Cierre: <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 pt-3">
                                                <button type="button" className="btn btn-success" onClick={handleAgregarHorario}>Agregar Horario</button>
                                            </div>
                                        </div>
                                    </>
                                    :''}
                                    <div className="row p-3">
                                        <table className="table table-sm table-hover table-borderless text-center"style={{ width : "90%"}}>
                                            <caption className={captionTableHorarios}>
                                                <h5 className='text-secondary text-center'>No hay horarios agregadas</h5>
                                            </caption>
                                            <thead>
                                                <tr>
                                                    <th>Dia</th>
                                                    <th>Abre</th>
                                                    <th>Cierra</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {lista_horarios}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            :
                                <>
                                    <h4 className='text-secondary text-center m-0'>Para continuar por favor ingrese la segunda clave.</h4>
                                    <p className='text-secondary text-center m-0'>Este módulo se encuentra protegido por un doble factor de autenticación.</p>
                                    <br></br>
                                    <div className='row  justify-content-md-center'>
                                        <div className='col-md-4'>
                                            <div className="form-floating mb-3">
                                                <input type="password" className="form-control" id="segunda_clave" name="segunda_clave" value={segundaClave}  onChange={handleInputSegundaClave}  />
                                                <label htmlFor="floatingName">Segunda Clave</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row  justify-content-md-center'>
                                        <div className="col-md-4 text-center">
                                            <button type="button" className="btn btn-success" onClick={handleValidarSegundaClave}>Validar Clave</button>
                                        </div>
                                    </div>
                                    {spiner ?
                                    <div className="d-flex justify-content-center align-items-center my-4">
                                        <div className="spinner-border" role="status"></div>                 
                                    </div>
                                    :''}
                                    {verMensaje ?
                                    <div className='text-center text-danger my-4'>
                                        <h3>Clave incorrecta</h3>
                                        <p>Por favor, ingrese nuevamente la segunda clave de forma correcta.</p>
                                    </div>
                                    :''}
                                </>
                            }
                        </div>
                        <div className="modal-footer">
                            { parseInt(props.rol_id) === 0 ?
                                <button type="button" className="btn btn-primary" disabled={btn_guardar} onClick={handleGuardarLoteria}>Guardar</button>
                            :''}                            
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        loteria: state.loteria,
        data_sesion: state.data_sesion,
        gestion_loterias: state.gestion_loterias
    }
}

const mapDispatchToProps  = {
    setTablaLoteria,
    setGestionLoterias,
    setLoteria
};

export default  connect(mapSateToProps, mapDispatchToProps)(Editar);