import InstaceAxios  from "./Api";

export const login = async (data) => {
    const result = await InstaceAxios.post('usuario/login', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const validateToken = async (token) => {
    const result = await InstaceAxios.get(`usuario/validate_token?token=${token}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const sesion = async () => {
    const result = await InstaceAxios.get(`usuario/sesion`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const generarTokenGestion = async (id_usuario) => {
    const result = await InstaceAxios.get(`usuario/token_gestion?id_usuario=${id_usuario}`)
        .then(res => res)
        .catch(error => error)
    return result
}


