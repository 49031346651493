import React, {useEffect, useState} from "react";
import { connect } from 'react-redux';
import { setIdComercio,setIdComercioGestionado, setGestionando, setIdComercioSeleccionado } from '../../../../actions'
import { getListaEmpresas } from '../../../../services/root'
import { alerta, toast, formatDate } from '../../../../services/utilities';

function Lista(props){

    useEffect(() => {
        handleCargarDataModulo();
    },[]);

    const [spiner, setSpiner] = useState(false);
    const [listaEmpresas, setListaEmpresas] = useState([]);

    const handleComercioSeleccionado = (id_comercio) => {
        props.setIdComercio(id_comercio)
        props.setIdComercioSeleccionado(id_comercio)
    }

    const handleGestionComercio = (id_usuario) => {
        props.setGestionando('SI');
        props.setIdComercioGestionado(id_usuario);
    }

    const handleCargarDataModulo = async () => {

        setSpiner(true);

        try {
            await getListaEmpresas().then(response => {
                if(response.status === 200){
                    setListaEmpresas(response.data.data)
                }else{
                    alerta('No fue posible cargar la lista de empresas','','warning');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de empresas  (root/lista/empresas) ->'+ error);
        }finally {
            setSpiner(false);
        }
    }

    return(
        <React.Fragment>
            <div className="row mt-4">
                {Array.isArray(listaEmpresas) && listaEmpresas.length > 0  ? listaEmpresas.map((empresa, index) => (
                    <div className="col-md-4" key={index}>
                        <div className="card">
                            <div className="d-flex justify-content-center align-items-center">
                                <i className="bi bi-building"></i>
                                <h5 className="card-header">{empresa.nombre}</h5>
                            </div>
                            <div className="card-body">
                                <span className={empresa.estado_id === 1 ? 'text-success' : 'text-danger'}><b>{empresa.estado_id === 1 ? 'Activa' : 'Inactiva'}</b></span><br/>
                                <div className="d-flex align-items-center" style={{height:'20px'}}>
                                    <i className="card-title bi bi-globe me-2"></i>
                                    <a href={empresa.dominio} target="blank_"><h5 className="card-title">{empresa.dominio}</h5></a>
                                </div>
                                <i className="bi bi-calendar3 me-2"></i>
                                <b>{empresa.fecha_creacion}</b><br/>
                                <i className="bi bi-envelope-at me-2"></i>
                                <b>{empresa.correo}</b>
                                <hr></hr>
                                <button
                                    className="btn btn-primary me-2"
                                    data-bs-toggle="modal" 
                                    data-bs-target="#modalEmpresa"
                                    data-id={empresa.id_empresa} 
                                    onClick={(e) => handleComercioSeleccionado(e.target.getAttribute('data-id'))}
                                >Editar
                                </button>
                                <button 
                                    type="button" 
                                    className="btn btn-outline-dark btn-md" 
                                    title="Gestion Usuario" 
                                    data-id={empresa.id_usuario}
                                    onClick={(e) => handleGestionComercio(e.target.getAttribute('data-id'))}                             
                                >
                                    <i 
                                        className="bi bi-eye-fill"  
                                        data-id={empresa.id_usuario}
                                        onClick={(e) => handleGestionComercio(e.target.getAttribute('data-id'))}
                                    >
                                    </i>
                                </button>
                            </div>
                        </div>
                    </div>
                )) : <p className="text-center">cargando...</p>}
                {spiner ?
                    <div className="d-flex justify-content-center align-items-center" style={{height:300}}>
                        <div className="spinner-border" role="status"></div>                 
                    </div>
                :''}
            </div>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    setIdComercio,
    setIdComercioGestionado,
    setIdComercioSeleccionado,
    setGestionando
};

export default connect(null,mapDispatchToProps)(Lista);