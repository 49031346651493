import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Header from "../../header/Header";
import Aside from "../../aside/Aside";
import HeaderModulo from "../../header/HeaderModulo";
import { alerta, toast, formatDate } from '../../../services/utilities';
import { getDataTablaLiquidacion } from '../../../services/bono'

function Liquidacion(props){

    useEffect(() => {
 
    }, []);

    const [dataTabla, setDataTabla] = useState([]);
    const [resumen, setResumen] = useState([]);
    const [spinerTabla, setSpinerTabla] = useState(false);
    const [verMnesaje, setVerMensaje] = useState(true);
    const [verTable, setVerTable] = useState(false);
    const [filterTabla, setFilterTabla] = useState('');

    const [filtro, setFiltro] = useState({
        "fecha_ini": formatDate(new Date()),
        "fecha_fin": formatDate(new Date()),
        "id_empresa": props.data_sesion.id_empresa,
        "id_categoria_modalidad":"0"
    });

    const handleInputFiltroChange = (event) => {
        const { name, value } = event.target;
        setFiltro({ ...filtro, [name]: value });
    };

    const formatearMoneda = (value) =>{
        const valor = parseFloat(value);
        if (!isNaN(valor)) { 
          value = valor.toLocaleString('es-CO', {minimumFractionDigits: 0});
        }else{
            value = 0;
        }
        return value;
    }

    const handleBuscarVentas = async () => {
        
        setVerMensaje(false);
        setSpinerTabla(true);
        setVerTable(false);

        try {
            await getDataTablaLiquidacion(filtro.id_empresa, filtro.fecha_ini, filtro.fecha_fin).then( response => {

                if(response.status !== undefined && response.status === 200){
                    
                    if(response.data.data.length > 0){
                        setResumen(response.data.resumen);
                        setDataTabla(response.data.data);
                        setVerTable(true);
                    }else{
                        setDataTabla([]);
                        setVerMensaje(true)
                        setVerTable(false);
                    }

                }else{
                    toast('Error al ejecutar la consulta','');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de ventas  ->'+ error);
        }finally {
            setSpinerTabla(false);
        }
    }

    const filterDataTabla = dataTabla.filter((item) => {
        const idMatch =  item.id_transaccion !== null && item.id_transaccion ?  item.id_transaccion.toString().includes(filterTabla) : '';
        const empresaMatch = item.empresa !== null && item.empresa  ? item.empresa.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const adminMatch = item.nombre_admin_externo !== null && item.nombre_admin_externo ? item.nombre_admin_externo.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const promotorMatch = item.nombre_promotor !== null && item.nombre_promotor ? item.nombre_promotor.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const comercioMatch = item.nombre_comercio !== null && item.nombre_comercio ? item.nombre_comercio.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const DetalleMatch = item.detalle_venta !== null && item.detalle_venta ? item.detalle_venta.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        return empresaMatch || adminMatch || promotorMatch || comercioMatch || DetalleMatch || idMatch;
    });

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <HeaderModulo nombre_modulo={props.modulo}></HeaderModulo>
                <section className="section">
                    <div className='row my-4'>
                        <div className='col-md-2'>
                            <label className="mb-1">Desde:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_ini" 
                                    name="fecha_ini" 
                                    value={filtro.fecha_ini}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <label className="mb-1">Hasta:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_fin" 
                                    name="fecha_fin" 
                                    value={filtro.fecha_fin}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                        { verTable ?
                        <div className="col-md-3">
                            <label className="mb-1">&emsp;</label>
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
                                <input 
                                    type="text" 
                                    className="form-control form-control-sm" 
                                    placeholder="Buscar..." 
                                    aria-label="Buscar" 
                                    aria-describedby="basic-addon1" 
                                    value={filterTabla}
                                    onChange={(e) => setFilterTabla(e.target.value)} 
                                />
                            </div>
                        </div>
                        : ''}
                        <div className="col-md-1">
                            <label className='mb-1'>&emsp;</label>
                            <div className="input-group">
                                <button 
                                    type="button" 
                                    className="btn btn-outline-primary btn-md"
                                    onClick={handleBuscarVentas}
                                >
                                    Buscar
                                </button>
                            </div>
                        </div>
                    </div>
                    {verTable ?
                    <div className='row'>
                        {Array.isArray(resumen) ? resumen.map((value, index) => (
                            <div className='col-md-3' key={index}>
                                <div className="card  mb-3">
                                    <div className="card-header p-1 px-2 d-flex justify-content-between">
                                        <i className="bi bi-building me-2"></i>
                                        {value.empresa} <p className='text-success '><b>{value.comision}%</b></p>
                                    </div>
                                    <div className="card-body text-success">
                                        <table className='table table-hover m-0'>
                                            <tbody>
                                                <tr>
                                                    <td><b>Venta Bruta</b></td>
                                                    <td className='text-primary'><b>{formatearMoneda(value.venta_bruta)}</b></td>
                                                </tr>
                                                <tr>
                                                    <td><b>Venta Neta</b></td>
                                                    <td className='text-info'><b>{formatearMoneda(value.venta_neta)}</b></td>
                                                </tr>
                                                <tr className='table-success'>
                                                    <td><b>Total</b></td>
                                                    <td><b>{formatearMoneda(value.total_liquidar)}</b></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        
                                    </div>
                                </div>
                            </div>
                        )) : ''}
                    </div>
                    :''}
                    {verTable ?
                    <div className='d-flex justify-content-between mt-0'>
                        <span>Extracto</span>
                        <span className='text-secondary mx-3' style={{fontSize :'12px'}}>Total Registros {filterDataTabla.length}</span>
                    </div>
                    :''}
                    <div className="card">
                        <div className="card-body">
                            {verTable ?
                            <div className='table-container'>
                                <div className="table-responsive-sm">
                                    <table className='table table-hover'>
                                        <thead className='text-center'>
                                            <th>Transaccion</th>
                                            <th>Fecha</th>
                                            <th>Empresa</th>
                                            <th>Socio Empresa</th>
                                            <th>Promotor</th>
                                            <th>Vendedor</th>
                                            <th>Valor</th>
                                            <th>Detalle</th>
                                        </thead>
                                        <tbody>
                                        {Array.isArray(dataTabla) ? filterDataTabla.map((venta, index) => (
                                            <tr key={index}>
                                                <td className='text-center'><span className="badge text-bg-warning"># {venta.id_transaccion}</span></td>
                                                <td className='text-center'><span className="badge text-bg-dark">{venta.fecha}</span></td>
                                                <td style={{fontSize : '10px'}} className='text-center'><b>{venta.empresa}</b></td>
                                                <td style={{fontSize : '10px'}} className='text-center'>{venta.nombre_admin_externo}</td>
                                                <td style={{fontSize : '10px'}} className='text-center'>{venta.nombre_promotor}</td>
                                                <td style={{fontSize : '10px'}} className='text-center'>{venta.nombre_comercio}</td>
                                                <td className='text-center'>${formatearMoneda(venta.valor)}</td>
                                                <td style={{fontSize : '10px'}}>{venta.detalle_venta}</td>
                                            </tr>
                                        )) : <tr></tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            :''}
                            {spinerTabla ?
                            <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '200px'}}>
                                <div className="spinner-border" role="status"></div>                 
                            </div>
                            :''}
                            {verMnesaje ?
                            <div className='text-center text-secondary my-4'>
                                <h3>Sin resultado de búsqueda para los filtros aplicados.</h3>
                                <p>Por favor elija una combinación de filtros diferente.</p>
                            </div>
                            :''} 
                        </div>
                    </div>   
                </section>         
            </main>
        </React.Fragment>
    );
}

const mapSateToProps = state => {
    return {
        data_sesion: state.data_sesion,
    }
}


export default connect(mapSateToProps, null)(Liquidacion)