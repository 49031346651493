import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Header from "../../header/Header";
import HeaderModulo from "../../header/HeaderModulo";
import Aside from "../../aside/Aside";
import { alerta, toast, formatDate } from '../../../services/utilities';
import { getComercios } from '../../../services/Usuario';
import { getReportePrestamos } from '../../../services/Reportes';
import DetalleVenta from './DetalleVenta';
import { setIdTransaccion} from '../../../actions'

function Prestamos(props){

    useEffect(() => {
        handleConsultarComercios();
    }, []);

    const [spinerUsuarios, setSpinerUsuarios] = useState(true);
    const [spinerTabla, setSpinerTabla] = useState(false);
    const [verMensaje, setVerMensaje] = useState(true);
    const [verTable, setVerTable] = useState(false);
    const [listsUsuarios, setListaUsuarios] = useState([]);
    const [listaPrestamos, setlistaPrestamos] = useState([]);
    const [filterTabla, setFilterTabla] = useState('');

    const [filtro, setFiltro] = useState({
        "fecha_ini": "2024-01-01",
        "fecha_fin": formatDate(new Date()),
        "id_comercio": ""
    });

    const filterDataTabla = listaPrestamos.filter((item) => {
        const comercioMatch = item.tipo_comercio !== null && item.tipo_comercio  ? item.tipo_comercio.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const tipoComercioMatch = item.nombre_comercio !== null && item.nombre_comercio  ? item.nombre_comercio.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const idComercioMatch = item.id_comercio !== null && item.id_comercio  ?  item.id_comercio.toString().includes(filterTabla) : '';
        const ValorMatch =  item.valor !== null && item.valor ?  item.valor.toString().includes(filterTabla) : '';
        return comercioMatch || ValorMatch || idComercioMatch || tipoComercioMatch;
    });

    const handleInputFiltroChange = (event) => {
        const { name, value } = event.target;
        setFiltro({ ...filtro, [name]: value });
    };

    const formatearMoneda = (value) =>{
        const valor = parseFloat(value);
        if (!isNaN(valor)) { 
          value = valor.toLocaleString('es-CO', {minimumFractionDigits: 0});
        }else{
            value = 0;
        }
        return value;
    }

    const handleConsultarComercios = async () => {
       
        try {
            await getComercios().then( response => {

                if(response.status !== undefined && response.status === 200){
                    setListaUsuarios(response.data.data);  
                }else{
                    toast('No tiene usuarios de este tipo o fallo la consulta','');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación del select de usuarios (reportes/ventaDetallada/handleConsultarComercios) ->'+ error);
        }finally {
            setSpinerUsuarios(false);
        }
    }

    const handleBuscarPrestamos = async () => {

        setVerMensaje(false);
        setSpinerTabla(true);
        setVerTable(false);

        try {
            await getReportePrestamos(filtro.fecha_ini, filtro.fecha_fin).then( response => {

                if(response.status !== undefined && response.status === 200){
                    if(response.data.prestamos.length > 0){
                        setlistaPrestamos(response.data.prestamos);
                        setVerTable(true);
                    }else{
                        setVerMensaje(true)
                        setVerTable(false);
                    }
                }else{
                    toast('Error al ejecutar la consulta','');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de prestamos  (reportes/prestamos/handleBuscarPrestamos) ->'+ error);
        }finally {
            setSpinerTabla(false);
        }
    }

    var total_prestado = 0;
    var total_abonado = 0;
    var me_deben = 0;

    if(Array.isArray(listaPrestamos) ){

        filterDataTabla.map((listaPrestamos) => {
            total_prestado = total_prestado + listaPrestamos.valor;
            total_abonado = total_abonado + (listaPrestamos.valor - listaPrestamos.deuda_actual);
            me_deben = me_deben + listaPrestamos.deuda_actual;
        });

    }

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <HeaderModulo nombre_modulo={props.modulo}></HeaderModulo>
                <section className="section">
                    <div className='row'>
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Desde:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_ini" 
                                    name="fecha_ini" 
                                    value={filtro.fecha_ini}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Hasta:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_fin" 
                                    name="fecha_fin" 
                                    value={filtro.fecha_fin}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                        { verTable ?
                        <div className="col-md-3">
                            <label className="mb-1">&emsp;</label>
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
                                <input 
                                    type="text" 
                                    className="form-control form-control-sm" 
                                    placeholder="Buscar..." 
                                    aria-label="Buscar" 
                                    aria-describedby="basic-addon1" 
                                    value={filterTabla}
                                    onChange={(e) => setFilterTabla(e.target.value)} 
                                />
                            </div>
                        </div>
                        : ''}
                        <div className="col-md-1">
                            <label className="mb-1">&emsp;</label>
                            <div className="input-group">
                                <button 
                                    type="button" 
                                    className="btn btn-outline-primary btn-md"
                                    onClick={handleBuscarPrestamos}
                                >
                                    Buscar
                                </button>
                            </div>
                        </div>
                    </div>
                    <hr className='m-0'/>
                    {verTable ?
                        <>
                        <div className='row pb-2'>
                            <div className='col-md-2 mt-2'>
                                <div className="card-totales-prestamos">
                                    <h6 className="m-0"><b>Total Prestado</b></h6>
                                    <h6 className="text-secondary m-0"><b>$ {formatearMoneda(total_prestado)}</b></h6>
                                </div>
                            </div>
                            <div className='col-md-2 mt-2'>
                                <div className="card-totales-prestamos">
                                    <h6 className="m-0"><b>Total Abonado</b></h6>
                                    <h6 className="text-success m-0"><b>$ {formatearMoneda(total_abonado)}</b></h6>
                                </div>
                            </div>
                            <div className='col-md-2 mt-2'>
                                <div className="card-totales-prestamos">
                                    <h6 className="m-0"><b>Me Deben</b></h6>
                                    <h6 className="text-danger m-0"><b>$ {formatearMoneda(me_deben)}</b></h6>
                                </div>
                            </div>
                        </div>
                        <div className="card" style={{ overflow : 'auto', height:"500px"}}>
                            <div className="card-body">
                                <div className='table-container'>
                                    <div className="table-responsive-sm">
                                        <table className='table table-hover'>
                                            <thead>
                                            <tr className='text-center'>
                                                <td><b>Tipo Usuario</b></td>
                                                <td><b>ID</b></td>
                                                <td className='text-start'><b>Usuario</b></td>
                                                <td className='text-start'><b>Prestado</b></td>
                                                <td className='text-start'><b>Abono</b></td>
                                                <td className='text-start'><b>Deuda Actual</b></td>
                                                {/*<td><i class="bi bi-clock-history"></i></td>*/}
                                            </tr>
                                            </thead>
                                            <tbody>                                            
                                            {Array.isArray(listaPrestamos) ? filterDataTabla.map((prestamo, index) => (
                                                <tr className='text-center' key={index}>
                                                    <td>{prestamo.tipo_comercio}</td>
                                                    <td>{prestamo.id_comercio}</td>
                                                    <td className='text-start'>{prestamo.nombre_comercio}</td>
                                                    <td className='text-start'><span className="badge text-bg-success">$ {formatearMoneda(prestamo.valor)}</span></td>
                                                    <td className='text-success text-start'><b>${formatearMoneda(prestamo.valor - prestamo.deuda_actual)}</b></td>
                                                    <td className='text-danger text-start'><b>${formatearMoneda(prestamo.deuda_actual)}</b></td>
                                                    {/*<td>
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-primary btn-sm"
                                                            data-id={prestamo.id_comercio}
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#modalDetalleVenta"
                                                            onClick={(e) => handleTransaccionSeleccionada(e.target.getAttribute('data-id'))}
                                                        >Historial
                                                        </button>
                                                    </td>*/}
                                                </tr>
                                            )) : <tr></tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>
                    :''}
                    {spinerTabla ?
                    <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '200px'}}>
                        <div className="spinner-border" role="status"></div>                 
                    </div>
                    :''}
                    {verMensaje ?
                    <div className='text-center text-secondary my-4'>
                        <h3>Sin resultado de búsqueda para los filtros aplicados.</h3>
                        <p>Por favor elija una combinación de filtros diferente.</p>
                    </div>
                    :''}
                </section>
            </main>
            <DetalleVenta></DetalleVenta>
        </React.Fragment>
    )
}

const mapSateToProps = state => {
    return {
        data_sesion: state.data_sesion,
    }
}

export default connect(mapSateToProps, null)(Prestamos)