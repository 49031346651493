import InstaceAxios  from "./Api";

export const validar_topes_numero = async (numero = "", loterias = "", valor = 0, comb=0, categoria_modalidad_juego=0, serie='99', astros='') => {
    const result = await InstaceAxios.get('bloqueo/validar?numero='+numero+'&loterias='+loterias+'&valor='+valor+'&comb='+comb+'&categoria_modalidad_juego_id='+categoria_modalidad_juego+'&serie='+serie+'&astros='+astros)
        .then(res => res)
        .catch(error => error)
    return result
}

export const venta = async (data = {}) => {
    const result = await InstaceAxios.post('venta/chance', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const get_vaucher = async (id_transaccion = 0) => {
    const result = await InstaceAxios.get('reporte/vaucher?id_transaccion='+id_transaccion)
        .then(res => res)
        .catch(error => error)
    return result
}

export const anaular_venta = async (id_transaccion = 0, ) => {
    const result = await InstaceAxios.get('venta/anular?id_transaccion='+id_transaccion)
        .then(res => res)
        .catch(error => error)
    return result
}

export const get_cartera = async (fecha_ini = "", fecha_fin = "" ) => {
    const result = await InstaceAxios.get('reporte/cartera?fecha_ini='+fecha_ini+'&fecha_fin='+fecha_fin)
        .then(res => res)
        .catch(error => error)
    return result
}