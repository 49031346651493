import InstaceAxios  from "./Api";

export const getLoteriasSugeridas = async () => {
    const result = await InstaceAxios.get(`loteria/sugeridas`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getLoterias = async () => {
    const result = await InstaceAxios.get(`loteria/loterias`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getLoteria = async (id_loteria) => {
    const result = await InstaceAxios.get(`loteria/loterias?id_loteria=${id_loteria}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const guardarLoteria = async (data) => {
    const result = await InstaceAxios.post('loteria/crear', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const editarLoteria = async (data) => {
    const result = await InstaceAxios.post('loteria/editar', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getHoy = async () => {
    const result = await InstaceAxios.get(`loteria/hoy`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getDia = async (fecha) => {
    const result = await InstaceAxios.get(`loteria/dia?fecha=${fecha}`)
        .then(res => res)
        .catch(error => error)
    return result
}