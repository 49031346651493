import InstaceAxios  from "./Api";

export const getListaEmpresas = async () => {
    const result = await InstaceAxios.get(`root/lista/empresas`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const crearEmpresa = async (data) => {
    const result = await InstaceAxios.post('root/empresa/crear', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const EditarEmpresa = async (data) => {
    const result = await InstaceAxios.post('root/empresa/editar', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getEmpresa = async (id_empresa) => {
    const result = await InstaceAxios.get(`root/empresa?id_empresa=${id_empresa}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getLog = async () => {
    const result = await InstaceAxios.get(`root/log`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getUsuarios = async () => {
    const result = await InstaceAxios.get(`root/usuarios`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getUsuario = async (id_usuario) => {
    const result = await InstaceAxios.get(`root/usuarios?id_usuario=${id_usuario}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const cambiarPasswordUsuario = async (data) => {
    const result = await InstaceAxios.post('root/usuarios/password', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getVentasEliminadas = async (id_empresa, fecha_ini, fecha_fin) => {
    const result = await InstaceAxios.get(`root/ventas/eliminadas?id_empresa=${id_empresa}&fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}`)
        .then(res => res)
        .catch(error => error)
    return result
}

