import React, {useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { alerta, toast } from '../../../../services/utilities';
import { setListaEncabezadoResultado, setLoteriasHoy, setDataSesion } from '../../../../actions'
import { getEncabezados, actulizarAutomatico } from '../../../../services/Resultados';
import { getHoy } from '../../../../services/Loterias';
import Header from "../../../header/Header";
import Aside from "../../../aside/Aside";
import HeaderModulo from "../../../header/HeaderModulo";
import Hoy from '../loterias/Hoy'
import Tabla from "./Tabla";
import Nuevo from "./Nuevo";
import Editar from './Editar';

function Resultados(props){

    useEffect(() => {

        getEncabezados().then(response => {
            if(response.status === 200){
                props.setListaEncabezadoResultado(response.data.data)
            }else{
                alerta('No se cargo la tabla de resultados o no hay resultados actualemente creados',response.response.data.status_message??response.message,'warning');
            }
        });

        getHoy().then(response => {
            if(response.status === 200){
                props.setLoteriasHoy(response.data)
            }else{
                alerta('No se cargaron los resultados de hoy',response.response.data.status_message??response.message,'warning');
            }
        });
    
    },[]);

    const [estadoAutomatico, setEstadoAutomatico] = useState(props.data_sesion.resultados_automaticos);
    const [spiner, setSpiner] = useState(false);

    const handleEstadoAutomaticoChange = ( event, index = false) => {
        const { name, value } = event.target;
        handleActulizarAutomatico(value)
        setEstadoAutomatico(value)
        props.setDataSesion(({ ...props.data_sesion, ['resultados_automaticos']: value }));
    }


    const handleActulizarAutomatico= async (estado) => {
        
        setSpiner(true);

        try {
            await actulizarAutomatico(estado).then( response => {
                if(response.status !== undefined && response.status === 200){
                    toast('Registro Actulizado','','success');
                }else{
                    toast('No se cargo la informacion de la venta','');
                }
            });
         }catch (error) {
            toast('Error no controlado al cargar la infromaci�n del select de usuarios (resultado/handleActulizarAutomatico) ->',error);
        }finally {
            setSpiner(false);
        }
    } 


    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <HeaderModulo nombre_modulo={props.modulo}></HeaderModulo>
                <section className="section">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card">
                                <div className="card-body">
                                    { parseInt(props.data_sesion.rol_id) === 0 ?
                                    <div  className="mb-4" style={{ textAlign : 'right' }}>
                                        <button type="button" className="btn btn-primary mt-3" data-bs-toggle="modal" data-bs-target="#modalNuevaResultado">Agregar Resultados</button>
                                    </div>
                                    : ''}
                                    <Tabla></Tabla>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            { parseInt(props.data_sesion.rol_id) === 0 ?
                            <div className="card mb-2">
                                <div className="card-body">
                                    <h5 className="card-title">Modo Automatico</h5>
                                    <div className="form-floating mb-3">
                                        <select
                                            className="form-select" 
                                            id="estado_id" 
                                            name="estado_id" 
                                            aria-label="State"
                                            value={estadoAutomatico}
                                            onChange={handleEstadoAutomaticoChange}                                           
                                                                                       
                                        >
                                            <option value="1">Activo</option>
                                            <option value="0">Inactivo</option>
                                        </select>
                                        <label htmlFor="estado_id">Estado:</label>
                                    </div>
                                    {spiner ?
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div className="spinner-border" role="status"></div>                 
                                    </div>
                                    :''}
                                </div>
                            </div>:''}
                            <Hoy></Hoy>
                        </div>
                    </div>
                    <Nuevo></Nuevo>
                    <Editar rol_id={props.data_sesion.rol_id}></Editar>
                </section>
            </main>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        data_sesion: state.data_sesion
    }
}

const mapDispatchToProps  = {
    setListaEncabezadoResultado,
    setLoteriasHoy,
    setDataSesion
};

export default  connect(mapSateToProps, mapDispatchToProps)(Resultados);