import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { alerta, toast, validarEmail } from '../../../services/utilities';
import { editarUsuarioPerfil } from '../../../services/Usuario';
import Swal  from 'sweetalert2';

function Informacion_usuario(props){

    useEffect(() => {
        
        setUsuario({
            "id_comercio": props.data_usuario.id_comercio,
            "id_usuario": props.data_usuario.id_usuario,
            "alias": props.data_usuario.alias,
            "nombre":props.data_usuario.nombre_usuario,
            "email":props.data_usuario.email,
            "celular_usuario":props.data_usuario.celular_usuario,
            "estado_id":props.data_usuario.id_estado
        });

    },[props.data_usuario.id_usuario]);

    const handleInputUsuarioChange = (event) => {
        const { name, value } = event.target;
        setUsuario({ ...usuario, [name]: value });
    };

    const [usuario, setUsuario] = useState({
        "id_comercio": props.data_usuario.id_comercio,
        "id_usuario": props.data_usuario.id_usuario,
        "alias": props.data_usuario.alias,
        "nombre":props.data_usuario.nombre_usuario,
        "email":props.data_usuario.email,
        "celular_usuario":props.data_usuario.celular_usuario,
        "estado_id":props.data_usuario.id_estado
    });

    

    const handleGuardarInfoUsuario = () => {

        if(usuario.nombre.length < 3){
            alerta('Digite un nombre valido');
            return;
        }else if(!validarEmail(usuario.email)){
            alerta('Digite un correo valido');
            return;
        }else if(usuario.celular_usuario.length < 10){
            alerta('Digite un numero de celular valido');
            return;
        }

        Swal.fire({
            title: "¿ Esta seguro de guardar la información del usuario ?",
            text: "Si esta seguro presione el boton Guardar de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Guardar'
        }).then((result) => {
            if (result.isConfirmed) {
                editarUsuarioPerfil(usuario).then(response => {
                    if(response.status == 200){
                        toast('¡ Perfecto !','Información actulizada.','success');
                    }else{
                        alerta('Se presento un error al intentar guardar la información',response.response.data.status_message??response.message);
                    }
                });
            }
        });
    }

    return(
        <React.Fragment>
            <div className="row">
                <div className="col-md-4">
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="alias" value={usuario.alias} disabled/>
                        <label htmlFor="floatingName">Alias</label>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="nombre" name="nombre" value={usuario.nombre} onChange={handleInputUsuarioChange}/>
                        <label htmlFor="floatingName">Nombre Completo</label>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="email" name="email" value={usuario.email} onChange={handleInputUsuarioChange}/>
                        <label htmlFor="floatingName">Correo Electronico </label>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="celular_usuario" name="celular_usuario" value={usuario.celular_usuario} onChange={handleInputUsuarioChange}/>
                        <label htmlFor="floatingName">Celular</label>
                    </div>
                </div>
                <div className="col-md-4 pt-2  text-center">
                    <label>Estado: </label>
                    {usuario.estado_id == 1
                        ?<span className="badge rounded-pill text-bg-success fs-6 m-2">Activo</span>
                        :<span className="badge rounded-pill text-bg-danger fs-6 m-2">Inactivo</span>
                    }
                </div>
            </div>
            <div className="text-center">
                <button type="submit" className="btn btn-primary" onClick={handleGuardarInfoUsuario}>Guardar Cambios</button>
            </div>
            
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        data_usuario: state.data_usuario
    }
}
export default  connect(mapSateToProps, null)(Informacion_usuario);