import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { alerta, toast, cerrarModal } from '../../../../services/utilities';
import { recargaPrepago, getSaldos } from '../../../../services/Usuario'
import Swal  from 'sweetalert2';

function Prepago (props){

    useEffect(() => {

        setPrepago({
            "valor": 0,
            "id_comercio": props.id_comercio_seleccionado, //Comercio al cual se le realiza el prestamo o abono
            "descripcion" : ""
        })

        if(props.id_comercio_seleccionado !== 0){
            getSaldos(props.id_comercio_seleccionado).then(response => {
                if(response.status === 200){
                    setSaldoPrepago(response.data.saldos.prepago);
                }else{
                    alerta('Error al cargar saldos del comercio',response.response.data.status_message??response.message,'warning');
                }
            });
        }

    },[props.id_comercio_seleccionado]);

    const formatearMoneda = (value) =>{

        const valor = parseFloat(value);
        
        if (!isNaN(valor)) { 
          value = valor.toLocaleString('es-CO', {minimumFractionDigits: 0});
        }else{
            value = 0;
        }

        return value;
    }

    const [prepago,setPrepago] = useState({
        "valor": 0,
        "id_comercio": 0,
        "descripcion" : ""
    });
    
    const [saldoPrepago,setSaldoPrepago] = useState(0);

    const handleInputPrestamoChange = (event) => {
        const { name, value } = event.target;
        setPrepago({ ...prepago, [name]: value });
    };

    const handleGuardarPrepago = () =>{

        if(prepago.valor <= 0){
            alerta('El valor debe ser mayor a $0');
            return;
        }

        Swal.fire({
            title: "¿ Esta seguro de guardar la transaccion ?",
            text: "Si esta seguro presione el boton Guardar de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Guardar'
        }).then((result) => {

            if (result.isConfirmed) {          
                recargaPrepago(prepago).then(response => {
                    if(response.status == 201){

                        cerrarModal('modalPrepago');
                        setPrepago({
                            "valor": 0,
                            "id_comercio": props.id_comercio_seleccionado,
                            "descripcion" : ""
                        })                            
                        toast('¡ Perfecto !','Transaccion realizada','success');

                    }else{
                        alerta('Se presento un error al intentar guardar la transaccion',response.response.data.status_message??response.message);
                    }
                });
            }
        });
    }

    return(
        <React.Fragment>
            <div className="modal fade" id="modalPrepago" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalPrepago" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Prepago</h1>
                            <span className="badge text-bg-primary mx-2">{props.id_comercio_seleccionado}</span>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="text-center">
                                <h4>Saldo Actual</h4>
                                <h5 className="text-success">$ {formatearMoneda(saldoPrepago)}</h5>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-floating mb-3">
                                        <input 
                                            type="number" 
                                            className="form-control form-control-sm" 
                                            name="valor"
                                            id="valor" 
                                            value={prepago.valor}
                                            onChange={handleInputPrestamoChange}/>
                                        <label htmlFor="valor">$ Valor:</label>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-floating mb-3">
                                        <textarea 
                                            rows="4"
                                            maxLength="100"
                                            className="form-control form-control-sm" 
                                            name="descripcion"
                                            id="descripcion" 
                                            value={prepago.descripcion}
                                            onChange={handleInputPrestamoChange}/>
                                        <label htmlFor="valor">Descripción:</label>
                                    </div>
                                </div>

                            </div>                            
                                
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={handleGuardarPrepago}>Guardar</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

}

const mapSateToProps = state => {

    return {
        data_sesion: state.data_sesion,
        id_comercio_seleccionado: state.id_comercio_seleccionado
    }
}

export default  connect(mapSateToProps,null)(Prepago);