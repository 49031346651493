import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { setListaZonas } from '../../../../actions'
import { getZona, editarZona, getZonas } from '../../../../services/Zona';
import { alerta, toast, cerrarModal } from '../../../../services/utilities';
import Swal  from 'sweetalert2';

function Editar(props){

    const [zona, setZona] = useState({
        "id_zona": "",
        "nombre_zona": "",
        "zona_loteria" : []
    });

    useEffect(() => {        
        if(props.id_zona != 0){
            getZona(props.id_zona).then(response => {
                if(response.status === 200){
                    let asignadas = response.data.data.zona_loteria.map(item => item.id_loteria)
                    setZona({
                            "id_zona": response.data.data.zona.id_zona,
                            "nombre_zona": response.data.data.zona.nombre,
                            "zona_loteria":  asignadas
                    })
                    handleSeleccionarLoteriasAsignadas(asignadas);
                }else{
                    if(props.id_zona != 0)
                        alerta('Error al cargar el listado de loterias ya agregadas');
                }
            })
        }
    }, [props.id_zona]);

    const handleSeleccionarLoteriasAsignadas = (asignadas) =>{

        let checkboxes = document.querySelectorAll('input[id="check-loterias"]');

        checkboxes.forEach((checkbox, index) => {
            let loreriaEncontrado = asignadas.find(item => item == checkbox.value);
            if (loreriaEncontrado !== undefined){
                checkbox.checked = true;
            }else{
                checkbox.checked = false;
            }
        });
    }

    
    const handleInputZona = (event) => {
        const { name, value } = event.target;
        setZona({ ...zona, [name]: value });
    };

    const handleCheckiarLoteria = (event) => {

        let loterias_seleccionadas =  zona.zona_loteria;

        if(event.target.checked){
            loterias_seleccionadas.push(event.target.value);
        }else{
            loterias_seleccionadas = loterias_seleccionadas.filter(item => item != event.target.value)
        }

        setZona({
            "id_zona": zona.id_zona,
            "nombre_zona": zona.nombre_zona,
            "zona_loteria":  loterias_seleccionadas
        })
    }

    const handleRefrescarListaZonas = () => {

        getZonas().then(response => {
            if(response.status === 200){
                props.setListaZonas(response.data.data)
            }else{
                alerta('No se cargo la lista de zonas o no hay zonas actualemente creadas',response.response.data.status_message??response.message,'warning');
            }
        });
    }

    const handleGuardarEditarZona = () => {

        if(zona.nombre_zona == ''){
            alerta('El nombre de la zona no puede estar vacío')
            return;
        }


        Swal.fire({
            title: "¿ Esta seguro de guardar los cambios ?",
            text: "Si esta seguro presione el boton Guardar de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Guardar'
        }).then((result) => {
            if (result.isConfirmed) {
                editarZona(zona).then(response => {
                    if(response.status == 200){
                        cerrarModal('modalEditarZona');
                        handleRefrescarListaZonas();
                        toast('¡ Perfecto !','Zona actulizada exitosamente','success');
                    }else{
                        alerta('Se presento un error al intentar guardar los cambios',response.response.data.status_message??response.message);
                    }
                });
            }
        });

    }

    return(
        <React.Fragment>
            <div className="modal fade" id="modalEditarZona" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalEditarZona"           aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Editar zona</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="nombre_zona" name="nombre_zona" value={zona.nombre_zona} onChange={handleInputZona}  />
                                        <label htmlFor="floatingName">Nombre Zona: <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                
                                <div className="col-md-12">
                                    <table className="table table-hover table-sm" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>Loteria</th>
                                                <th><i className="bi bi-check-square"></i></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {Array.isArray(props.tableLoterias.data) ? props.tableLoterias.data.map((loteria, index) => (
                                            <tr key={index}>
                                                <td>{loteria.prefijo}</td>
                                                <td>
                                                    <div className="form-check text-center">
                                                        <input className="form-check-input" type="checkbox" value={loteria.id_loteria}  id="check-loterias" onChange={handleCheckiarLoteria} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : <tr></tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={handleGuardarEditarZona}>Guardar</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapDispatchToProps  = {
    setListaZonas
};


const mapSateToProps = state => {

    return {
        id_zona: state.id_zona,
        tableLoterias: state.tableLoterias
    }
}

export default  connect(mapSateToProps, mapDispatchToProps)(Editar);