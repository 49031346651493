import React from "react";
import { connect } from 'react-redux';

function Configuracion(props){

    return(
        <React.Fragment>
            
            <div className="row">

                <div className="row pt-2">
                    <div className="col-md-5 label-profile">Empresa</div>
                    <div className="col-md-7">{props.data_usuario.nombre_empresa}</div>
                </div>

                {   props.data_usuario.nombre_admin_externo?
                        <div className="row pt-2">
                            <div className="col-md-5 label-profile">Administrador</div>
                            <div className="col-md-7">{props.data_usuario.nombre_admin_externo}</div>
                        </div>
                    :''
                }
                
                {  props.data_usuario.nombre_promotor?
                    <div className="row pt-2">
                        <div className="col-md-5 label-profile">Mi Promotor</div>
                        <div className="col-md-7">{props.data_usuario.nombre_promotor}</div>
                    </div>
                    :''
                }

                <div className="row pt-2">
                    <div className="col-md-5 label-profile">Zona</div>
                    <div className="col-md-7">{props.data_usuario.nombre_zona}</div>
                </div>

                <div className="row pt-2">
                    <div className="col-md-5 label-profile">Plan Pago</div>
                    <div className="col-md-7">{props.data_usuario.nombre_plan_pago}</div>
                </div>

                <div className="row pt-2">
                    <div className="col-md-5 label-profile">Comision</div>
                    <div className="col-md-7">{props.data_usuario.porcentaje_comision} %</div>
                </div>

                <div className="row pt-2">
                    <div className="col-md-5 label-profile">Descuento Prestamos</div>
                    <div className="col-md-7">{props.data_usuario.porcentaje_descuento_prestamo} %</div>
                </div>

                <div className="row pt-2">
                    <div className="col-md-5 label-profile">Prepago</div>
                    <div className="col-md-7">{props.data_usuario.saldo_prepago ? 'SI' : 'NO' }</div>
                </div>

                <div className="row pt-2">
                    <div className="col-md-5 label-profile">Saldo Prepago</div>
                    <div className="col-md-7">$ {props.data_usuario.saldo_prepago}</div>
                </div>
            </div>
        </React.Fragment>
    );

}

const mapSateToProps = state => {

    return {
        data_usuario: state.data_usuario
    }
}
export default  connect(mapSateToProps, null)(Configuracion);