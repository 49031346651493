import React, {useEffect, useState} from "react";
import { connect } from 'react-redux';
import { setTablaPlanesPago, setListaZonas, setTablaComercios, setIdComercio } from '../../../../actions'
import { getPlanesPago } from '../../../../services/PlanesDePago';
import { getZonas } from '../../../../services/Zona';
import { alerta } from '../../../../services/utilities';
import { getComercios } from '../../../../services/Usuario';
import Header from "../../../header/Header";
import Aside from "../../../aside/Aside";
import HeaderModulo from "../../../header/HeaderModulo";
import Tabla from "./Tabla";
import Nuevo from "./Nuevo";
import Prestamos from "./Prestamos";
import Prepago from "./Prepago";

function Usuarios(props){

    useEffect(() => {
        handleCargarDataModuloUsuarios();
    },[]);

    const [spiner, setSpiner] = useState(false);

    const handleCargarDataModuloUsuarios = async () => {

        setSpiner(true);

        try {

            await getPlanesPago().then(response => {
                if(response.status === 200){
                    props.setTablaPlanesPago(response.data.data)
                }else{
                    alerta('No se cargo la tabla de planes de pago o no hay actualemente creadas',response.response.data.status_message??response.message,'warning');
                }
            });
    
            await getZonas().then(response => {
                if(response.status === 200){
                    props.setListaZonas(response.data.data)
                }else{
                    alerta('No se cargo la lista de zonas o no hay zonas actualemente creadas',response.response.data.status_message??response.message,'warning');
                }
            });
    
            await getComercios().then(response => {
                if(response.status === 200){
                    props.setTablaComercios(response.data.data)
                }else{
                    alerta('No se cargo la lista de usuarios creados',response.response.data.status_message??response.message,'warning');
                }
            });
           
         }catch (error) {
            alerta('Error no controlado al cargar la infromación de prestamos  (reportes/abonos/handleBuscarPrestamos) ->'+ error);
        }finally {
            setSpiner(false);
        }
    }

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <HeaderModulo nombre_modulo={props.modulo}></HeaderModulo>
                <section className="section">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <Tabla></Tabla>                             
                                {spiner ?
                                    <div className="d-flex justify-content-center align-items-center" style={{height:150}}>
                                        <div className="spinner-border" role="status"></div>                 
                                    </div>
                                :''}
                            </div>
                        </div>
                    </div>
                    <Nuevo></Nuevo>
                    <Prestamos></Prestamos>
                    <Prepago></Prepago>
                </section>
            </main>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    setTablaPlanesPago,
    setListaZonas,
    setTablaComercios,
    setIdComercio
};

const mapSateToProps = state => {

    return {
        tablaPlanesPago: state.tablaPlanesPago,
        listaZonas: state.listaZonas,
        data_sesion: state.data_sesion,
    }
}


export default connect(mapSateToProps,mapDispatchToProps)(Usuarios);