import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Header from "../../header/Header";
import Aside from "../../aside/Aside";
import HeaderModulo from "../../header/HeaderModulo";
import { getResumenSaldosDashboard } from '../../../services/Usuario';
import { getResumenResumenIngresosEgresos } from '../../../services/Reportes';
import { alerta, toast, formatDate } from '../../../services/utilities';

function Dashboard(props){

    useEffect(() => { 
        handleConsultarResumenSaldosDashboard();
        handleConsultarResumenIngresosEgresos();
    }, []);

    const formatearMoneda = (value) =>{

        const valor = parseFloat(value);
        
        if (!isNaN(valor)) { 
          value = valor.toLocaleString('es-CO', {minimumFractionDigits: 0});
        }else{
            value = 0;
        }
    
        return value;
    }

    const [spiner, setSpiner] = useState(false);
    const [spinerIngresosEgresos, setSpinerIngresosEgresos] = useState(true);
    const [verResumenSaldos, setVerResumenSaldos] = useState(false);
    const [verResumenIngresosEgresos, setVerResumenIngresosEgresos] = useState(false);
    const [resumenSaldo, setResumenSaldo] = useState({
        "bruta": 0,
        "neto": 0,
        "comision": 0,
        "cobros": 0
    });

    const [resumenIngresoEgreso, setResumenIngresoEgreso] = useState({
        "ingresos": 0,
        "egresos": 0,
        "total": 0
    });

    const [filtro, setFiltro] = useState({
        "fecha_ini": formatDate(new Date()),
        "fecha_fin": formatDate(new Date())
    });

    const handleInputFiltroChange = (event) => {
        const { name, value } = event.target;
        setFiltro({ ...filtro, [name]: value });
    };

    const handleConsultarResumenSaldosDashboard = async (fecha, tipoComercio) => {

        setSpiner(true);

        try {

            await getResumenSaldosDashboard().then( response => {

                if(response.status !== undefined && response.status === 200){
                    setResumenSaldo(response.data.resumen_saldo);      
                }else{
                    alerta('Error al consular la informacion del Dasboard','Recarga la pagina nuevamente, si el problema persiste contacte a soporte.');
                }
            });

         }catch (error) {
            setSpiner(false);
            alerta('Error no controlado al cargar la infromación del dashboard (usuario/dashboard/handleConsultarResumenSaldosDashboard) ->'+ error);
        }finally {
            setVerResumenSaldos(true);  
            setSpiner(false);
        }
    }

    const handleConsultarResumenIngresosEgresos = async () => {
        
        setSpinerIngresosEgresos(true)

        try {

            await getResumenResumenIngresosEgresos(filtro.fecha_ini, filtro.fecha_fin).then( response => {
                if(response.status !== undefined && response.status === 200){
                    setResumenIngresoEgreso(response.data.resumen);      
                }else{
                    alerta('Error al consular la informacion del Dasboard Ingresos Egresos','Recarga la pagina nuevamente, si el problema persiste contacte a soporte.');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación del dashboard (usuario/dashboard/handleConsultarResumenIngresosEgresos) ->'+ error);
        }finally {
            setVerResumenIngresosEgresos(true);
            setSpinerIngresosEgresos(false);
        }
    }

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <HeaderModulo nombre_modulo={props.modulo}></HeaderModulo>
                { verResumenSaldos ?
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card info-card sales-card">
                                        <div className="card-body">
                                        <h5 className="card-title">$ Bruta <span>| Hoy</span></h5>

                                            <div className="d-flex align-items-center">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="bi bi-cash-coin"></i>
                                                </div>
                                                <div className="ps-3">
                                                    <h6>${formatearMoneda(resumenSaldo.bruta)}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card info-card revenue-card">
                                        <div className="card-body">
                                        <h5 className="card-title">$ Neto <span>| Hoy</span></h5>

                                            <div className="d-flex align-items-center">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="bi bi-coin"></i>
                                                </div>
                                                <div className="ps-3">
                                                    <h6>${formatearMoneda(resumenSaldo.neto)}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card info-card danger-card">
                                        <div className="card-body">
                                        <h5 className="card-title">$ Recaudos Prestamos<span>| Hoy</span></h5>

                                            <div className="d-flex align-items-center">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="bi bi-cash-stack"></i>
                                                </div>
                                                <div className="ps-3">
                                                    <h6>${formatearMoneda(resumenSaldo.cobros)}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card info-card customers-card">
                                        <div className="card-body">
                                        <h5 className="card-title">$ Comision <span>| Hoy</span></h5>

                                            <div className="d-flex align-items-center">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="bi bi-piggy-bank"></i>
                                                </div>
                                                <div className="ps-3">
                                                    <h6>${formatearMoneda(resumenSaldo.comision)}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-body">
                                    <table className="table table-hover">
                                        <tbody>
                                            <tr>
                                                <td className="align-middle">
                                                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                    <i className="bi bi-cash"></i>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <h5 className="card-title">Admin Externos</h5>
                                                </td>
                                                <td className="align-middle fs-4">
                                                    <span className="badge rounded-pill text-bg-warning">0</span>
                                                </td>
                                            </tr> 
                                            <tr>
                                                <td className="align-middle">
                                                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                        <i className="bi bi-check-circle"></i>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <h5 className="card-title">Promotores Activos</h5>
                                                </td>
                                                <td className="align-middle fs-4">
                                                    <span className="badge rounded-pill text-bg-success">0</span>
                                                </td>
                                            </tr>                                           
                                            <tr>
                                                <td className="align-middle">
                                                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                        <i className="bi bi-people"></i>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <h5 className="card-title">Vendedores</h5>
                                                </td>
                                                <td className="align-middle fs-4">
                                                    <span className="badge rounded-pill text-bg-primary">0</span>
                                                </td>
                                            </tr>                                                                                      
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.data_sesion.tipo_comercio !== 'VENDEDOR'?
                        <div className='row'>
                            <div className="col-md-8">
                                <div className="card info-card sales-card">
                                    <div className="card-body">
                                        <h5 className="card-title">Resumen Ingresos / Egresos</h5>
                                        <div className='row'>
                                            <div className='col-md-5 mb-3'>
                                                <label className="mb-1">Desde:</label>
                                                <div className="input-group">
                                                    <input 
                                                        type="date" 
                                                        className="form-control" 
                                                        id="fecha_ini" 
                                                        name="fecha_ini" 
                                                        value={filtro.fecha_ini}
                                                        onChange={handleInputFiltroChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-5 mb-3'>
                                                <label className="mb-1">Hasta:</label>
                                                <div className="input-group">
                                                    <input 
                                                        type="date" 
                                                        className="form-control" 
                                                        id="fecha_fin" 
                                                        name="fecha_fin" 
                                                        value={filtro.fecha_fin}
                                                        onChange={handleInputFiltroChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <label className="mb-1">&emsp;</label>
                                                <div className="input-group">
                                                    <button type="button" className="btn btn-outline-primary btn-md" onClick={() => handleConsultarResumenIngresosEgresos()} >
                                                        Buscar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {spinerIngresosEgresos ?
                                            <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '100px'}}>
                                                <div className="spinner-border" role="status"></div>                 
                                            </div>
                                        :''}
                                        {verResumenIngresosEgresos ? 
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    Ingresos
                                                    <span className="badge bg-success rounded-pill">$ {formatearMoneda(resumenIngresoEgreso.ingresos)}</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    Egresos
                                                    <span className="badge bg-danger rounded-pill">$ {formatearMoneda(resumenIngresoEgreso.egresos)}</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center list-group-item-info">
                                                    <b>Total</b>
                                                    <span className="badge bg-primary rounded-pill">$ {formatearMoneda(resumenIngresoEgreso.ingresos -resumenIngresoEgreso.egresos)}</span>
                                                </li>
                                            </ul>
                                        :''}                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    :''}
                </section>
                :''}
                {spiner ?
                    <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '200px'}}>
                        <div className="spinner-border" role="status"></div>                 
                    </div>
                :''}
            </main>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        data_sesion: state.data_sesion,
    }
}

export default connect(mapSateToProps, null)(Dashboard)