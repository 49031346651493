import React, { useState, useEffect } from "react";
import logospace from '../../assets/img/logo_software22.png'
import { login } from "../../services/Login";
import { toast } from '../../services/utilities';

function Login () {

    const [disabled_botton, setDsiabledBotton] = useState(false);
    const [spiner, setSpiner] = useState(false);

    useEffect(() => {
        localStorage.clear();
    }, []);
    
    const [form, setValue] = useState({
        usuario:'',
        password:''
    });

    const handleInput = event => {
        setValue({
            ...form,
            [event.target.name]: event.target.value
        })
    };

    const handleLogin = async () => {

        setSpiner(true);
        setDsiabledBotton(true);
        
        if(form.usuario.length < 3 ){
            toast('Usuario Invalido', 'Por favor ingrese un usuario valido');
        }else if(form.password.length < 4 ){
            toast('Clave Invalida', 'Por favor ingrese una clave valida');
        }else{
            
            try {
                await login(form).then(response => {
                
                    if(response.status === 200){
                        localStorage.setItem("gestion", 'NO');
                        localStorage.setItem("token", response.data.jwt)
                        if(response.data.tipo_comercio === "ROOT"){
                            window.location.href = '/root/dashboard';
                        }else if(response.data.tipo_comercio !== 'VENDEDOR'){
                            window.location.href = '/dashboard';
                        }else if(response.data.tipo_comercio === 'VENDEDOR' && response.data.venta_web){
                            window.location.href = '/venta';
                        }else{
                            toast('Usuario no valido para este canal','Debe ingresar por la app movil');
                        }
                    }else{
                        
                        if(response.response.status === 401){
                            toast('Error de autenticacion', response.response.data.status_message);
                        }else{
                            toast('Error de autenticacion', 'Error no identificado');
                        }
                    }
                });
            }catch (error) {
                toast('Error no controlado al intentar hacer login',error);
            }finally {
                setSpiner(false);
                setDsiabledBotton(false);
            }
        }

        setSpiner(false);
        setDsiabledBotton(false);
    }

    return(
        <React.Fragment>
            <main>
                <div className="container">
                    <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                                    <div className="d-flex justify-content-center py-4">
                                        <a href="index.html" className="d-flex align-items-center w-auto">
                                        <img className="logo_login" src={logospace} alt="" />                                        
                                        </a>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="pt-4 pb-2">
                                                <h5 className="card-title text-center pb-0 fs-4">Inicie sesión en su cuenta</h5>
                                                <p className="text-center small">Ingrese su usuario y clave para iniciar sesión</p>
                                            </div>
                                            <form className="row g-3 needs-validation">
                                                <div className="col-12">
                                                    <label htmlFor="usuario" className="form-label">Usuario</label>
                                                    <input 
                                                        type="text" 
                                                        name="usuario"
                                                        className="form-control" 
                                                        id="usuario" 
                                                        required 
                                                        value={form.usuario} 
                                                        onChange={handleInput}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <label htmlFor="password" className="form-label">Clave</label>
                                                    <input 
                                                        type="password" 
                                                        name="password" 
                                                        className="form-control" 
                                                        id="password" 
                                                        required 
                                                        value={form.password} 
                                                        onChange={handleInput}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <button 
                                                        className="btn btn-primary w-100" 
                                                        type="button"
                                                        disabled={disabled_botton} 
                                                        onClick={handleLogin}
                                                    >
                                                        Ingresar
                                                    </button>
                                                </div>
                                                {spiner &&
                                                    <div className="d-flex justify-content-center">
                                                        <div className="spinner-border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>
                                                }
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </React.Fragment>
    );
}

export default Login;