import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { alerta, toast, cerrarModal } from '../../../../services/utilities';
import { prestamoComercio, getSaldos } from '../../../../services/Usuario'
import Swal  from 'sweetalert2';

function Prestamos (props){

    useEffect(() => {
        setPrestamo({
            "id_transaccion_tipo":"0",
            "valor": 0,
            "id_comercio": props.id_comercio_seleccionado, //Comercio al cual se le realiza el prestamo o abono
            "descripcion" : ""
        })


        if(props.id_comercio_seleccionado !== 0){
            getSaldos(props.id_comercio_seleccionado).then(response => {
                if(response.status === 200){
                    setSaldoPrestamo(response.data.saldos.prestamo);
                }else{
                    alerta('Error al cargar saldos del comercio',response.response.data.status_message??response.message,'warning');
                }
            });
        }

    },[props.id_comercio_seleccionado]);

    const formatearMoneda = (value) =>{

        const valor = parseFloat(value);
        
        if (!isNaN(valor)) { 
          value = valor.toLocaleString('es-CO', {minimumFractionDigits: 0});
        }else{
            value = 0;
        }

        return value;
    }

    const [prestamo,setPrestamo] = useState({
        "id_transaccion_tipo":"0",
        "valor": 0,
        "id_comercio": 0,
        "descripcion" : ""
    });
    const [saldoPrestamo,setSaldoPrestamo] = useState(0);

    const handleInputPrestamoChange = (event) => {
        const { name, value } = event.target;
        setPrestamo({ ...prestamo, [name]: value });
    };

    const handleGuardarPrestamo = () =>{

        if(prestamo.valor <= 0){
            alerta('El valor debe ser mayor a $0');
            return;
        }else if(prestamo.id_transaccion_tipo != 2 && prestamo.id_transaccion_tipo != 3){
            alerta('Seleccione un tipo de transaccion valido')
            return;
        }

        Swal.fire({
            title: "¿ Esta seguro de guardar la transaccion ?",
            text: "Si esta seguro presione el boton Guardar de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Guardar'
        }).then((result) => {

            if (result.isConfirmed) {          
                prestamoComercio(prestamo).then(response => {
                    if(response.status == 201){

                        cerrarModal('modalPrestamos');
                        setPrestamo({
                            "id_transaccion_tipo":"0",
                            "valor": 0,
                            "id_comercio": props.id_comercio_seleccionado,
                            "descripcion" : ""
                        })                            
                        toast('¡ Perfecto !','Transaccion realizada','success');

                    }else{
                        alerta('Se presento un error al intentar guardar la transaccion',response.response.data.status_message??response.message);
                    }
                });
            }
        });
    }

    return(
        <React.Fragment>
            <div className="modal fade" id="modalPrestamos" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalPrestamos" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Prestamos</h1>
                            <span className="badge text-bg-primary mx-2">{props.id_comercio_seleccionado}</span>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="text-center">
                                <h4>Deuda Actual</h4>
                                <h5 className="text-danger">$ {formatearMoneda(saldoPrestamo)}</h5>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-floating mb-3">
                                        <select
                                            className="form-select"
                                            id="id_transaccion_tipo"
                                            name="id_transaccion_tipo"
                                            aria-label="State"
                                            value={prestamo.id_transaccion_tipo}
                                            onChange={handleInputPrestamoChange}>
                                            <option value="0">-Seleccione-</option>
                                            <option value="2">Realizar Prestamo</option>
                                            <option value="3">Registrar Abono</option>
                                        </select>
                                        <label htmlFor="id_transaccion_tipo">Tipo de transacción:</label>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-floating mb-3">
                                        <input 
                                            type="number" 
                                            className="form-control form-control-sm" 
                                            name="valor"
                                            id="valor" 
                                            value={prestamo.valor}
                                            onChange={handleInputPrestamoChange}/>
                                        <label htmlFor="valor">$ Valor:</label>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-floating mb-3">
                                        <textarea 
                                            rows="4"
                                            maxLength="100"
                                            className="form-control form-control-sm" 
                                            name="descripcion"
                                            id="descripcion" 
                                            value={prestamo.descripcion}
                                            onChange={handleInputPrestamoChange}/>
                                        <label htmlFor="valor">Descripción:</label>
                                    </div>
                                </div>

                            </div>                            
                                
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={handleGuardarPrestamo}>Guardar</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

}

const mapSateToProps = state => {

    return {
        data_sesion: state.data_sesion,
        id_comercio_seleccionado: state.id_comercio_seleccionado
    }
}

export default  connect(mapSateToProps,null)(Prestamos);