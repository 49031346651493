import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { setTablaPlanesPago, setListaModalidades } from '../../../../actions'
import { alerta } from '../../../../services/utilities';
import { getPlanesPago } from '../../../../services/PlanesDePago';
import { getModalidadesEmpresa } from '../../../../services/ModalidadesJuego';
import Header from "../../../header/Header";
import Aside from "../../../aside/Aside";
import HeaderModulo from "../../../header/HeaderModulo";
import Tabla from "./Tabla";
import Modalidades from "./Modalidades"
import Nuevo from "./Nuevo";
import Editar from './Editar';

function PlanesDePago(props){

    useEffect(() => {

        getPlanesPago().then(response => {
            if(response.status === 200){
                props.setTablaPlanesPago(response.data.data)
            }else{
                alerta('No se cargo la tabla de planes de pago o no hay actualemente creadas',response.response.data.status_message??response.message,'warning');
            }
        });

        getModalidadesEmpresa().then(response => {
            if(response.status === 200){
                props.setListaModalidades(response.data.data)
            }else{
                alerta('No se cargo la lista de modalidades de juego o no hay modalidades asociadas a la empresa',response.response.data.status_message??response.message,'warning');
            }
        });
    
    }, []);

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <HeaderModulo nombre_modulo={props.modulo}></HeaderModulo>
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="card">
                                <div className="card-body">
                                    <div  className="mb-4" style={{ textAlign : 'right' }}>
                                        <button type="button" className="btn btn-primary mt-3" data-bs-toggle="modal" data-bs-target="#modalNuevoPlan">Crear un Nuevo Plan</button>
                                    </div>
                                    <Tabla></Tabla>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <Modalidades></Modalidades>
                        </div>
                    </div>
                </section>
            </main>
            <Nuevo></Nuevo>
            <Editar></Editar>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    setTablaPlanesPago,
    setListaModalidades
};

export default connect(null, mapDispatchToProps)(PlanesDePago)