import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { alerta, toast, formatDate, formatearMonedaValue } from '../../../services/utilities';
import { get_cartera } from '../../../services/Venta';

function Cartera(props){

    useEffect(() => {
        handleConsultarCartera(formatDate(new Date()),formatDate(new Date()));
    }, []);
    
    const [filtro, setFiltro] = useState({
        "fecha_ini": formatDate(new Date()),
        "fecha_fin": formatDate(new Date())
    });

    const handleInputFiltroChange = (event) => {
        const { name, value } = event.target;
        setFiltro({ ...filtro, [name]: value });
    };

    const [spiner, setSpiner] = useState(false);
    const [verMnesaje, setVerMensaje] = useState(true);
    const [verTable, setVerTable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [cartera, setCartera] = useState([]);

    const handleConsultarCartera = async (fecha_ini, fecha_fin) => {

        setVerMensaje(false);
        setVerTable(false);
        setIsDisabled(true);
        setSpiner(true);
        
        try {
            await get_cartera(fecha_ini, fecha_fin).then( response => {
                if(response.status !== undefined && response.status === 200){
                    if(response.data.cartera.length === 0){
                        setVerMensaje(true);
                    }else{
                        setIsDisabled(false);
                        setCartera(response.data.cartera);
                        setVerTable(true);
                    }
                }else{
                    alerta('Error al consular la cartera en la base de datos');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de la cartera (cartera/Micartera/handleConsultarCartera)');
        }finally {
            setSpiner(false);
        }
    }
console.log(cartera)
    return(
        <React.Fragment>
            <div className="modal fade" id="ModalCarteraVendedor" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="ModalCarteraVendedor" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Cartera</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">

                            <div className='row mb'>
                                <div className='col-md-3 mb-3'>
                                    <label className="mb-1">Desde:</label>
                                    <div className="input-group">
                                        <input 
                                            type="date" 
                                            className="form-control" 
                                            id="fecha_ini" 
                                            name="fecha_ini" 
                                            value={filtro.fecha_ini}
                                            onChange={handleInputFiltroChange} 
                                        />
                                    </div>
                                </div>
                                <div className='col-md-3 mb-3'>
                                    <label className="mb-1">Hasta:</label>
                                    <div className="input-group">
                                        <input 
                                            type="date" 
                                            className="form-control" 
                                            id="fecha_fin" 
                                            name="fecha_fin" 
                                            value={filtro.fecha_fin}
                                            onChange={handleInputFiltroChange} 
                                        />
                                    </div>
                                </div>
                                <div className="col-md-1">
                                    <label className="mb-1">&emsp;</label>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-primary btn-md" onClick={() => handleConsultarCartera(filtro.fecha_ini, filtro.fecha_fin)} >
                                            Buscar
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr className='m-0'/>
                            {verTable && !spiner ?
                                <div className="table-responsive-sm">
                                    <table className='table table-hover' style={{fontSize : '12px'}}>
                                        <thead>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>V. Brutra</th>
                                                <th>Ganancia</th>
                                                <th>S. Anterior</th>
                                                <th>V. Neta</th>
                                                <th>Abonos</th>
                                                <th>Prestamos</th>
                                                <th>Premios</th>
                                                <th>Ingresos</th>
                                                <th>Egresos</th>
                                                <th>Saldo Actual</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {Array.isArray(cartera) ? cartera.map((item, index) => (
                                            <tr className='align-middle' key={index}>
                                                <td><span className="badge text-bg-dark">{item.fecha}</span></td>
                                                <td className='text-secondary'><b>{formatearMonedaValue(item.venta_bruta)}</b></td>
                                                <td className='text-primary'><b>{formatearMonedaValue(item.venta_neta_vendedor)}</b></td>
                                                <td className={item.saldo_anterior > 0 ? 'text-danger' : 'text-success'}>{formatearMonedaValue(item.saldo_anterior)}</td>
                                                <td className='text-danger'>{formatearMonedaValue(item.venta_bruta -  item.venta_neta_vendedor)}</td>
                                                <td className='text-danger'>{formatearMonedaValue(item.cobros_prestamo_vendedor)}</td>
                                                <td className='text-danger'>{formatearMonedaValue(item.abonos)}</td>
                                                <td className='text-success'>{formatearMonedaValue(item.premios)}</td>
                                                <td className='text-success'>{formatearMonedaValue(item.ingresos)}</td>
                                                <td className='text-danger'>{formatearMonedaValue(item.egresos)}</td>
                                                <td className={item.saldo_actual > 0 ? 'text-danger' : 'text-success'}>{formatearMonedaValue(item.saldo_actual)}</td>
                                            </tr>
                                        )) : <tr></tr>}
                                        </tbody>
                                    </table>
                                </div>
                            :''}
                            {spiner ?
                            <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '200px'}}>
                                <div className="spinner-border" role="status"></div>                 
                            </div>
                            :''}
                            {verMnesaje ?
                            <div className='text-center text-secondary my-4'>
                                <h3>Sin resultado de busqueda</h3>
                                <p>Por favor, elija otro rango de fechas para buscar registros de cartera</p>
                            </div>
                            :''}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary mx-2" data-bs-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {
    return {
        data_sesion: state.data_sesion,
    }
}

export default connect(mapSateToProps, null)(Cartera)   