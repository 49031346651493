import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { alerta, toast, cerrarModal } from '../../../services/utilities';
import { ajusteComercio } from '../../../services/Usuario'
import Swal  from 'sweetalert2';

function Ajuste (props){

    useEffect(() => {

        setAjuste({
            "id_transaccion_tipo":"0",
            "valor": 0,
            "id_comercio": props.id_comercio_seleccionado, //Comercio al cual se le realiza el prestamo o abono
            "descripcion" : ""
        })

    },[props.id_comercio_seleccionado]);

    const [ajuste, setAjuste] = useState({
            "id_transaccion_tipo":"0",
            "valor": 0,
            "id_comercio": 0,
            "descripcion" : ""
    });

    const handleInputAjusteChange = (event) => {
        const { name, value } = event.target;
        setAjuste({ ...ajuste, [name]: value });
    };

    const handleGuardarAjuste = () =>{

        if(ajuste.valor <= 0){
            alerta('El valor debe ser mayor a $0');
            return;
        }else if(ajuste.id_transaccion_tipo != 4 && ajuste.id_transaccion_tipo != 5){
            alerta('Seleccione un tipo de transaccion valido')
            return;
        }

        Swal.fire({
            title: "¿ Esta seguro de guardar la transaccion ?",
            text: "Si esta seguro presione el boton Guardar de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Guardar'
        }).then((result) => {

            if (result.isConfirmed) {          
                ajusteComercio(ajuste).then(response => {
                    if(response.status == 201){
                        cerrarModal('modalAjuste');
                        setAjuste({
                            "id_transaccion_tipo":"0",
                            "valor": 0,
                            "id_comercio": props.id_comercio_seleccionado,
                            "descripcion" : ""
                        })                        
                        toast('¡ Perfecto !','Transaccion realizada','success');
                    }else{
                        alerta('Se presento un error al intentar guardar la transaccion',response.response.data.status_message??response.message);
                    }
                });
            }
        });
    }
    
    return(
        <React.Fragment>
            <div className="modal fade" id="modalAjuste" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalAjuste" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Ajustes</h1>
                            <span className="badge text-bg-primary mx-2">Codigo: {props.id_comercio_seleccionado}</span>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-floating mb-3">
                                        <select
                                            className="form-select"
                                            id="id_transaccion_tipo"
                                            name="id_transaccion_tipo"
                                            aria-label="State"
                                            value={ajuste.id_transaccion_tipo}
                                            onChange={handleInputAjusteChange}>
                                            <option value="0" disabled>-Seleccione-</option>
                                            <option value="4">Egreso</option>
                                            <option value="5">Ingreso</option>
                                        </select>
                                        <label htmlFor="id_transaccion_tipo">Tipo de transacción:</label>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-floating mb-3">
                                        <input 
                                            type="number" 
                                            className="form-control form-control-sm" 
                                            name="valor"
                                            id="valor" 
                                            value={ajuste.valor}
                                            onChange={handleInputAjusteChange}/>
                                        <label htmlFor="valor">$ Valor:</label>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-floating mb-3">
                                        <textarea 
                                            rows="4"
                                            maxLength="100"
                                            className="form-control form-control-sm" 
                                            name="descripcion"
                                            id="descripcion" 
                                            value={ajuste.descripcion}
                                            onChange={handleInputAjusteChange}/>
                                        <label htmlFor="valor">Descripción:</label>
                                    </div>
                                </div>

                            </div>                            
                                
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={handleGuardarAjuste}>Guardar</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

}

const mapSateToProps = state => {

    return {
        data_sesion: state.data_sesion,
        id_comercio_seleccionado: state.id_comercio_seleccionado
    }
}

export default  connect(mapSateToProps,null)(Ajuste);