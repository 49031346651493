import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
//https://fiduswriter.github.io/simple-datatables/documentation/
import { DataTable } from "simple-datatables"
import { setIdComercio,setIdComercioGestionado, setGestionando, setIdComercioSeleccionado } from '../../../../actions'
import { alerta, toast, validarEmail, cerrarModal } from '../../../../services/utilities';
import { getComercio } from '../../../../services/Usuario'
import { compose } from "redux";

function Tabla (props){

    useEffect(() => {
        setComercios(props.tablaComercios??[]);
    }, [props.tablaComercios.length > 0]);

    const [filter, setFilter] = useState('');
    const [comercios, setComercios] = useState([]);

    const handleComercioSeleccionado = (id_comercio) => {
        props.setIdComercio(id_comercio)
        props.setIdComercioSeleccionado(id_comercio)
    }

    const handleGestionComercio = (id_usuario) => {
        props.setGestionando('SI');
        props.setIdComercioGestionado(id_usuario);
    }

    const filteredData = comercios.filter((item) => {

        var nombreMatch = ''

        if(item.nombre !== null)
            nombreMatch = item.nombre.toLowerCase().includes(filter.toLowerCase());

        const tipoMatch = item.tipo.toLowerCase().includes(filter.toLowerCase());

        const nicknameMatch = item.nickname.toLowerCase().includes(filter.toLowerCase());

        const aliasMatch = item.alias.toLowerCase().includes(filter.toLowerCase());
        
        const idMatch = item.id_comercio.toString().includes(filter);

        return nombreMatch || aliasMatch || idMatch || nicknameMatch || tipoMatch;

    });

    let tiene_prestamos = false;

    if(Object.keys(props.data_usuario).length !== 0){
        tiene_prestamos = props.data_usuario.data.realiza_prestamos;
    }
    
    return(
        <React.Fragment>
            <div className='col-md-3 pt-4'>
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
                    <input type="text" className="form-control form-control-sm" placeholder="Buscar..." aria-label="Buscar" aria-describedby="basic-addon1" value={filter}
                    onChange={(e) => setFilter(e.target.value)} />
                </div>
            </div>
            <div className="col-md-9">
                <div  className="mb-4" style={{ textAlign : 'right' }}>
                    <button 
                        type="button" 
                        className="btn btn-primary mt-3" 
                        data-bs-toggle="modal" 
                        data-bs-target="#modalNuevoUsuario" 
                        onClick={() => props.setIdComercio(0)}
                    >
                        Nuevo Usuario
                    </button>
                </div>
            </div>
            <div className="col-md-12">
                <div className="table-responsive">
                    <table  id="tablaComercios" className="table table-hover table-sm">
                        <thead>
                            <tr className="text-center">
                                <th>Id</th>
                                <th>Tipo</th>
                                <th>Usuario</th>
                                <th>Nombre</th>
                                <th>Alias</th>
                                <th>Admin Externo</th>
                                <th>Promotor</th>
                                <th>Zona</th>
                                <th>Estado</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {Array.isArray(props.tablaComercios) ? filteredData.map((comercio, index) => (
                            <tr key={comercio.id_comercio} className="text-center" >
                                <td>{comercio.id_comercio}</td>
                                <td>{comercio.tipo}</td>
                                <td>{comercio.nickname}</td>
                                <td>{comercio.nombre}</td>
                                <td>{comercio.alias}</td>
                                <td>{comercio.admin_externo}</td>
                                <td>{comercio.promotor_comercio_id !== null? '('+comercio.promotor_comercio_id+')' : ''} {comercio.promotor}</td>
                                <td>{comercio.zona}</td>
                                <td>
                                    {comercio.estado_id == '1' ?<p className='text-success'>Activo</p> : <p className='text-danger'>Inactivo</p>}
                                </td>
                                <td>
                                    <button 
                                        type="button" 
                                        className="btn btn-outline-primary btn-sm " 
                                        title="Editar" 
                                        data-bs-toggle="modal" 
                                        data-bs-target="#modalNuevoUsuario"
                                        data-id={comercio.id_comercio}
                                        onClick={(e) => handleComercioSeleccionado(e.target.getAttribute('data-id'))}
                                        >
                                        <i 
                                            className="bi bi-gear"  
                                            data-id={comercio.id_comercio} 
                                            onClick={(e) => handleComercioSeleccionado(e.target.getAttribute('data-id'))}
                                        >
                                        </i>
                                    </button>
                                </td>
                                <td>
                                { props.gestionando === 'NO' && comercio.tipo !=='VENDEDOR' ?
                                    <button 
                                        type="button" 
                                        className="btn btn-outline-dark btn-sm " 
                                        title="Gestion Usuario" 
                                        data-id={comercio.id_usuario}
                                        onClick={(e) => handleGestionComercio(e.target.getAttribute('data-id'))}
                                        >
                                        <i 
                                            className="bi bi-eye-fill"  
                                            data-id={comercio.id_usuario} 
                                            onClick={(e) => handleGestionComercio(e.target.getAttribute('data-id'))}
                                        >
                                        </i>
                                    </button>
                                    :''
                                }
                                </td>
                                <td>
                                    {/*VALIDA SI LA EMPRESA TIENE ACTIVA LA CONFIGURACION DE PRESTAMO*/}
                                    {tiene_prestamos?

                                        <>{/*VALIDACIONES DE BOTON PRESTAMO PARA EL TIPO DE ROLA*/}

                                            { (props.data_sesion.rol_id === 0 && comercio.tipo_comercio_id === 1) || 
                                              (props.data_sesion.rol_id === 0 && comercio.tipo_comercio_id === 2 && comercio.admin_externo === null ) ?
                                                <button 
                                                    type="button" 
                                                    className="btn btn-outline-success btn-sm " 
                                                    title="Prestamos"
                                                    data-bs-toggle="modal" 
                                                    data-bs-target="#modalPrestamos"
                                                    data-id={comercio.id_comercio}
                                                    onClick={(e) => handleComercioSeleccionado(e.target.getAttribute('data-id'))}
                                                    >
                                                    <i 
                                                        className="bi bi-currency-dollar"  
                                                        data-id={comercio.id_comercio} 
                                                        onClick={(e) => handleComercioSeleccionado(e.target.getAttribute('data-id'))}
                                                    >
                                                    </i>
                                                </button>
                                            :''}

                                            { props.data_sesion.rol_id === 1 && comercio.tipo_comercio_id === 2 ?
                                              
                                                <button 
                                                    type="button" 
                                                    className="btn btn-outline-success btn-sm " 
                                                    title="Prestamos"
                                                    data-bs-toggle="modal" 
                                                    data-bs-target="#modalPrestamos"
                                                    data-id={comercio.id_comercio}
                                                    onClick={(e) => handleComercioSeleccionado(e.target.getAttribute('data-id'))}
                                                    >
                                                    <i 
                                                        className="bi bi-currency-dollar"  
                                                        data-id={comercio.id_comercio} 
                                                        onClick={(e) => handleComercioSeleccionado(e.target.getAttribute('data-id'))}
                                                    >
                                                    </i>
                                                </button>
                                            :''}

                                            { props.data_sesion.rol_id === 2 && comercio.tipo_comercio_id === 3 ?
                                              
                                              <button 
                                                  type="button" 
                                                  className="btn btn-outline-success btn-sm " 
                                                  title="Prestamos"
                                                  data-bs-toggle="modal" 
                                                  data-bs-target="#modalPrestamos"
                                                  data-id={comercio.id_comercio}
                                                  onClick={(e) => handleComercioSeleccionado(e.target.getAttribute('data-id'))}
                                                  >
                                                  <i 
                                                      className="bi bi-currency-dollar"  
                                                      data-id={comercio.id_comercio} 
                                                      onClick={(e) => handleComercioSeleccionado(e.target.getAttribute('data-id'))}
                                                  >
                                                  </i>
                                              </button>
                                          :''}

                                        </>
                                    :''}
                                </td>
                                <td>
                                    {comercio.prepago !==0 && props.data_sesion.rol_id === 2 ?
                                    <button 
                                        type="button" 
                                        className="btn btn-outline-warning btn-sm " 
                                        title="Prepago"
                                        data-bs-toggle="modal" 
                                        data-bs-target="#modalPrepago"
                                        data-id={comercio.id_comercio}
                                        onClick={(e) => handleComercioSeleccionado(e.target.getAttribute('data-id'))}
                                        >
                                        <i 
                                            className="bi bi-wallet2"  
                                            data-id={comercio.id_comercio} 
                                            onClick={(e) => handleComercioSeleccionado(e.target.getAttribute('data-id'))}
                                        >
                                        </i>
                                    </button>
                                    : ''}
                                </td>
                            </tr>
                        )) : <tr></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        gestionando: state.gestionando,
        tablaComercios: state.tablaComercios,
        data_sesion: state.data_sesion,
        data_usuario: state.data_usuario,
    }
}

const mapDispatchToProps  = {
    setIdComercio,
    setIdComercioGestionado,
    setIdComercioSeleccionado,
    setGestionando
};

export default connect(mapSateToProps,mapDispatchToProps)(Tabla)