import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { setLoteriasHoy, setIdTransaccion, setIdCategoriaModalidadJuego } from '../../../actions'
import Header from "../../header/Header";
import Hoy from "../administracion/loterias/Hoy";
import { get_comercio } from '../../../services/Usuario';
import {  getHoy } from '../../../services/Loterias';
import { get_mis_ventas,  get_mis_ventas_detalle } from '../../../services/Reportes';
import { anaular_venta } from '../../../services/Venta';
import { alerta, toast, formatDate } from '../../../services/utilities';
import DetalleVenta from '../reportes/DetalleVenta';
import VentaApuesta from './VentaApuesta';
import Swal  from 'sweetalert2';
import Cartera from './Cartera';

function Venta(props){

    useEffect(() => { 

        handleConsultarDataComercio();
        handleBuscarVentas();

        getHoy().then(response => {
            if(response.status === 200){
                props.setLoteriasHoy(response.data)
            }else{
                alerta('No se cargaron los resultados de hoy',response.response.data.status_message??response.message,'warning');
            }
        });

    }, []);

    const formatearMoneda = (value) =>{

        const valor = parseFloat(value);
        
        if (!isNaN(valor)) { 
          value = valor.toLocaleString('es-CO', {minimumFractionDigits: 0});
        }else{
            value = 0;
        }
    
        return value;
    }

    const [data_venta, setDataVenta] = useState([]);
    const [filter, setFilter] = useState('');
    const [spinerTabla, setSpinerTabla] = useState(false);
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState('');
    const [subText, setSubText] = useState('');
    const [messageText, setMessageText] = useState('');
    const [cartera, setCartera] = useState({});
    const [categoriasModalidadComercio, setCategoriasModalidadComercio] = useState('');
    const [categoriasModalidadEmpresa, setCategoriasModalidadEmpresa] = useState([]);
    const [idComercio, setidComercio] = useState('');
    const [filtro, setFiltro] = useState({
        "fecha_ini": formatDate(new Date()),
        "fecha_fin": formatDate(new Date()),
        "modalidad":"",
        "tipo_comercio":"99",
        "usuario": "",
        "detalle": ""
    });

    const handleConsultarDataComercio= async () => {
    
        try {
            await get_comercio().then( response => {
    
                if(response.status !== undefined && response.status === 200){
                  setCartera(response.data.data.cartera.cartera)
                  setCategoriasModalidadComercio(response.data.data.categorias_modalidad)
                  setCategoriasModalidadEmpresa(response.data.data.categorias_modalidad_empresa)
                  setidComercio(response.data.data.id_comercio)
                }else{
                  setText('No fue posible cargar la información del comercio');
                  setSubText(response.response.data.status_message);
                  setMessageText(response.response.data.status_code);
                }
            });
    
         }catch (error) {
            setText('Error no controlado la información del comercio')
            setSubText('Por favor intente nuevamente, si el problema persiste contacte a soporte.')
        }
    }

    const chance = categoriasModalidadEmpresa.some(item => item.id_categoria_modalidad_juego === 1);
    const quinta = categoriasModalidadEmpresa.some(item => item.id_categoria_modalidad_juego === 2);
    const astro = categoriasModalidadEmpresa.some(item => item.id_categoria_modalidad_juego === 3);
    const bono = categoriasModalidadEmpresa.some(item => item.id_categoria_modalidad_juego === 4);
    const rifa = categoriasModalidadEmpresa.some(item => item.id_categoria_modalidad_juego === 5);

    const handleInputFiltroChange = (event) => {
        const { name, value } = event.target;
        setFiltro({ ...filtro, [name]: value });
    };

    const handleBuscarVentas = async () => {

        setSpinerTabla(true)

        try {

            await get_mis_ventas(filtro.fecha_ini, filtro.fecha_fin).then( response => {

                if(response.status !== undefined && response.status === 200){
                    //setHeader(response.data.header);
                    setDataVenta(response.data.items);
                }else{
                    setText('No fue posible cargar mis ventas');
                    setSubText(response.response.data.status_message)
                    setMessageText(response.response.data.status_code)
                }
            });

         }catch (error) {
            setSpinerTabla(false)
        }

        setSpinerTabla(false)
    }

    const handleConfirmarEliminarVenta = (id_transaccion) => {

        Swal.fire({
            title: "¿Esta seguro de anular esta venta?",
            text: "Si esta seguro presione el boton Anular de lo contrario Cancelar",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Si, Anular'
        }).then((result) => {
            if (result.isConfirmed) {
                handleEliminarVenta(id_transaccion);
            }
        });
    }
    
    const handleEliminarVenta = async (id_transaccion) => {
        
        setLoading(true);

        try {

            await anaular_venta(id_transaccion).then( response => {
                
                if(response.status !== undefined && response.status === 200){

                    if(response.data.status_message === "OK"){

                        const miIonCard = document.getElementById('fila_venta_'+id_transaccion);
                        if (miIonCard) {
                            miIonCard.style.display = 'none';
                        }
                        alerta('Venta Eliminada','Por favor revise sus reportes para confirmar la eliminación', 'success');
                    }else{
                        alerta('No es posible eliminar la venta', 'La Loteria '+response.data.status_message+' ya cerro')
                    }            
                }else{
                    alerta(response.response.data.status_message, response.response.data.status_code);
                }
            });

         }catch (error) {
            alerta('Error no controlado al consultar el detalle de la venta', 'Por favor intente nuevamente, si el problema persiste contacte a soporte.')
            setLoading(false); 
        }

        setLoading(false);
    }

    const filterDataTabla = data_venta.filter((item) => {

        // Asegúrate de que data_venta es un string
        const searchTerm = typeof filter === 'string' ? filter.toLowerCase() : '';
    
        const categoriaMatch = item.categoria !== null && item.categoria ? item.categoria.toLowerCase().includes(searchTerm) : '';
        const DetalleMatch = item.detalle_venta !== null && item.detalle_venta ? item.detalle_venta.toLowerCase().includes(searchTerm) : '';
        const idMatch = item.id_transaccion !== null && item.id_transaccion ? item.id_transaccion.toString().includes(searchTerm) : '';
        const valorMatch = item.valor !== null && item.valor ? item.valor.toString().includes(searchTerm) : '';
        
        return categoriaMatch || idMatch || valorMatch || DetalleMatch;
    });

    const handleTransaccionSeleccionada = (id_transaccion) => {
        props.setIdTransaccion(id_transaccion)
    }

    const hanldeCambiarCategoriaJuego = (id_categoria_modalidad_juego) => {
        props.setIdCategoriaModalidadJuego(id_categoria_modalidad_juego);
    }

    return(
        <React.Fragment>
            <Header></Header>
            <main id="main-vender">
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card card-saldos-venta">                                        
                                        <p className='m-0'><b>Venta Bruta</b><span>|Hoy</span></p>
                                        <p className='m-0 fs-5 text-success'>${formatearMoneda(cartera.venta_bruta)}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-saldos-venta">                                        
                                        <p className='m-0'><b>Venta Neta</b><span>|Hoy</span></p>
                                        <p className='m-0 fs-5'>${formatearMoneda(cartera.venta_bruta - cartera.venta_neta_vendedor + cartera.cobros_prestamo_vendedor)}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-saldos-venta">                                     
                                        <p className='m-0'><b>Cartera</b></p>
                                        <p className='m-0 fs-5 text-danger'>${formatearMoneda(cartera.saldo_actual)} 
                                            <span 
                                                className='mx-2 fs-6'
                                                style={{textDecoration:'underline', cursor:'pointer'}}
                                                data-bs-toggle="modal"
                                                data-bs-target="#ModalCarteraVendedor"
                                            >Ver mas...
                                            </span>
                                        </p>                            
                                    </div>
                                </div>                            
                            </div>
                            <div className='card p-3' style={{fontSize:'12px'}}>
                                <div className='row'>
                                    <div className='col-md-2 mb-3'>
                                        <label className="mb-1">Desde:</label>
                                        <div className="input-group">
                                            <input 
                                                type="date" 
                                                className="form-control form-control-sm" 
                                                id="fecha_ini" 
                                                name="fecha_ini" 
                                                value={filtro.fecha_ini}
                                                onChange={handleInputFiltroChange} 
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-2 mb-3'>
                                        <label className="mb-1">Hasta:</label>
                                        <div className="input-group">
                                            <input 
                                                type="date" 
                                                className="form-control form-control-sm" 
                                                id="fecha_fin" 
                                                name="fecha_fin" 
                                                value={filtro.fecha_fin}
                                                onChange={handleInputFiltroChange} 
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-2 mb-3'>
                                        <label className="mb-1">Modalidad</label>
                                        <div className='input-group'>
                                            <select 
                                                className="form-select form-control form-select-sm"
                                                name="modalidad"
                                                value={filtro.modalidad}
                                                onChange={handleInputFiltroChange}
                                            >
                                                <option value="">(TODAS)</option>
                                                <option value="1">APUESTA</option>
                                                <option value="2">QUINTA</option>
                                                <option value="3">ASTROS</option>
                                                <option value="4">BONO</option>
                                                <option value="5">RIFA</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label className="mb-1">Detalle</label>
                                        <div className="input-group">
                                            <input 
                                                type="text" 
                                                className="form-control form-control-sm" 
                                                id="detalle" 
                                                name="detalle" 
                                                onChange={(e) => setFilter(e.target.value)}  
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-2 mb-3'>
                                        <label className="mb-1">&emsp;</label>
                                        <div className="input-group">
                                            <button 
                                                type="button" 
                                                className="btn btn-outline-primary btn-sm"
                                                onClick={handleBuscarVentas}
                                            >
                                                Buscar
                                            </button>                                            
                                        </div>
                                                                              
                                    </div>
                                </div>
                                <div className='row'>
                                    {spinerTabla ?
                                        <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '200px'}}>
                                            <div className="spinner-border" role="status"></div>
                                            <h3 className='mb-0 mx-2'>Consultando...</h3>               
                                        </div>
                                    :''}
                                    {loading ?
                                        <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '200px'}}>
                                            <div className="spinner-border" role="status"></div>
                                            <h3 className='mb-0 mx-2'>Anulando venta...</h3>               
                                        </div>
                                    :''}
                                    {data_venta.length <=0 && spinerTabla === false ?
                                        <div className='text-center text-secondary my-4 d-flex  justify-content-center align-items-center flex-column' style={{ 'height' : '200px'}}>
                                            <h3>Sin resultado de búsqueda para los filtros aplicados.</h3>
                                            <p>Por favor elija una combinación de filtros diferente.</p>
                                        </div>
                                    :
                                        <div className='table-container'>
                                            <div className="table-responsive-sm">
                                                <table className='table table-hover'>
                                                    <thead>
                                                        <tr>
                                                            <td className='text-center'><b>Transaccion</b></td>
                                                            <td className='text-center'><b>Fecha</b></td>
                                                            <td className='text-center'><b>Hora</b></td>
                                                            <td className='text-center'><b>Cliente</b></td>
                                                            <td><b>Valor</b></td>
                                                            <td className='text-center'><b>Categoria</b></td>
                                                            <td><b>Detalle</b></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {Array.isArray(data_venta) ? filterDataTabla.map((venta, index) => (
                                                        
                                                        <tr key={index} className='align-middle' id={'fila_venta_'+venta.id_transaccion}>
                                                            <td className='text-center'><span className="badge text-bg-warning"># {venta.id_transaccion}</span></td>
                                                            <td className='text-center'><span className="badge text-bg-dark">{venta.fecha}</span></td>
                                                            <td className='text-center'><span className="badge text-bg-dark">{venta.hora}</span></td>
                                                            <td className='text-center' style={{fontSize : '10px'}}><b>{venta.nombre_cliente}</b></td>
                                                            <td>${formatearMoneda(venta.valor)}</td>
                                                            <td className={venta.categoria === 'APUESTA' ? 'text-success text-center' : venta.categoria === 'QUINTA' ? 'text-info text-center' : 'text-primary text-center'}><b>{venta.categoria}</b></td>
                                                            {/*<td style={{fontSize : '12px'}}>{venta.nombre_cliente} {venta.celular_cliente}</td>*/}
                                                            <td style={{fontSize : '10px'}}>{venta.nombre_cliente} {venta.detalle_venta}</td>
                                                            <td className='text-center'>
                                                                { venta.fecha == formatDate(new Date()) ?
                                                                <i 
                                                                    className="bi bi-trash text-danger" 
                                                                    style={{cursor:'pointer'}}
                                                                    onClick={() => handleConfirmarEliminarVenta(venta.id_transaccion)}                                                              
                                                                >                                                                            
                                                                </i>
                                                                :''}
                                                            </td>
                                                            <td className='text-center'>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-primary btn-sm"
                                                                    data-id={venta.id_transaccion}
                                                                    data-bs-toggle="modal" 
                                                                    data-bs-target="#modalDetalleVenta"
                                                                    onClick={(e) => handleTransaccionSeleccionada(e.target.getAttribute('data-id'))}
                                                                >detalle
                                                                </button>
                                                            </td>                                                            
                                                        </tr>
                                                        
                                                    )) : <tr></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }                                  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="list-group">
                                        <h5 className="card-title m-0">Vender</h5>
                                        { chance ?
                                        <div className="alert alert-success card-option" role="alert" data-bs-toggle="modal" data-bs-target="#ModalVentaApuesta" onClick={() => hanldeCambiarCategoriaJuego(1)}>
                                            <div className='d-flex align-items-center'>
                                                <i className="bi bi-ticket-perforated" style={{fontSize: '20px'}}></i>
                                                <h6 className='my-0 mx-2'>Apuesta</h6>
                                            </div>
                                        </div>:''}
                                        
                                        { quinta ?
                                        <div className="alert alert-info card-option" role="alert" data-bs-toggle="modal" data-bs-target="#ModalVentaApuesta" onClick={() => hanldeCambiarCategoriaJuego(2)}>
                                            <div className='d-flex align-items-center'>
                                                <i className="bi bi-pass" style={{fontSize: '20px'}}></i>
                                                <h6 className='my-0 mx-2'>Quinta</h6>
                                            </div>
                                        </div>:''}
                                        
                                        { false ? // poner luego astro
                                        <div className="alert alert-primary card-option" role="alert" data-bs-toggle="modal" data-bs-target="#ModalVentaApuesta">
                                            <div className='d-flex align-items-center'>
                                                <i className="bi bi-star-half" style={{fontSize: '20px'}}></i>
                                                <h6 className='my-0 mx-2'>Astros</h6>
                                            </div>
                                        </div>:''}
                                        
                                        { false ? // Poner luego rifa
                                        <div className="alert alert-danger card-option" role="alert" data-bs-toggle="modal" data-bs-target="#ModalVentaApuesta">
                                            <div className='d-flex align-items-center'>
                                                <i className="bi bi-bag" style={{fontSize: '20px'}}></i>
                                                <h6 className='my-0 mx-2'>Rifas</h6>
                                            </div>
                                        </div>:''}
                                        
                                        { bono ?
                                        <div className="alert alert-dark card-option" role="alert" data-bs-toggle="modal" data-bs-target="#ModalHabilitarSaldo">
                                            <div className='d-flex align-items-center'>
                                                <i className="bi bi-dice-6" style={{fontSize: '20px'}}></i>
                                                <h6 className='my-0 mx-2'>Bono millonario</h6>
                                            </div>
                                        </div>:''}
                                    </div>
                                </div>
                            </div>
                            <Hoy></Hoy>
                        </div>
                    </div>
                </section>
            </main>
            <DetalleVenta></DetalleVenta>
            <VentaApuesta></VentaApuesta>
            <Cartera></Cartera>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        data_sesion: state.data_sesion,
        id_categoria_modalidad_juego: state.id_categoria_modalidad_juego
    }
}

const mapDispatchToProps  = {
    setLoteriasHoy,
    setIdTransaccion,
    setIdCategoriaModalidadJuego
};

export default connect(mapSateToProps, mapDispatchToProps)(Venta)