import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { alerta } from '../../../services/utilities';
import Header from "../../header/Header";
import Aside from "../../aside/Aside";
import HeaderModulo from "../../header/HeaderModulo";


function Promotor(props){

    useEffect(() => {

    
    }, []);

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <HeaderModulo nombre_modulo={props.modulo}></HeaderModulo>
                <section className="section">
                    hola promotor
                </section>
            </main>
        </React.Fragment>
    );
}

export default connect(null, null)(Promotor)