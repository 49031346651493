import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setDataSesion, setDataUsuario } from "../../actions";
import { sesion } from "../../services/Login";
import { data_general_usuario } from "../../services/Usuario";
import logospace from '../../assets/img/logo.png'
import profile from '../../assets/img/profile.jpg'
import Swal  from 'sweetalert2';
import { alerta } from '../../services/utilities';

function Header(props){

    const [data_sesion, setDataSesion] = useState({});
    const [nombreEmpresa, setNombreEmpresa] = useState("");
    const [nickname, setNickname] = useState("");
    
    useEffect (() =>{

        let id_usuario = null;

        sesion().then(response => {
            if(response.status === 200){

                let data = response.data.data;
                id_usuario = data.id_usuario;

                setDataSesion({
                    "id_usuario": data.id_usuario,
                    "nombre_usuario": data.nombre_usuario,
                    "id_comercio": data.id_comercio,
                    "id_empresa": data.id_empresa,
                    "estado_usuario": data.estado_usuario,
                    "estado_comercio": data.estado_comercio,
                    "estado_empresa": data.estado_empresa,
                    "rol_id": data.rol_id,
                    "tipo_comercio": data.tipo_comercio,
                    "resultados_automaticos": data.resultados_automaticos
                });

                props.setDataSesion(data);

                data_general_usuario(data.id_usuario).then(response => {
                    if(response.status === 200){
                        setNickname(response.data.data.nickname)
                        setNombreEmpresa(response.data.data.nombre_empresa)
                        props.setDataUsuario(response.data);
                    }else{
                       alerta('No fue posible obtener la informacion general del usuario.')
                    }
                });

            }else{
                window.location.href = '/unauthorized';
            }
        })
        
    },[]);

    const handleButtonToggle = () => {
        if (document.querySelector('.toggle-sidebar-btn')) {
            document.querySelector('body').classList.toggle('toggle-sidebar');
        }
    };

    const handleLogout = () => {

        Swal.fire({
            icon: 'question',
            html: '<p style="font-size:16px"><b>Cerrar Sesion</b><br>¿Seguro desea salir?</p>',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: '<span style="font-size:16px"><strong>Salir</strong></span>',
            cancelButtonText: '<span style="font-size:16px"><strong>Cancelar</strong></span>',
            width: 350,
            height:250
        }).then((result) => {
                if (result.isConfirmed) {
                    localStorage.clear();
                    window.location.href = '/';
                }
        })
    }

    return(
        <React.Fragment>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <div className="d-flex align-items-center justify-content-between">
                    {data_sesion.rol_id >= 0 &&  data_sesion.tipo_comercio != 'VENDEDOR'?
                        <a href="/dashboard" className="logo d-flex align-items-center">
                            <img src={logospace} alt="Logo empresa"/>                       
                            <span className="d-none d-lg-block">{nombreEmpresa.toUpperCase()}</span>
                        </a>
                    :   data_sesion.rol_id === - 1 ?
                        <a href="/root/dashboard" className="logo d-flex align-items-center">
                            <img src={logospace} alt="Logo empresa"/>                       
                            <span className="d-none d-lg-block">ROOT</span>
                        </a> : 
                            <h5 className="card-title placeholder-glow">
                                <span className="placeholder col-12"></span>
                            </h5>
                    }

                    {data_sesion.tipo_comercio != 'VENDEDOR'?
                        <i className="bi bi-list toggle-sidebar-btn" onClick={handleButtonToggle}></i>
                    :''}

                    {data_sesion.tipo_comercio === 'VENDEDOR'?
                        <a href="/venta" className="logo d-flex align-items-center">
                            <img src={logospace} alt="Logo empresa"/>                       
                            <span className="d-none d-lg-block">{nombreEmpresa.toUpperCase()}</span>
                        </a>
                    :''}

                </div>
                <nav className="header-nav ms-auto">
                    <ul className="d-flex align-items-center">
                        <li className="nav-item dropdown pe-3">
                            <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                <img src={profile} alt="Profile" className="rounded-circle"/>
                                <span className="d-none d-md-block dropdown-toggle ps-2">{nickname.toLowerCase()}</span>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li className="dropdown-header">
                                    <h6>{data_sesion.nombre_usuario}</h6>
                                    <span>{data_sesion.tipo_comercio}</span>
                                </li>
                                <li>
                                    <hr className="dropdown-divider"/>
                                </li>
                                <li>
                                    {data_sesion.rol_id >= 0 ?
                                    <Link to="/perfil" className="dropdown-item d-flex align-items-center" href="users-profile.html">
                                        <i className="bi bi-person"></i>
                                        <span>Mi Perfil</span>
                                    </Link>
                                    :''}
                                </li>
                                <li>
                                    <hr className="dropdown-divider"/>
                                </li>
                                <li>
                                <a className="dropdown-item d-flex align-items-center" onClick={handleLogout} href="#">
                                    <i className="bi bi-box-arrow-right"></i>
                                    <span>Cerrar sesión</span>
                                </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </header>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    setDataSesion,
    setDataUsuario
};

export default connect(null, mapDispatchToProps)(Header);