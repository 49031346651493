import React, {useEffect, useState} from "react";
import { connect } from 'react-redux';
import { setIdComercio } from '../../../../actions'
import Header from "../../../header/Header";
import Aside from "../../../aside/Aside";
import Lista from "./Lista";

function Loterias(props){

    useEffect(() => {
      
    },[]);

    const [spiner, setSpiner] = useState(false);

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
                <button
                    style={{position: 'fixed',bottom: '20px',right: '20px', 'zIndex': '1000'}}
                    className="btn btn-success me-2"
                    data-bs-toggle="modal" 
                    data-bs-target="#modalLoterias"
                    onClick={() => props.setIdComercio(0)}          
                ><i className="bi bi-ui-checks me-2"></i>Crear Nueva Loteria
                </button>
            <main id="main" className="main">
                <section className="section">
                    <div className="row">
                        <div className="col-md-8">
                            <Lista></Lista>
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    setIdComercio
};

const mapSateToProps = state => {

    return {
        data_sesion: state.data_sesion,
    }
}

export default connect(mapSateToProps,mapDispatchToProps)(Loterias);