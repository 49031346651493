import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setTablaBloqueos } from '../../../../actions'
import { guardarBloqueo, getBloqueos } from '../../../../services/Bloqueo';
import Swal  from 'sweetalert2';
import { alerta, toast, cerrarModal } from '../../../../services/utilities';

function Nuevo (props){

    const handleRefrescarTablaBloqueos = () => {

       getBloqueos().then(response => {
            if(response.status === 200){
                cerrarModal('modalCrearBloqueo');
                props.setTablaBloqueos(response.data)
            }else{
                alerta('No se cargo la tabla de bloqueos o no hay bloqueos actualemente creadas',response.response.data.status_message??response.message,'warning');
            }
        });
    }
    
    const [mostrarSelectCombinado, setMostrarSelectCombinado] = useState(false);
    const [mostrarTipoBloqueo, setMostrarTipoBloqueo] = useState(false);
    
    const [mostrarSelectCantidadCifras, setMostrarSelectCantidadCifras] = useState(false);
    const [bloqueo, setBloqueo] = useState({
        "loteria_id":"",
        "tipo_bloqueo":"",
        "valor_tipo_bloqueo":"",
        "valor_venta":"",
        "valor_cubierta": 0,
        "categoria_modalidad_juego_id":"",
        "combinado":"0"
    })

    const handleInputBloqueoChange = (event) => {
        const { name, value } = event.target;
        setBloqueo({ ...bloqueo, [name]: value });

        if(name === "categoria_modalidad_juego_id"){
            setMostrarSelectCombinado(value === "1" && bloqueo.tipo_bloqueo == 'cifras' ? true : false);
            setMostrarTipoBloqueo(value === "1" || value === "3" ? true : false);
            setMostrarSelectCantidadCifras(value === "1" ? true : false);
        }else if(name === "tipo_bloqueo"){
            setMostrarSelectCombinado(value === "cifras" && bloqueo.categoria_modalidad_juego_id == '1' ? true : false);
        }

    };

    const handleValidarCampos = () =>{

        let msg = false;

        if(bloqueo.loteria_id == ''){
            msg = 'Debe seleccionar una loteria';
        }else if(bloqueo.categoria_modalidad_juego_id == ''){
            msg = 'Debe seleccionar una modalidad de juego.';
        }else if(bloqueo.categoria_modalidad_juego_id == '1' && bloqueo.tipo_bloqueo == ''){
            msg = 'Debe seleccionar un tipo de bloqueo.';
        }else if(bloqueo.tipo_bloqueo == 'cifras' && (bloqueo.valor_tipo_bloqueo > 4 || bloqueo.valor_tipo_bloqueo < 1)){
            msg = 'La cantidad de cifras tiene que ser entre 1 y 4.';
        }else if(bloqueo.tipo_bloqueo == 'numero' && (bloqueo.valor_tipo_bloqueo < 0 ||  bloqueo.valor_tipo_bloqueo == '')){
            msg = 'El numero debe ser cero o mayor.';
        }else if(bloqueo.valor_venta < 1){
            msg = 'El campo valor debe ser mayor a cero.';
        }else if(props.data_sesion.venta_cubierta === 1 && bloqueo.valor_cubierta > bloqueo.valor_venta){
            msg = 'El valor de cubierta no puede ser mayor al valor del tope';
        }

        return msg;

    }

    const handleGuardarBloqueo = () => {

        if(handleValidarCampos()){
            alerta(handleValidarCampos());
            return;
        }

        Swal.fire({
            title: "¿ Esta seguro de guardar la información de la rifa ?",
            text: "Si esta seguro presione el boton Guardar de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Guardar'
        }).then((result) => {
            if (result.isConfirmed) {
                guardarBloqueo(bloqueo).then(response => {
                    if(response.status == 201){
                        handleRefrescarTablaBloqueos()
                        setBloqueo({
                            "loteria_id":"",
                            "tipo_bloqueo":"",
                            "valor_tipo_bloqueo":"",
                            "valor_venta":"",
                            "valor_cubierta": 0,
                            "categoria_modalidad_juego_id":"",
                            "combinado":"0"
                        });
                        toast('¡ Perfecto !','Bloqueo creado exitosamente','success');
                    }else{
                        alerta('Se presento un error al intentar guardar la informacion',response.response.data.status_message??response.message);
                    }
                });
            }
        });
    }

    return(
        <React.Fragment>
            <div className="modal fade" id="modalCrearBloqueo" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalCrearBloqueo" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Crear Tope</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6 pt-3">
                                    <div className="form-floating">
                                        <select 
                                            className="form-select" 
                                            id="loteria_id" 
                                            name="loteria_id" 
                                            aria-label="State"
                                            value={bloqueo.loteria_id}
                                            onChange={handleInputBloqueoChange}
                                        >
                                            <option value="">-- Seleccion --</option>
                                            {props.tableLoterias.data != undefined ? props.tableLoterias.data.map(opcion => (
                                                <option key={opcion.id_loteria} value={opcion.id_loteria}>{opcion.prefijo}</option>
                                            )):''}
                                        </select>
                                        <label htmlFor="loteria_id">Loteira:</label>
                                    </div>
                                </div>
                                <div className="col-md-6 pt-3">
                                    <div className="form-floating">
                                        <select 
                                            className="form-select" 
                                            id="categoria_modalidad_juego_id" 
                                            name="categoria_modalidad_juego_id" 
                                            aria-label="State" 
                                            value={bloqueo.categoria_modalidad_juego_id}
                                            onChange={handleInputBloqueoChange}                                            
                                        >
                                            <option value="">-- Seleccion --</option>
                                            {props.tieneChance ? <option value="1">APUESTA</option> : ''}
                                            {props.tieneQuinta ? <option value="2">QUINTA</option> : ''}
                                            {props.tieneAstro ? <option value="3">ASTROS</option> : ''}
                                        </select>
                                        <label htmlFor="tipo_bloqueo">Modalidad Juego:</label>
                                    </div>
                                </div>
                                {mostrarSelectCantidadCifras?                               
                                <div className="col-md-6 pt-3">
                                    <div className="form-floating">
                                        <select 
                                            className="form-select" 
                                            id="tipo_bloqueo" 
                                            name="tipo_bloqueo" 
                                            aria-label="State" 
                                            value={bloqueo.tipo_bloqueo}
                                            onChange={handleInputBloqueoChange}                                            
                                        >
                                            <option value="">-- Seleccion --</option>
                                            <option value="cifras">Cantidad Cifras</option>
                                            <option value="numero">Numero</option>
                                        </select>
                                        <label htmlFor="tipo_bloqueo">Tipo de Bloqueo:</label>
                                    </div>
                                </div>
                                :''}
                                {mostrarSelectCombinado?
                                <div className="col-md-6 pt-3">
                                    <div className="form-floating">
                                        <select 
                                            className="form-select" 
                                            id="combinado" 
                                            name="combinado" 
                                            aria-label="State" 
                                            value={bloqueo.combinado}
                                            onChange={handleInputBloqueoChange}                                            
                                        >
                                            <option value="0">NO</option>
                                            <option value="1">SI</option>
                                        </select>
                                        <label htmlFor="tipo_bloqueo">Combinado</label>
                                    </div>
                                </div>
                                :''}
                                {mostrarTipoBloqueo?
                                <div className="col-md-6 pt-3">
                                    <div className="form-floating">
                                        <input 
                                            type="number"
                                            className="form-control"
                                            id="valor_tipo_bloqueo"
                                            name="valor_tipo_bloqueo"
                                            value={bloqueo.valor_tipo_bloqueo}
                                            onChange={handleInputBloqueoChange}                                           
                                        />

                                        <label htmlFor="valor_tipo_bloqueo">Cifras / Numero:</label>
                                    </div>
                                </div>
                                :''}
                                <div className="col-md-6 pt-3">
                                    <div className="form-floating">
                                        <input 
                                            type="number"
                                            className="form-control"
                                            id="valor_venta"
                                            name="valor_venta"
                                            value={bloqueo.valor_venta}
                                            onChange={handleInputBloqueoChange}                                            
                                        />
                                        <label htmlFor="valor_venta">$ valor:</label>
                                    </div>
                                </div>
                                {props.data_sesion.venta_cubierta === 1 ?
                                <div className="col-md-6 pt-3">
                                    <div className="form-floating">
                                        <input 
                                            type="number"
                                            className="form-control"
                                            id="valor_cubierta"
                                            name="valor_cubierta"
                                            value={bloqueo.valor_cubierta}
                                            onChange={handleInputBloqueoChange}                                            
                                        />
                                        <label htmlFor="valor_cubierta">$ valor Cubierta:</label>
                                    </div>
                                </div>
                                :''}         
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={handleGuardarBloqueo}>Guardar</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        tableLoterias: state.tableLoterias,
        data_sesion: state.data_sesion
    }
}

const mapDispatchToProps  = {
    setTablaBloqueos
};

export default connect(mapSateToProps, mapDispatchToProps)(Nuevo)