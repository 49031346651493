import React from 'react';
import { connect } from 'react-redux';
import { alerta, toast } from '../../../../services/utilities';
import { setIdRifa, setListaRifas } from '../../../../actions'
import { eliminarRifa, getRifa } from '../../../../services/Rifa';
import Swal  from 'sweetalert2';

function Lista (props){

    const handleRifaSeleccionada = (id_rifa) => {
        props.setIdRifa(id_rifa);
    }

    const handleRefrescarListaRifa = () => {

        getRifa().then(response => {
            if(response.status === 200){
                props.setListaRifas(response.data.data)
            }else{
                alerta('No se cargo la lista de rifas o no hay rifas actualemente creadas',response.response.data.status_message??response.message,'warning');
            }
        });
    }

    const handleElminarRifa = (id_rifa) =>{
        
        Swal.fire({
            title: "¿ Esta seguro de elminar la Rifa ?",
            text: "Si esta seguro presione el boton Eliminar de lo contrario Cancelar",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#D33',
            confirmButtonText: 'Si, ELIMINAR'
        }).then((result) => {
            if (result.isConfirmed) {
                eliminarRifa(id_rifa).then(response => {
                    if(response.status == 200){
                        handleRefrescarListaRifa();
                        toast('¡ Perfecto !','Rifa eliminada exitosamente','success');
                    }else{
                        alerta('Se presento un error al intentar eliminar la rifa',response.response.data.status_message??response.message);
                    }
                });
            }
        });
    }
     
    return(
        <React.Fragment>
            <div className={props.data_sesion.rol_id == 0 ? 'col-md-6' : 'col-md-12'}>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Lista de rifas creadas:</h5>
                        <div className="table-responsive">
                            <table className="table table-hover table-sm">
                                <thead>
                                    <tr>
                                        <th>Descripcion</th>
                                        <th className="text-center">Boleta</th>
                                        <th className="text-center">Fecha</th>
                                        <th className="text-center">Estado</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {Array.isArray(props.listaRifas) ? props.listaRifas.map((rifa, index) => (
                                    <tr key={index}>
                                        <td>{rifa.descripcion}</td>
                                        <td className="text-center">
                                            {Math.floor(rifa.valor_venta).toLocaleString('es-CO', { style: 'currency', currency: 'COP', maximumFractionDigits: 0 })}
                                        </td>
                                        <td className="text-center">{rifa.fecha_termina}</td>
                                        <td className="text-center">
                                            {rifa.estado_id == '8' ?<p className='text-success'>Habilitada</p> : <p className='text-danger'>Cerrada</p>}
                                        </td>
                                        <td className="text-center">
                                            {props.data_sesion.rol_id == 0 ?
                                            <button 
                                                type="button" 
                                                className="btn btn-outline-danger btn-sm " 
                                                title="Eliminar"
                                                onClick={() => handleElminarRifa(rifa.id_rifa)}
                                                >
                                                <i className="bi bi-trash"></i>
                                            </button>
                                            :''}
                                        </td>
                                        <td className="text-center">
                                            <button 
                                                type="button" 
                                                className="btn btn-outline-primary btn-sm " 
                                                title="Ver Loteria" 
                                                data-bs-toggle="modal" 
                                                data-bs-target="#modalEditarRifa"
                                                onClick={() => handleRifaSeleccionada(rifa.id_rifa)}
                                                >
                                                <i className="bi bi-gear"></i>
                                            </button>
                                        </td>
                                    </tr>
                                )) : <tr></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
     );
}

const mapSateToProps = state => {
    return {
        listaRifas: state.listaRifas,
        data_sesion: state.data_sesion,
    }
}

const mapDispatchToProps  = {
    setIdRifa,
    setListaRifas
};

export default  connect(mapSateToProps, mapDispatchToProps)(Lista);