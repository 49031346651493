import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { alerta, toast, cerrarModal } from '../../../../services/utilities';
import { validar_nickname, crerarComercio, getComercio, editarComercio, getModalidadJuegoEmpresa } from '../../../../services/Usuario'
import Swal  from 'sweetalert2';

function Nuevo (props){

    useEffect(() => {

        getComercio().then(response => {

            if(response.status === 200){

                const data = response.data.data.categorias_modalidad_empresa;

                data.forEach(categoria => {
                    if(categoria.id_categoria_modalidad_juego === 1){
                        setComicionChance(categoria.comision);
                        setComercioTieneChance(categoria.estado_id === 1 ? true : false);
                    }else if(categoria.id_categoria_modalidad_juego === 2){
                        setComicionQuinta(categoria.comision);                        
                        setComercioTieneQuinta(categoria.estado_id === 1 ? true : false);
                    }else if(categoria.id_categoria_modalidad_juego === 3){
                        setComicionAstro(categoria.comision);                        
                        setComercioTieneAstro(categoria.estado_id === 1 ? true : false);
                    }else if(categoria.id_categoria_modalidad_juego === 4){
                        setComicionBono(categoria.comision);                        
                        setComercioTieneBono(categoria.estado_id === 1 ? true : false);
                    }else if(categoria.id_categoria_modalidad_juego === 5){
                        setComicionRifa(categoria.comision);                        
                        setComercioTieneRifa(categoria.estado_id === 1 ? true : false);
                    }
                });

            }else{
                alerta('No se cargo las modalidades de juego',response.response.data.status_message??response.message,'warning');
            }
        });

        getModalidadJuegoEmpresa().then(response => {

            if(response.status === 200){

                const data = response.data.data;

                data.forEach(categoria => {
                    if(categoria.id_categoria_modalidad_juego === 1){
                        setTieneChance(categoria.id_categoria_modalidad_juego);
                    }else if(categoria.id_categoria_modalidad_juego === 2){
                        setTieneQuinta(categoria.id_categoria_modalidad_juego);
                    }else if(categoria.id_categoria_modalidad_juego === 3){
                        setTieneAstro(categoria.id_categoria_modalidad_juego);
                    }else if(categoria.id_categoria_modalidad_juego === 4){
                        setTieneBono(categoria.id_categoria_modalidad_juego);
                    }else if(categoria.id_categoria_modalidad_juego === 5){
                        setTieneRifa(categoria.id_categoria_modalidad_juego);
                    }
                });

            }else{
                alerta('No se cargo las modalidades de juego',response.response.data.status_message??response.message,'warning');
            }
        });

        if(props.id_comercio != 0 && props.id_comercio !== undefined){

            getComercio(props.id_comercio).then(response => {
                if(response.status === 200){

                    setDisabledCamposEdit(true);
                    const data = response.data.data;
                    const data_categorias_modalidad = response.data.data.categorias_modalidad;

                    setComercio({
                        "id_comercio":data.id_comercio,
                        "nombre":data.nombre,
                        "apellido":data.apellido,
                        "alias":data.alias,
                        "celular":data.celular,
                        "estado_id":data.estado_id,
                        "tipo_comercio_id":data.tipo_comercio_id,
                        "porcentaje_comision":data.porcentaje_comision,
                        "porcentaje_descuento_prestamo":data.porcentaje_descuento_prestamo,
                        "plan_pago_id":data.plan_pago_id,
                        "zona_id": data.zona_id,
                        "prepago": data.es_prepago
                    })

                    setUsuario({
                        "id_usuario" : data.id_usuario,
                        "nickname":data.nickname,
                        "password":"",
                        "email":data.email
                    });


                    var tiene_chance = false;
                    var tiene_quinta = false;
                    var tiene_astro = false;
                    var tiene_bono = false;
                    var tiene_rifa = false;
                    var chance = 0;
                    var quinta = 0;
                    var astro = 0;
                    var bono = 0;
                    var rifa = 0;

    
                    data_categorias_modalidad.forEach(item => {

                        if(item.categoria_modalidad_juego_id === 1){
                            tiene_chance = item.estado_id == 1 ? true : false;
                            chance = item.comision;
                        }

                        if(item.categoria_modalidad_juego_id === 2){
                            tiene_quinta = item.estado_id == 1 ? true : false;
                            quinta = item.comision;
                        }

                        if(item.categoria_modalidad_juego_id === 3){
                            tiene_astro = item.estado_id == 1 ? true : false;
                            astro = item.comision;
                        }

                        if(item.categoria_modalidad_juego_id === 4){
                            tiene_bono = item.estado_id == 1 ? true : false;
                            bono = item.comision;
                        }

                        if(item.categoria_modalidad_juego_id === 5){
                            tiene_rifa = item.estado_id == 1 ? true : false;
                            rifa = item.comision;
                        }
                        
                    });

                    setCategoriasModalidadJuego({
                        "tiene_chance": tiene_chance,
                        "tiene_quinta": tiene_quinta,
                        "tiene_astro": tiene_astro,
                        "tiene_bono": tiene_bono,
                        "tiene_rifa": tiene_rifa,
                        "chance": chance,
                        "quinta": quinta,
                        "astro": astro,
                        "bono": bono,
                        "rifa": rifa,
                    });
                    
                }else{
                    alerta('No se cargo la informacion del usuario',response.response.data.status_message??response.message,'warning');
                }
            });

        }else{
            setDisabledCamposEdit(false);
            handleLimpiarFormulario()
        }

    },[props.id_comercio,props.data_sesion.plan_pago_id]);


    const [comicionChance, setComicionChance] = useState(null);
    const [comicionQuinta, setComicionQuinta] = useState(null);
    const [comicionAstro, setComicionAstro] = useState(null);
    const [comicionBono, setComicionBono] = useState(null);
    const [comicionRifa, setComicionRifa] = useState(null);

    //INDICA SI LA EMPRESA TIENE HABILITADA LA MODALIDAD
    const [tieneChance, setTieneChance] = useState(null);
    const [tieneQuinta, setTieneQuinta] = useState(null);
    const [tieneAstro, setTieneAstro] = useState(null);
    const [tieneBono, setTieneBono] = useState(null);
    const [tieneRifa, setTieneRifa] = useState(null);

    //INDICA SI EL COMERCIO EN SESION TIENE HABILITADA LA MODALIDAD
    const [comercioTieneChance, setComercioTieneChance] = useState(false);
    const [comercioTieneQuinta, setComercioTieneQuinta] = useState(false);
    const [comercioTieneAstro, setComercioTieneAstro] = useState(false);
    const [comercioTieneBono, setComercioTieneBono] = useState(false);
    const [comercioTieneRifa, setComercioTieneRifa] = useState(false);

    const [disabledCamposEdit, setDisabledCamposEdit] = useState(false);
    const [comercio,setComercio] = useState({
        "id_comercio": 0,
        "nombre":"",
        "apellido":"",
        "alias":"",
        "celular":"",
        "estado_id":"1",
        "tipo_comercio_id":"",
        "porcentaje_comision":0,
        "porcentaje_descuento_prestamo":0,
        "plan_pago_id": props.data_sesion.rol_id === 0 ? "" : props.data_sesion.plan_pago_id ,
        "zona_id": props.data_sesion.rol_id === 0 ? "" : props.data_sesion.zona_id,
        "prepago": false
    });

    const [usuario,setUsuario]  = useState({
        "nickname":"",
        "password":"",
        "email":""
    })

    const [categoriasModalidadJuego, setCategoriasModalidadJuego] = useState({
        "tiene_chance": true,
        "tiene_quinta": true,
        "tiene_astro": true,
        "tiene_bono": true,
        "tiene_rifa": true,
        "chance": 0,
        "quinta": 0,
        "astro": 0,
        "bono": 0,
        "rifa": 0
        
    })

    const handleInputComercioChange = (event) => {

        const { name, value } = event.target;

        if(name == 'porcentaje_comision'){

            const array = [comicionChance,comicionQuinta,comicionAstro,comicionBono,comicionRifa];

            /*Elimina las comisiones nulas, si estan nulas es por que la empresa no la tiene asignada*/
            let newArray = array.filter(function(elemento) {
                return elemento !== null;
            });
            
            const menorValor = Math.min(...newArray);
            if(menorValor !== null && parseInt(value) > parseInt(menorValor)){
                alerta('La comision general no puede ser mayor a su minima comision asiganda','Su minima comision es '+menorValor+'%')
                return;
            }
        }

        if(name == 'porcentaje_comision'){
            
            setCategoriasModalidadJuego({
                "tiene_chance":categoriasModalidadJuego.tiene_chance,
                "tiene_quinta":categoriasModalidadJuego.tiene_quinta,
                "tiene_astro":categoriasModalidadJuego.tiene_astro,
                "tiene_bono":categoriasModalidadJuego.tiene_bono,
                "tiene_rifa":categoriasModalidadJuego.tiene_rifa,
                "chance": value,
                "quinta": value,
                "astro": value,
                "bono": value,
                "rifa": value
            });
        }
        
        if(name == 'porcentaje_descuento_prestamo' && parseInt(value) > parseInt(comercio.porcentaje_comision)){
            alerta('El descuento no puede ser mayor a la comision','La comision es '+comercio.porcentaje_comision+'%')
            return;
        }

        if(name == 'prepago'){
            setComercio({ ...comercio, [name]: event.target.checked });
        }else{
            setComercio({ ...comercio, [name]: value });
        }
        handleValidarCampos();
    };

    const handleInputUsuarioChange = (event) => {
        const { name, value } = event.target;
        setUsuario({ ...usuario, [name]: value });
    };

    const handleInputModalidadJuegoChange = (event) => {

        const { name, value, type } = event.target;

        if(name == 'chance' && parseInt(value) > parseInt(comicionChance)){
            alerta('La comision no puede ser mayor a la que usted tiene asignada','Su comision para CHANCE es '+comicionChance+'%')
            return;
        }else if(name == 'quinta' && parseInt(value) > parseInt(comicionQuinta)){
            alerta('La comision no puede ser mayor a la que usted tiene asignada','Su comision para QUINTA es '+comicionQuinta+'%')
            return;
        }else if(name == 'astro' && parseInt(value) > parseInt(comicionAstro)){
            alerta('La comision no puede ser mayor a la que usted tiene asignada','Su comision para ASTRO es '+comicionAstro+'%')
            return;
        }else if(name == 'bono' && parseInt(value) > parseInt(comicionBono)){
            alerta('La comision no puede ser mayor a la que usted tiene asignada','Su comision para BONO es '+comicionBono+'%')
            return;
        }else if(name == 'rifa' && parseInt(value) > parseInt(comicionRifa)){
            alerta('La comision no puede ser mayor a la que usted tiene asignada','Su comision para RIFA es '+comicionRifa+'%')
            return;
        }

        if(type === 'checkbox'){
            setCategoriasModalidadJuego({ ...categoriasModalidadJuego, [name]: event.target.checked });
        }else{
            setCategoriasModalidadJuego({ ...categoriasModalidadJuego, [name]: value });
        }
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 32) {
          event.preventDefault();
        }
    };

    const handleValidarNickname = (event) =>{

        if(event.target.value !== ''){
            validar_nickname(event.target.value).then(response => {
                if(response.status !== 200){
                    alerta('!Atención¡',response.response.data.status_message??response.message,'info');
                    setUsuario({ ...usuario, ['nickname']: '' });
                }
            });
        }
    }

    const handleValidarCampos = () =>{

        let msg = false;

        if(comercio.nombre == ''){
            msg = 'Debe digitar un nombre valido';
        }else if(comercio.alias == ''){
            msg = 'Debe indicar un alias o apodo, puede ser el mismo nombre';
        }else if(comercio.tipo_comercio_id == ''){
            msg = 'Debe seleccionar un rol';
        }else if(usuario.nickname.length < 3){
            msg = 'El usuario debe tener minimo 3 caracteres';
        }else if((comercio.id_comercio == 0 || comercio.id_comercio === undefined) && usuario.password.length < 4){
            msg = 'La contraseña debe tener minimo 4 caracteres';
        }else if(comercio.plan_pago_id  == ''){
            msg = 'Debe seleccionar un plan de pago';
        }else if(comercio.zona_id  == ''){
            msg = 'Debe seleccionar una zona';
        }
        
        return msg;
    }

    const handleLimpiarFormulario = () =>{

        setComercio({
            "nombre":"",
            "apellido":"",
            "alias":"",
            "celular":"",
            "estado_id":"1",
            "tipo_comercio_id":"",
            "porcentaje_comision":0,
            "porcentaje_descuento_prestamo":0,
            "plan_pago_id": props.data_sesion.rol_id === 0 ? "" : props.data_sesion.plan_pago_id ,
            "zona_id": props.data_sesion.rol_id === 0 ? "" : props.data_sesion.zona_id,
            "prepago": false
        });

        setUsuario({
            "nickname":"",
            "password":"",
            "email":""
        });

        setCategoriasModalidadJuego({
            "tiene_chance":true,
            "tiene_quinta":true,
            "tiene_astro":true,
            "tiene_bono":true,
            "tiene_rifa":true,
            "chance": 0,
            "quinta": 0,
            "astro": 0,
            "bono": 0,
            "rifa": 0
        });
    }

    const handleGuardarUsuario = () =>{

        if(handleValidarCampos()){
            alerta(handleValidarCampos());
            return;
        }

        const data = {
            "comercio" : comercio,
            "usuario" : usuario,
            "comercio_categoria_modalidad_juego": categoriasModalidadJuego
        }
    
        Swal.fire({
            title: "¿ Esta seguro de guardar la información del usuario ?",
            text: "Si esta seguro presione el boton Guardar de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Guardar'
        }).then((result) => {
            if (result.isConfirmed) {

                if(comercio.id_comercio == 0 || comercio.id_comercio === undefined){
                    crerarComercio(data).then(response => {
                        if(response.status == 201){
                            cerrarModal('modalNuevoUsuario');
                            handleLimpiarFormulario();
                            toast('¡ Perfecto !','Usuario creado.','success');
                        }else{
                            alerta('Se presento un error al intentar guardar la información',response.response.data.status_message??response.message);
                        }
                    });
                }else if(comercio.id_comercio !=0 && comercio.id_comercio !== undefined){

                    editarComercio(data).then(response => {
                        if(response.status == 200){
                            cerrarModal('modalNuevoUsuario');
                            toast('¡ Perfecto !','Usuario editado.','success');
                        }else{
                            alerta('Se presento un error al intentar guardar la información',response.response.data.status_message??response.message);
                        }
                    });
                }
            }
        });
    }

    return(
        <React.Fragment>
            <div className="modal fade" id="modalNuevoUsuario" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalNuevoUsuario" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Usuario</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title"><i className="bi bi-ui-checks mx-2"></i>Información personal:</h5>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-floating mb-3">
                                                                <input 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    name="nombre"
                                                                    id="nombre" 
                                                                    value={comercio.nombre}
                                                                    onChange={handleInputComercioChange}/>
                                                                <label htmlFor="nombre">Nombre: <span className="text-danger">*</span></label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 d-none">
                                                            <div className="form-floating mb-3">
                                                                <input 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    name="apellido"
                                                                    id="apellido" 
                                                                    value={comercio.apellido}
                                                                    onChange={handleInputComercioChange}/>
                                                                <label htmlFor="apellido">Apellido:</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-floating mb-3">
                                                                <input 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    name="alias"
                                                                    id="alias" 
                                                                    value={comercio.alias}
                                                                    onChange={handleInputComercioChange}/>
                                                                <label htmlFor="alias">Alias / Apodo: <span className="text-danger">*</span></label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 d-none">
                                                            <div className="form-floating mb-3">
                                                                <input 
                                                                    type="number" 
                                                                    className="form-control" 
                                                                    name="celular"
                                                                    id="celular" 
                                                                    value={comercio.celular}
                                                                    onChange={handleInputComercioChange}/>
                                                                <label htmlFor="celular">Celular:</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-floating mb-3">
                                                                <select 
                                                                    className="form-select" 
                                                                    id="tipo_comercio_id" 
                                                                    name="tipo_comercio_id" 
                                                                    aria-label="State" 
                                                                    disabled={disabledCamposEdit}
                                                                    value={comercio.tipo_comercio_id}
                                                                    onChange={handleInputComercioChange}
                                                                >   

                                                                    { disabledCamposEdit === false && (
                                                                        <>
                                                                            <option>- Seleccione -</option>
                                                                            {props.data_sesion.rol_id == 0 && (
                                                                                <>
                                                                                <option value="1">SOCIO EMPRESA</option>
                                                                                <option value="2">PROMOTOR</option>
                                                                                </>
                                                                            )}
                                                                            {props.data_sesion.rol_id == 1 && (
                                                                                <>
                                                                                <option value="2">PROMOTOR</option>
                                                                                </>
                                                                            )}
                                                                            {props.data_sesion.rol_id == 2 && (
                                                                                <>
                                                                                <option value="3">VENDEDOR</option>
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}

                                                                    { disabledCamposEdit === true && (
                                                                        <>
                                                                            <option value="1">SOCIO EMPRESA</option>
                                                                            <option value="2">PROMOTOR</option>
                                                                            <option value="3">VENDEDOR</option>
                                                                        </>
                                                                    )}
                                                                </select>
                                                                <label htmlFor="tipo_comercio_id">Rol: <span className="text-danger">*</span></label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-floating mb-3">
                                                                <select 
                                                                    className="form-select" 
                                                                    id="estado_id" 
                                                                    name="estado_id" 
                                                                    aria-label="State" 
                                                                    value={comercio.estado_id}
                                                                    onChange={handleInputComercioChange}>
                                                                    <option value="1">Activo</option>
                                                                    <option value="3">Inactivo</option>
                                                                </select>
                                                                <label htmlFor="estado_id">Estado:</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title"><i className="bi bi-person-gear mx-1"></i>Inicio de sesión:</h5>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-floating mb-3">
                                                                <input 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    name="nickname"
                                                                    id="nickname"
                                                                    disabled={disabledCamposEdit}
                                                                    value={usuario.nickname}
                                                                    onKeyDown={handleKeyDown}
                                                                    onChange={handleInputUsuarioChange}
                                                                    onBlur={handleValidarNickname}
                                                                    />
                                                                <label htmlFor="nickname">Usuario: <span className="text-danger">*</span></label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 d-none">
                                                            <div className="form-floating mb-3">
                                                                <input 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    name="email"
                                                                    id="email" 
                                                                    value={usuario.email}
                                                                    onChange={handleInputUsuarioChange}/>
                                                                <label htmlFor="email">Email:</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-floating mb-3">
                                                                <input 
                                                                    type="password" 
                                                                    className="form-control" 
                                                                    name="password"
                                                                    id="password" 
                                                                    value={usuario.password}
                                                                    onChange={handleInputUsuarioChange}/>
                                                                <label htmlFor="password">Contraseña:</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title"><i className="bi bi-gear mx-1"></i>Configuración:</h5>
                                            <div className="row">                                                
                                                <div className="col-md-12">
                                                    <div className="form-floating mb-3">
                                                        <input 
                                                            type="number" 
                                                            className="form-control form-control-sm" 
                                                            name="porcentaje_comision"
                                                            id="porcentaje_comision" 
                                                            value={comercio.porcentaje_comision}
                                                            onChange={handleInputComercioChange}/>
                                                        <label htmlFor="porcentaje_comision">% Comision general:</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <button 
                                                        className="btn btn-dark" 
                                                        type="button" 
                                                        data-bs-toggle="collapse" 
                                                        data-bs-target="#collapseExample" 
                                                        aria-expanded="false" 
                                                        aria-controls="collapseExample"
                                                    >
                                                        Comision Por Modalidad
                                                    </button>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="collapse" id="collapseExample">
                                                        <div className="card card-body pt-3">
                                                            <div className="row">
                                                                
                                                                { tieneChance && comercioTieneChance ? (
                                                                    <>
                                                                        <label className="form-check-label" htmlFor="bonos">% Apuesta:</label>
                                                                        <div className="input-group mb-3">
                                                                            <div className="input-group-text">
                                                                                <div className="form-check form-switch">
                                                                                    <input 
                                                                                        onChange={handleInputModalidadJuegoChange} 
                                                                                        className="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        role="switch" 
                                                                                        id="tiene_chance" 
                                                                                        name="tiene_chance" 
                                                                                        checked={categoriasModalidadJuego.tiene_chance}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <input 
                                                                                type="number"
                                                                                maxLength={3} 
                                                                                className="form-control" 
                                                                                id="chance"
                                                                                name="chance"                                                                                
                                                                                value={categoriasModalidadJuego.chance}
                                                                                onChange={handleInputModalidadJuegoChange}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                ):''}
                                                                { tieneQuinta === 2 && comercioTieneQuinta ? (
                                                                    <>
                                                                        <label className="form-check-label" htmlFor="bonos">% Quinta</label>
                                                                        <div className="input-group mb-3">
                                                                            <div className="input-group-text">
                                                                                <div className="form-check form-switch">
                                                                                    <input 
                                                                                        onChange={handleInputModalidadJuegoChange} 
                                                                                        className="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        role="switch" 
                                                                                        id="tiene_quinta" 
                                                                                        name="tiene_quinta" 
                                                                                        checked={categoriasModalidadJuego.tiene_quinta}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <input 
                                                                                type="number"
                                                                                maxLength={3} 
                                                                                className="form-control" 
                                                                                id="quinta"
                                                                                name="quinta"                                                                                
                                                                                value={categoriasModalidadJuego.quinta}
                                                                                onChange={handleInputModalidadJuegoChange}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                ):''}
                                                                { tieneAstro === 3 && comercioTieneAstro ? (                                                                    
                                                                    <>
                                                                        <label className="form-check-label" htmlFor="bonos">% Astro:</label>
                                                                        <div className="input-group mb-3">
                                                                            <div className="input-group-text">
                                                                                <div className="form-check form-switch">
                                                                                    <input 
                                                                                        onChange={handleInputModalidadJuegoChange} 
                                                                                        className="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        role="switch" 
                                                                                        id="tiene_astro" 
                                                                                        name="tiene_astro" 
                                                                                        checked={categoriasModalidadJuego.tiene_astro}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <input 
                                                                                type="number"
                                                                                maxLength={3} 
                                                                                className="form-control" 
                                                                                id="astro"
                                                                                name="astro"                                                                                
                                                                                value={categoriasModalidadJuego.astro}
                                                                                onChange={handleInputModalidadJuegoChange}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                ):''}
                                                                { tieneBono === 4 && comercioTieneBono ? (
                                                                    
                                                                    <>
                                                                        <label className="form-check-label" htmlFor="bonos">% Bono:</label>
                                                                        <div className="input-group mb-3">
                                                                            <div className="input-group-text">
                                                                                <div className="form-check form-switch">
                                                                                    <input 
                                                                                        onChange={handleInputModalidadJuegoChange} 
                                                                                        className="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        role="switch" 
                                                                                        id="tiene_bono" 
                                                                                        name="tiene_bono" 
                                                                                        checked={categoriasModalidadJuego.tiene_bono}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <input 
                                                                                type="number"
                                                                                maxLength={3} 
                                                                                className="form-control" 
                                                                                id="bono"
                                                                                name="bono"                                                                                
                                                                                value={categoriasModalidadJuego.bono}
                                                                                onChange={handleInputModalidadJuegoChange}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                    
                                                                ) : ""}
                                                                { tieneRifa === 5 && comercioTieneRifa ? (
                                                                    
                                                                    <>
                                                                        <label className="form-check-label" htmlFor="bonos">% Rifa:</label>
                                                                        <div className="input-group mb-3">
                                                                            <div className="input-group-text">
                                                                                <div className="form-check form-switch">
                                                                                    <input 
                                                                                        onChange={handleInputModalidadJuegoChange} 
                                                                                        className="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        role="switch" 
                                                                                        id="tiene_rifa" 
                                                                                        name="tiene_rifa" 
                                                                                        checked={categoriasModalidadJuego.tiene_rifa}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <input 
                                                                                type="number"
                                                                                maxLength={3} 
                                                                                className="form-control" 
                                                                                id="rifa"
                                                                                name="rifa"                                                                                
                                                                                value={categoriasModalidadJuego.rifa}
                                                                                onChange={handleInputModalidadJuegoChange}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                ):''}
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                       
                                                <div className="col-md-12">
                                                    <div className="form-floating mb-3">
                                                        <select
                                                            className="form-select" 
                                                            id="plan_pago_id" 
                                                            name="plan_pago_id" 
                                                            aria-label="State"
                                                            disabled={props.data_sesion.rol_id === 0 ? false : true}
                                                            value={comercio.plan_pago_id}
                                                            onChange={handleInputComercioChange}
                                                        >
                                                            <option value="">- Seleccione -</option>
                                                            {Array.isArray(props.tablaPlanesPago) ? props.tablaPlanesPago.map((plan, index) => (
                                                                <option key={index} value={plan.id_plan_pago}>{plan.nombre}</option>
                                                            )) : <option value="">- Seleccione -</option>}
                                                        </select>
                                                        <label htmlFor="plan_pago_id">Plan Pago: <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-floating mb-3">
                                                        <select 
                                                            className="form-select" 
                                                            id="zona_id" 
                                                            name="zona_id" 
                                                            aria-label="State" 
                                                            disabled={props.data_sesion.rol_id === 0 ? false : true}
                                                            value={ comercio.zona_id }
                                                            onChange={handleInputComercioChange}
                                                        >   
                                                            <option value="">- Seleccione -</option>
                                                            {Array.isArray(props.listaZonas) ? props.listaZonas.map((zona, index) => (
                                                                <option key={index} value={zona.id_zona}>{zona.nombre}</option>
                                                            )) : <option value="">- Seleccione -</option>}
                                                        </select>
                                                        <label htmlFor="zona_id">Zona: <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                {comercio.tipo_comercio_id !== ''?
                                                <div className="col-md-6">
                                                    <div className="form-floating mb-3">
                                                        <input
                                                            type="number"
                                                            className="form-control form-control-sm"
                                                            name="porcentaje_descuento_prestamo"
                                                            id="porcentaje_descuento_prestamo"
                                                            value={comercio.porcentaje_descuento_prestamo}
                                                            onChange={handleInputComercioChange}/>
                                                        <label htmlFor="porcentaje_descuento_prestamo">% Prestamo:</label>
                                                    </div>
                                                </div>
                                                :''}
                                                { comercio.tipo_comercio_id === '3' || comercio.tipo_comercio_id === 3 ?
                                                    <div className="col-md-6">
                                                        <div className="form-check form-switch">
                                                            <input 
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="prepago"
                                                                name="prepago"                                                            
                                                                onChange={handleInputComercioChange}
                                                            />
                                                            <label className="form-check-label" htmlFor="prepago">¿Usuario Prepago?</label>
                                                        </div>
                                                    </div>
                                                :''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={handleGuardarUsuario}>Guardar</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapSateToProps = state => {

    return {
        tablaPlanesPago: state.tablaPlanesPago,
        listaZonas: state.listaZonas,
        data_sesion: state.data_sesion,
        id_comercio: state.id_comercio
    }
}

export default  connect(mapSateToProps,null)(Nuevo);