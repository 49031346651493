import React, {useEffect, useState} from "react";
import { connect } from 'react-redux';
import Header from "../../../header/Header";
import Aside from "../../../aside/Aside";
import { alerta, toast, formatDate } from '../../../../services/utilities';
import { getListaEmpresas, getVentasEliminadas } from '../../../../services/root'
import { setIdTransaccion} from '../../../../actions'
import DetalleVenta from '../../reportes/DetalleVenta';



function Eliminadas(props){

    useEffect(() => {
        handleCargarDataModulo();
    },[]);

    const formatearMoneda = (value) =>{
        const valor = parseFloat(value);
        if (!isNaN(valor)) { 
          value = valor.toLocaleString('es-CO', {minimumFractionDigits: 0});
        }else{
            value = 0;
        }
        return value;
    }

    const [spiner, setSpiner] = useState(false);
    const [verMnesaje, setVerMensaje] = useState(true);
    const [verTable, setVerTable] = useState(false);
    const [filterTabla, setFilterTabla] = useState('');
    const [listaEmpresas, setListaEmpresas] = useState([]);
    const [filter, setFilter] = useState('');
    const [listaVentas, setlistaVentas] = useState([]);

    const handleCargarDataModulo = async () => {
        
        //Solo se ejeucta cuando la llama el ROOT
        if(props.data_sesion.tipo_comercio != 'EMPRESA'){
            setSpiner(true);

            try {
                await getListaEmpresas().then(response => {
                    if(response.status === 200){
                        setListaEmpresas(response.data.data)
                    }else{
                        alerta('No fue posible cargar la lista de empresas','','warning');
                    }
                });

            }catch (error) {
                alerta('Error no controlado al cargar la infromación de empresas  (root/lista/empresas) ->'+ error);
            }finally {
                setSpiner(false);
            }
        }
    }

    const [filtro, setFiltro] = useState({
        "fecha_ini": formatDate(new Date()),
        "fecha_fin": formatDate(new Date()),
        "id_empresa": props.data_sesion.tipo_comercio === 'EMPRESA' ? props.data_sesion.id_empresa : "",
    });

    const handleInputFiltroChange = (event) => {
        const { name, value } = event.target;
        setFiltro({ ...filtro, [name]: value });
    };

    const handleTransaccionSeleccionada = (id_transaccion) => {
        props.setIdTransaccion(id_transaccion)
    }

    const handleBuscarVentas = async () => {

        if(filtro.id_empresa === "" || filtro.id_empresa.length === 0 ){
            toast('Seleccione una empresa','Empresa invalida');
            return;
        }

        setVerMensaje(false);
        setSpiner(true);
        setVerTable(false);

        try {
            await getVentasEliminadas(filtro.id_empresa,filtro.fecha_ini,filtro.fecha_fin).then( response => {

                if(response.status !== undefined && response.status === 200){
                    
                    if(response.data.ventas.length > 0){
                        setlistaVentas(response.data.ventas);
                        setVerTable(true);
                    }else{
                        setVerMensaje(true)
                        setVerTable(false);
                    }

                }else{
                    toast('Error al ejecutar la consulta','');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de ventas  (reportes/ventaDetallada/handleBuscarVentas) ->'+ error);
        }finally {
            setSpiner(false);
        }
    }

    const filterDataTabla = listaVentas.filter((item) => {

        const vendedorMatch = item.vendedor !== null && item.vendedor  ? item.vendedor.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const categoriaMatch = item.categoria !== null && item.categoria ? item.categoria.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const DetalleMatch = item.detalle_venta !== null && item.detalle_venta ? item.detalle_venta.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const idMatch =  item.id_transaccion !== null && item.id_transaccion ?  item.id_transaccion.toString().includes(filterTabla) : '';
        const idComercioMatch = item.id_comercio !== null && item.id_comercio  ?  item.id_comercio.toString().includes(filterTabla) : '';
        const valorMatch = item.valor !== null && item.valor  ?  item.valor.toString().includes(filterTabla) : '';
        return vendedorMatch || categoriaMatch || idMatch || idComercioMatch || valorMatch || DetalleMatch;
    });

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <section className="section">
                <div className='row'>
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Desde:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_ini" 
                                    name="fecha_ini" 
                                    value={filtro.fecha_ini}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Hasta:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_fin" 
                                    name="fecha_fin" 
                                    value={filtro.fecha_fin}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                        {props.data_sesion.tipo_comercio != 'EMPRESA' ?
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Empresa</label>
                            <div className='input-group'>
                                <select 
                                    className="form-select form-control"
                                    name="id_empresa"
                                    value={filtro.id_empresa}
                                    onChange={handleInputFiltroChange}
                                >
                                    <option value="">-Seleccione-</option>
                                    {Array.isArray(listaEmpresas) && listaEmpresas.length > 0  ? listaEmpresas.map((empresa, index) => (
                                        <option value={empresa.id_empresa}>{empresa.nombre}</option>
                                    )) : ''}
                                </select>
                            </div>
                        </div>
                        :''}
                        { verTable ?
                        <div className="col-md-3">
                            <label className="mb-1">&emsp;</label>
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
                                <input 
                                    type="text" 
                                    className="form-control form-control-sm" 
                                    placeholder="Buscar..." 
                                    aria-label="Buscar" 
                                    aria-describedby="basic-addon1" 
                                    value={filterTabla}
                                    onChange={(e) => setFilterTabla(e.target.value)} 
                                />
                            </div>
                        </div>
                        : ''}
                        <div className="col-md-1">
                            <label className="mb-1">&emsp;</label>
                            <div className="input-group">
                                <button 
                                    type="button" 
                                    className="btn btn-outline-primary btn-md"
                                    onClick={handleBuscarVentas}
                                >
                                    Buscar
                                </button>
                            </div>
                        </div>
                    </div>
                    {verTable ?
                    <>
                        <div className='d-flex justify-content-end'>
                            <span className='text-secondary mx-4' style={{fontSize :'12px'}}>Total Registros {filterDataTabla.length}</span>
                        </div>
                        <div className="card" style={{ overflow : 'auto', height:"400px"}}>
                            <div className="card-body">
                                <div className='table-container'>
                                    <div className="table-responsive-sm">
                                        <table className='table table-hover'>
                                            <thead>
                                            <tr className='text-center'>
                                                <td><b>Transaccion</b></td>
                                                <td><b>Fecha</b></td>
                                                <td><b>Hora</b></td>
                                                <td><b>Vendedor</b></td>
                                                <td><b>Modalidad</b></td>
                                                <td><b>Valor</b></td>
                                                <td><b>Resumen</b></td>
                                                <td><b><i className="bi bi-clipboard-heart"></i> detalle</b></td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {Array.isArray(listaVentas) ? filterDataTabla.map((venta, index) => (
                                                <tr key={index}>
                                                    <td className='text-center'><span className="badge text-bg-warning"># {venta.id_transaccion}</span></td>
                                                    <td className='text-center'><span className="badge text-bg-dark">{venta.fecha}</span></td>
                                                    <td className='text-center'><span className="badge text-bg-dark">{venta.hora}</span></td>
                                                    <td className='text-center' style={{fontSize : '10px'}}><b>{venta.id_comercio} - {venta.vendedor}</b></td>
                                                    <td className={venta.categoria === 'CHANCE' ? 'text-success text-center' : venta.categoria === 'QUINTA' ? 'text-info text-center' : 'text-primary text-center'}><b>{venta.categoria}</b></td>
                                                    <td className='text-center'>${formatearMoneda(venta.valor)}</td>
                                                    <td style={{fontSize : '10px'}}>{venta.detalle_venta}</td>
                                                    <td className='text-center'>
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-primary btn-sm"
                                                            data-id={venta.id_transaccion}
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#modalDetalleVenta"
                                                            onClick={(e) => handleTransaccionSeleccionada(e.target.getAttribute('data-id'))}
                                                        >detalle
                                                        </button>
                                                    </td>
                                                </tr>
                                            )) : <tr></tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :''}

                    {spiner ?
                    <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '200px'}}>
                        <div className="spinner-border" role="status"></div>                 
                    </div>
                    :''}
                    {verMnesaje ?
                    <div className='text-center text-secondary my-4'>
                        <h3>Sin resultado de búsqueda para los filtros aplicados.</h3>
                        <p>Por favor elija una combinación de filtros diferente.</p>
                    </div>
                    :''}

                </section>
            </main>
            <DetalleVenta></DetalleVenta>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        data_sesion: state.data_sesion,
    }
}

const mapDispatchToProps  = {
    setIdTransaccion
};

export default connect(mapSateToProps,mapDispatchToProps)(Eliminadas);