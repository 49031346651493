import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { getDetalleVenta } from '../../../services/Reportes';
import { alerta, toast } from '../../../services/utilities';
import { get_vaucher } from '../../../services/Venta';
import jsPDF from 'jspdf';

function DetalleVenta(props){

    useEffect(() => {
        if(props.id_transaccion !==0)
            handleConsultarTransaccion();
    }, [props.id_transaccion]);

    const [spiner, setSpiner] = useState(true);
    const [detalleVenta, setDetalleVenta] = useState({});
    const [verMnesaje, setVerMensaje] = useState(false);
    const [verDetalle, setVerDetalle] = useState(false);

    const formatearMoneda = (value) =>{
        const valor = parseFloat(value);
        if (!isNaN(valor)) { 
          value = valor.toLocaleString('es-CO', {minimumFractionDigits: 0});
        }else{
            value = 0;
        }
        return value;
    }

    const handleConsultarTransaccion= async () => {
        
        setSpiner(true);
        setVerMensaje(false);
        setVerDetalle(false);

        try {
            await getDetalleVenta(props.id_transaccion).then( response => {
                if(response.status !== undefined && response.status === 200){
                    setVerDetalle(true)
                    setDetalleVenta(response.data.transaccion)
                }else{
                    setVerMensaje(true);
                    toast('No se cargo la informacion de la venta','');
                }
            });
         }catch (error) {
            alerta('Error no controlado al cargar la infromación del select de usuarios (reportes/DetalleVenta/handleConsultarTransaccion) ->'+ error);
        }finally {
            setSpiner(false);
        }
    } 

    const generarPDF = async (idTransaccion) => {

        // Configuración inicial del documento
        let doc = new jsPDF({
            orientation: 'portrait',  // Orientación vertical
            unit: 'mm',               // Usar milímetros como unidad de medida
            format: [55, 100],        // Tamaño temporal, lo recalcularemos más adelante
        });
        
        doc.setFontSize(7);
    
        try {
    
            await get_vaucher(idTransaccion).then(response => {
                
                if(response.status === 200 && response.data.status_message === 'OK') {
    
                    let data = response.data.data;
    
                    // Generar el texto del recibo
                let receiptText = 
`===============================
    RECIBO DE VENTA ${data.id_transaccion}
===============================
Fecha: ${data.fecha}
Hora:  ${data.hora}
Tipo:  ${data.modalidad}
-------------------------------
LOTERIAS
${data.loterias}
-------------------------------
NUMERO   MODALIDAD     VALOR`;
    
                    // Añadir los items
                    data.items.forEach((item) => {
                        const numero = item.numero.padEnd(6, ' ');  
                        const prefijo = item.prefijo.padEnd(8, ' '); 
                        const valor = item.valor.padStart(10, ' ');  
                        receiptText += `\n${numero}   ${prefijo}   ${valor}`;
                    });
    
                    // Añadir el pie de página
                receiptText += `
-------------------------------
Total: ${data.total}
===============================
    ¡Gracias por su compra!
===============================`;
    
                    // Dividir el texto en líneas
                    const textLines = doc.splitTextToSize(receiptText, 48); // 48mm de ancho para el texto
    
                    // Calcular la altura dinámica basada en la cantidad de líneas
                    const lineHeight = 4;  // Altura de cada línea (en mm)
                    const contentHeight = textLines.length * lineHeight; // Altura total del contenido
    
                    // Establecer la altura mínima a 90 mm
                    const minHeight = 90;
                    const totalHeight = Math.max(contentHeight + 10, minHeight); // +10 para los márgenes superior/inferior
    
                    // Crear un nuevo documento con la altura calculada
                    doc = new jsPDF({
                        orientation: 'portrait',
                        unit: 'mm',
                        format: [55, totalHeight],  // Usamos la altura calculada y el anoche del papel para factura pos
                    });
    
                    // Establecer la fuente y tamaño nuevamente
                    doc.setFontSize(7);
    
                    // Posición inicial
                    let y = 5; // Margen superior de 5 mm
    
                    // Añadir las líneas al PDF
                    textLines.forEach(line => {
                        doc.text(line, 5, y); // X=5 (margen izquierdo), Y dinámico
                        y += lineHeight;      // Mover la posición Y para la siguiente línea
                    });
    
                    // Crear el Blob y abrir el PDF en una nueva pestaña
                    const pdfBlob = doc.output('blob');
                    const pdfUrl = URL.createObjectURL(pdfBlob);
                    window.open(pdfUrl);
    
                } else {
                    alerta('Error al imprimir', 'No fue posible generar la información de impresión.');
                }
            });
    
        } catch (error) {
            alerta('Error al imprimir:', error);
        }
    };

    return(
        <React.Fragment>
            <div className="modal fade" id="modalDetalleVenta" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalDetalleVenta" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Detalle de la venta <span className="badge text-bg-warning"># {props.id_transaccion}</span></h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        <div className='row'>
                            <div className='col-md-7'>
                                {verDetalle ?
                                    <div className='mx-2'>
                                        <h4>Movimientos</h4>
                                        <div className='d-flex justify-content-between m-0'>
                                            <p className='m-0'>Codigo Juego</p>
                                            <span className="badge text-bg-info">{detalleVenta.id_juego}</span>
                                        </div>
                                        <hr className='m-1'/>
                                        <div className='d-flex justify-content-between'>
                                            <p className='m-0'>Venta Bruta</p>
                                            <p className='text-primary m-0'><b>${formatearMoneda(detalleVenta.valor)}</b></p>
                                        </div>
                                        <hr className='m-1'/>
                                        <div className='d-flex justify-content-between m-0'>
                                            <p className='m-0'>Neta Vendedor</p>
                                            <p className='text-success m-0'><b>${formatearMoneda(detalleVenta.neto_vendedor)}</b></p>
                                        </div>
                                        <hr className='m-1'/>
                                        <div className='d-flex justify-content-between m-0'>
                                            <p className='m-0'>Neta Promotor</p>
                                            <p className='text-success m-0'><b>${formatearMoneda(detalleVenta.neto_promotor)}</b></p>
                                        </div> 
                                        <hr className='m-1'/>                               
                                        <div className='d-flex justify-content-between m-0'>
                                            <p className='m-0'>Cobro Vendedor</p>
                                            <p className='text-danger m-0'><b>${formatearMoneda(detalleVenta.cobro_vendedor)}</b></p>
                                        </div>
                                        <hr className='m-1'/>
                                        {props.data_sesion.tipo_comercio !== 'VENDEDOR'?
                                            <>
                                                <div className='d-flex justify-content-between m-0'>
                                                    <p className='m-0'>Cobro Promotor</p>
                                                    <p className='text-danger m-0'><b>${formatearMoneda(detalleVenta.cobro_promotor)}</b></p>
                                                </div>
                                                <hr className='m-1'/>
                                            </>
                                        :''}
                                        
                                        {props.data_sesion.tipo_comercio !== 'VENDEDOR'?
                                            <div className='d-flex justify-content-between m-0'>
                                                <p className='m-0'>Neto Empresa + Cobros</p>
                                                <p className='text-success m-0'><b>${formatearMoneda((detalleVenta.neto_empresa + detalleVenta.cobro_promotor + detalleVenta.cobro_vendedor))}</b></p>
                                            </div>  
                                        :''}                                  
                                    </div>
                                :''}
                            </div>
                            <div className='col-md-5'>
                                <div className='mx-2'>
                                    <h4>{detalleVenta.categoria_modalidad_juego} <span style={{fontSize : '14px'}}> | Tipo Juego</span></h4>
                                    {Array.isArray(detalleVenta.loterias) ? detalleVenta.loterias.map((loteria, index) => (
                                        <span className="badge text-bg-dark">{loteria.prefijo}</span> 
                                    )) : ''}
                                    <table className='table table-hover text-center' style={{fontSize : '14px'}}>
                                        <thead>
                                            <tr>
                                                <td><b>Numero</b></td>
                                                <td><b>Modalidad</b></td>
                                                <td><b>$ Valor</b></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {Array.isArray(detalleVenta.numeros) ? detalleVenta.numeros.map((numero, index) => (
                                            <tr>
                                                <td>{numero.numero}{detalleVenta.categoria_modalidad_juego === 'QUINTA' ? '-'+numero.serie : ''} {detalleVenta.categoria_modalidad_juego === 'ASTROS' ? numero.astro : ''}</td>
                                                <td>{numero.modalidad_juego}</td>
                                                <td>{formatearMoneda(numero.valor)}</td>
                                            </tr>
                                        )) : <tr></tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {spiner ?
                        <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '200px'}}>
                            <div className="spinner-border" role="status"></div>                 
                        </div>
                        :''}
                        {verMnesaje ?
                        <div className='text-center text-secondary my-4'>
                            <h3>Sin resultado de búsqueda para la venta seleccionada.</h3>
                            <p>Es posible que la inforación ya no este diponible.</p>
                        </div>
                        :''}
                        </div>
                        <div className="modal-footer">                            
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            {props.data_sesion.tipo_comercio === 'VENDEDOR'?
                            <button 
                                type="button" 
                                className="btn btn-dark"
                                onClick={() => generarPDF(props.id_transaccion)}
                            >Imprimir
                            </button>
                            :''}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {
    return {
        data_sesion: state.data_sesion,
        id_transaccion: state.id_transaccion
    }
}

export default connect(mapSateToProps, null)(DetalleVenta)