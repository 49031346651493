import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { toast, formatearMonedaValue, alerta } from '../../../services/utilities';
import { validar_topes_numero, venta, get_vaucher } from '../../../services/Venta';
import Swal  from 'sweetalert2';
import jsPDF from 'jspdf';

function VentaApuesta(props){

    const lista_loterias = props.loterias_hoy.data??[];
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [idTransaccion, setIdTransaccion] = useState(0);

    const handleCheckiarLoteria = (event) => {

        let updatedLoterias = [...form.loterias];
    
        if (event.target.checked) {
            updatedLoterias.push(event.target.value);
        } else {
            updatedLoterias = updatedLoterias.filter(item => item !== event.target.value);
        }
    
        setForm({ ...form, loterias: updatedLoterias });
    }

    const handleNextStep = () => {

        if(form.loterias.length <= 0){
            toast('Seleccione una loteria','Puede seleccionar una o varias loterias')
            return
        }
        
        const total = form.numeros.reduce((acumulador, objeto) => {
            const valor = parseFloat(objeto.valor) || 0;
            const comb = parseFloat(objeto.comb) || 0;
            return acumulador + valor + comb;
        }, 0);

        setForm({ ...form, total : total * form.loterias.length });
        setStep(step + 1);
    }

    const handleBeforeStep = () => {
        setStep(step - 1);
        if((step - 1) <= 0){
            setForm({ ...form, ["loterias"]: [] });
        }
    }
 
    const [numero, setNumero] = useState({
        "id": null,
        "numero": "",
        "serie": "",
        "astro": [],
        "valor": "",
        "comb": ""
    });

    const [form, setForm] = useState({
        "nombre": "",
        "celular": "",
        "categoria_modalidad_juego": props.id_categoria_modalidad_juego,
        "total": 0,
        "loterias":[],
        "numeros":[],
        "version": "WEB"}
    );

    const handleInputChange = (event) => {

        const { name, value } = event.target;
        if(name === 'valor' || name === 'comb'){
            var inputValue = value.replace(/[^\d.,]/g, '');
            setNumero({ ...numero, [name]: inputValue });
        }else if(name === 'numero'){
            var inputValue = value.replace(/\D/g, '');
            if(value.length < 3){
                setNumero({ ...numero, ['numero']: inputValue, ['comb']:'' });
            }else{
                setNumero({ ...numero, [name]: inputValue });
            }
        }else{
            setNumero({ ...numero, [name]: value });
        }
    };

    const handleInputChangeForm = (event) => {

        const { name, value } = event.target;

        if(name === 'celular'){
            var inputValue = value.replace(/\D/g, '')
            setForm({ ...form, [name]: inputValue });;
        }else{
            setForm({ ...form, [name]: value });
        }
    };


    const loteriasFiltradas = lista_loterias.filter(loteria => 
        form.loterias.includes(String(loteria.id_loteria))
    );

    const handleAgregarNumero = async () => {
        
        const numerosTemp = [...form.numeros];
        
        if(numerosTemp.some(objeto => objeto.numero === numero.numero) && props.id_categoria_modalidad_juego == 1){
            toast('El número '+numero.numero+' ya esta agregado.','Por favor ingrese otro número');
            return
        }

        if(numerosTemp.some(objeto => objeto.numero === numero.numero) && numerosTemp.some(objeto => objeto.serie === numero.serie) && props.id_categoria_modalidad_juego == 2){
            toast('El número '+numero.numero+' y serire '+numero.serie+' ya esta agregado.','Por favor ingrese otro número');
            return
        }

        setLoading(true);

        try {
        
            await validar_topes_numero(numero.numero, form.loterias, numero.valor, numero.comb, 1).then(response => {
                
                if(response.status !== undefined && response.status === 200){
                    if(response.data.bloqueos_cifras.length === 0 && response.data.bloqueos_numero.length == 0 && response.data.bloqueos_combinado.length == 0){
                        
                        numerosTemp.push(numero);
                        let totalTemp = form.total;

                        let valorTemp = numero.valor !='' ? parseFloat(numero.valor) : 0;
                        let combTemp  = numero.comb !='' ? parseFloat(numero.comb) : 0;

                        setForm({ ...form, numeros: numerosTemp, categoria_modalidad_juego: props.id_categoria_modalidad_juego,  total: totalTemp + (valorTemp * form.loterias.length) +  (combTemp * form.loterias.length)});

                        setNumero({
                            "id": null,
                            "numero": "",
                            "serie": "",
                            "astro": [],
                            "valor": "",
                            "comb": ""
                        });
                    
                    }else{

                        let texto = '';
                        let arrayBloCifras = Array.isArray(response.data.bloqueos_cifras) ? response.data.bloqueos_cifras : [] ;
                        let arrayBloNumeros = Array.isArray(response.data.bloqueos_numero) ? response.data.bloqueos_numero : [];
                        let arrayBloCombinado = Array.isArray(response.data.bloqueos_combinado) ? response.data.bloqueos_combinado : [];

                        const combinedArray = arrayBloCifras.concat(arrayBloNumeros).concat(arrayBloCombinado);
                        // Encuentra el valor mi­nimo usando reduce
                        const minValue = combinedArray.reduce((min, current) => {
                            return current.valor_disponible < min ? current.valor_disponible : min;
                        }, Infinity);

                        const uniqueLoterias = [...new Set(combinedArray.map((item) => item.loteria))];
                        const loteriasString = uniqueLoterias.join(', ');

                        let valor_disponible = 0;

                        if(arrayBloCifras.length > 0){
                            texto = 'El valor supera el tope de venta para la cantidad de cifras';
                            valor_disponible = (minValue);

                            setNumero({
                                ...numero,
                                ['valor']: valor_disponible.toString(),
                            });
                            
                        }
                        if(arrayBloNumeros.length > 0){
                            texto = 'El valor supera el tope de venta para el numero';
                            valor_disponible = (minValue);
                            
                            setNumero({
                                ...numero,
                                ['valor']: valor_disponible.toString(),
                            });                            
                        }
                        if(arrayBloCombinado.length > 0){
                            texto = 'El valor supera el tope de venta para el juego combinado';
                            valor_disponible = (minValue);

                            setNumero({
                                ...numero,
                                ['comb']: valor_disponible.toString(),
                            });
                        }
                        alerta(texto, 'Valor sugerido de venta $' +formatearMonedaValue(valor_disponible)+' | Loterias afectadas:\n'+loteriasString);
                    }
                }else{
                    alerta('No se pudo validar el numero', 'Por favor intente nuevamente, si el problema persiste contacte a soporte.');
                }
            });
        }catch (error) {
            alerta('Error no controlado al validar el numero', 'Por favor intente nuevamente, si el problema persiste contacte a soporte.');
        }

        setLoading(false);    
    };

    const handleEliminarNumero = (numero, valor, comb) => {

        let numerosTemp = form.numeros.filter((objeto) => objeto.numero !== numero);
        let totalTemp   = form.total;
        let valorTemp   = valor !='' ? parseFloat(valor) : 0;
        let combTemp    = comb !='' ? parseFloat(comb) : 0;
        setForm({ ...form, numeros: numerosTemp, total: totalTemp - ((valorTemp * form.loterias.length) + (combTemp * form.loterias.length))});
    };

    const handleConfirmarVenta = () => {

        Swal.fire({
            title: "¿Esta seguro de crear esta venta?",
            text: "Si esta seguro presione el boton Crear de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Crear'
        }).then((result) => {
            if (result.isConfirmed) {
                handleRealizarVenta();
            }
        });
    }

    const handleRealizarVenta = async () => {

        setLoading(true);

        try {
            await venta(form).then( response => {
                            
                if(response.status === 201){
                    setIdTransaccion(response.data.id_transaccion)
                    setStep(2)
                    toast('¡ Perfecto !','Venta realizada','success');                    
                }else{
                    alerta('Se presento un error al intentar crear la venta',response.data.status_message??response.message);
                }
            });
        }catch (error) {
            alerta('Se presento un error al intentar crear la venta','Error no controlado');
        }

        setLoading(false);
    }

    const HandleFinalizarVenta = () =>{
        window.location.href = '/venta';
    }
    
    const generarPDF = async () => {

        // Configuración inicial del documento
        let doc = new jsPDF({
            orientation: 'portrait',  // Orientación vertical
            unit: 'mm',               // Usar milímetros como unidad de medida
            format: [55, 100],        // Tamaño temporal, lo recalcularemos más adelante
        });
        
        doc.setFontSize(7);
    
        try {
    
            await get_vaucher(idTransaccion).then(response => {
                
                if(response.status === 200 && response.data.status_message === 'OK') {
    
                    let data = response.data.data;
    
                    // Generar el texto del recibo
                let receiptText = 
`===============================
    RECIBO DE VENTA ${data.id_transaccion}
===============================
Fecha: ${data.fecha}
Hora:  ${data.hora}
Tipo:  ${data.modalidad}
-------------------------------
LOTERIAS
${data.loterias}
-------------------------------
NUMERO   MODALIDAD     VALOR`;
    
                    // Añadir los items
                    data.items.forEach((item) => {
                        const numero = item.numero.padEnd(6, ' ');  
                        const prefijo = item.prefijo.padEnd(8, ' '); 
                        const valor = item.valor.padStart(10, ' ');  
                        receiptText += `\n${numero}   ${prefijo}   ${valor}`;
                    });
    
                    // Añadir el pie de página
                receiptText += `
-------------------------------
Total: ${data.total}
===============================
    ¡Gracias por su compra!
===============================`;
    
                    // Dividir el texto en líneas
                    const textLines = doc.splitTextToSize(receiptText, 48); // 48mm de ancho para el texto
    
                    // Calcular la altura dinámica basada en la cantidad de líneas
                    const lineHeight = 4;  // Altura de cada línea (en mm)
                    const contentHeight = textLines.length * lineHeight; // Altura total del contenido
    
                    // Establecer la altura mínima a 90 mm
                    const minHeight = 90;
                    const totalHeight = Math.max(contentHeight + 10, minHeight); // +10 para los márgenes superior/inferior
    
                    // Crear un nuevo documento con la altura calculada
                    doc = new jsPDF({
                        orientation: 'portrait',
                        unit: 'mm',
                        format: [55, totalHeight],  // Usamos la altura calculada
                    });
    
                    // Establecer la fuente y tamaño nuevamente
                    doc.setFontSize(7);
    
                    // Posición inicial
                    let y = 5; // Margen superior de 5 mm
    
                    // Añadir las líneas al PDF
                    textLines.forEach(line => {
                        doc.text(line, 5, y); // X=5 (margen izquierdo), Y dinámico
                        y += lineHeight;      // Mover la posición Y para la siguiente línea
                    });
    
                    // Crear el Blob y abrir el PDF en una nueva pestaña
                    const pdfBlob = doc.output('blob');
                    const pdfUrl = URL.createObjectURL(pdfBlob);
                    window.open(pdfUrl);
    
                } else {
                    alerta('Error al imprimir', 'No fue posible generar la información de impresión.');
                }
            });
    
        } catch (error) {
            alerta('Error al imprimir:', error);
        }
    };

    return(
        <React.Fragment>
            <div className="modal fade" id="ModalVentaApuesta" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="ModalVentaApuesta" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Venta de apuesta</h1>
                        </div>
                        <div className="modal-body">

                            {step === 0 ?
                                <div className='select-loteria'>                         
                                    <div className='row mt-0'>
                                        <div className='col-md-12'>
                                            <table className="table table-hover table-md">
                                                <tbody>                                            
                                                {Array.isArray(lista_loterias) && props.id_categoria_modalidad_juego === 2  ? lista_loterias.filter((venta) => venta.oficial === 1).map((loteria, index) => (
                                                    <tr key={index} className='align-middle'>
                                                        <td className='text-secondary'><i className="bi bi-bank"></i> <b>{loteria.prefijo}</b></td>
                                                        {loteria.disponibilidad == 'abierta' ?
                                                                <td className=''><span className="badge text-bg-success">{loteria.disponibilidad}</span></td>
                                                            :
                                                            <td className=''><span className="badge text-bg-danger">{loteria.disponibilidad}</span></td>
                                                        }
                                                        <td><i className="bi bi-clock"></i> {loteria.hora_final}</td>
                                                        <td>
                                                            <div className="form-check text-center form-switch">
                                                                <input 
                                                                    className="form-check-input" 
                                                                    type="checkbox" 
                                                                    role="switch"
                                                                    value={loteria.id_loteria} 
                                                                    onChange={handleCheckiarLoteria} id="flexCheckDefault" 
                                                                    disabled={loteria.disponibilidad != 'abierta'}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )) : <tr></tr>}
                                                {Array.isArray(lista_loterias) && props.id_categoria_modalidad_juego !== 2  ? lista_loterias.map((loteria, index) => (
                                                    <tr key={index} className='align-middle'>
                                                        <td className='text-secondary'><i className="bi bi-bank"></i> <b>{loteria.prefijo}</b></td>
                                                        {loteria.disponibilidad == 'abierta' ?
                                                                <td className=''><span className="badge text-bg-success">{loteria.disponibilidad}</span></td>
                                                            :
                                                            <td className=''><span className="badge text-bg-danger">{loteria.disponibilidad}</span></td>
                                                        }
                                                        <td><i className="bi bi-clock"></i> {loteria.hora_final}</td>
                                                        <td>
                                                            <div className="form-check text-center form-switch">
                                                                <input 
                                                                    className="form-check-input" 
                                                                    type="checkbox" 
                                                                    role="switch"
                                                                    value={loteria.id_loteria} 
                                                                    onChange={handleCheckiarLoteria} id="flexCheckDefault" 
                                                                    disabled={loteria.disponibilidad != 'abierta'}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )) : <tr></tr>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            :''}

                            {step === 1 ?
                                <div className='select-numeros'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className="m-0">
                                                <label htmlFor="Numero" className="form-label mt-0">Número</label>
                                                <input 
                                                    type="text"
                                                    autoComplete="off" 
                                                    placeholder='Numero' 
                                                    className="form-control input-add-numero" 
                                                    id="numero" 
                                                    name="numero" 
                                                    value={numero.numero}
                                                    maxLength={4} 
                                                    onChange={handleInputChange}  
                                                />
                                            </div>                                            
                                        </div>
                                        {props.id_categoria_modalidad_juego === 2 ?
                                            <div className='col-md-2'>
                                                <div className="m-0">
                                                    <label htmlFor="serie" className="form-label mt-0">Serie</label>
                                                    <input 
                                                        type="text"
                                                        autoComplete="off" 
                                                        placeholder='Serie' 
                                                        className="form-control input-add-numero" 
                                                        id="serie" 
                                                        name="serie" 
                                                        value={numero.serie}
                                                        maxLength={1} 
                                                        onChange={handleInputChange}  
                                                    />
                                                </div>
                                            </div>
                                        :''}
                                        <div className='col-md-3'>
                                            <div className="m-0">
                                                <label htmlFor="Numero" className="form-label mt-0">Valor</label>
                                                <input 
                                                    type="text" 
                                                    autoComplete="off" 
                                                    placeholder='$0.0' 
                                                    className="form-control input-add-numero" 
                                                    id="valor" 
                                                    name="valor" 
                                                    value={numero.valor} 
                                                    onChange={handleInputChange} 
                                                />
                                            </div>
                                        </div>
                                        {props.id_categoria_modalidad_juego === 1 ?
                                        <div className='col-md-3'>
                                            <div className="m-0">
                                                <label htmlFor="Numero" className="form-label mt-0">Combinado</label>
                                                <input 
                                                    type="text" 
                                                    autoComplete="off" 
                                                    placeholder='$0.0' 
                                                    className="form-control input-add-numero"  
                                                    id="comb" 
                                                    name="comb"
                                                    disabled={numero.numero.length < 3}
                                                    value={numero.comb} onChange={handleInputChange} 
                                                />                                                
                                            </div>
                                        </div>
                                        :''}
                                        <div className='col-md-2'> 
                                        <label htmlFor="Numero" className="form-label mt-0"></label>                               
                                            <button 
                                                type="submit" 
                                                className="btn btn-outline-success btn-lg mt-2"                                                
                                                disabled={(numero.numero.length == 0 || (numero.valor <= 0 && numero.comb <= 0)) && loading == false}
                                                onClick={handleAgregarNumero}
                                            >AGREGAR
                                            </button>
                                        </div>
                                    </div>

                                    {loading ?
                                        <div className="d-flex justify-content-center align-items-center mt-4">
                                            <div className="spinner-border" role="status"></div>
                                            <h3 className='mb-0 mx-2'>Consultando...</h3>               
                                        </div>
                                    :''}
                                    <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <div className='card-table-add-numeros'>
                                                { form.numeros.length > 0 ?
                                                <div className='table-container'>
                                                    <div className="table-responsive-sm">
                                                        <table className='table table-hover'>
                                                            <thead>
                                                                <tr>
                                                                    <th className='text-secondary text-center'>Número</th>
                                                                    <th className='text-secondary'>Valor</th>
                                                                    <th className='text-secondary'>Comb...</th>
                                                                    <th className='text-secondary'></th>
                                                                </tr>
                                                            </thead>                                                
                                                            <tbody>
                                                            {Array.isArray(form.numeros) ? form.numeros.map((numero, index) => (
                                                                <tr key={index} className='align-middle'>
                                                                    <th className='text-center'>{numero.numero} {numero.serie}</th>
                                                                    <th className='table-success'>${formatearMonedaValue(numero.valor)}</th>
                                                                    <th className='table-primary'>${formatearMonedaValue(numero.comb)}</th>
                                                                    <th className='table-danger'>
                                                                        <i 
                                                                            className="bi bi-trash text-danger" 
                                                                            style={{cursor:'pointer'}}
                                                                            onClick={() => handleEliminarNumero(numero.numero, numero.valor, numero.comb)}
                                                                        >                                                                            
                                                                        </i>
                                                                    </th>
                                                                </tr>
                                                                )) : <tr></tr>}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                :
                                                <div className='text-center text-secondary my-4 d-flex  justify-content-center align-items-center flex-column' style={{ 'height' : '100px'}}>
                                                    <h3>Aún no se han agregado números.</h3>
                                                    <p>Por favor, añade al menos un número para continuar con el juego.</p>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='card-table-add-numeros'>
                                                <h5 className='m-0 text-success'>Loterias</h5>
                                                <hr className='mt-0'></hr>
                                                {loteriasFiltradas.map(loteria => (
                                                    <h6 className='m-0' key={loteria.id_loteria}>{loteria.prefijo}</h6>
                                                ))}
                                            </div>
                                            <div className='card-table-add-numeros mt-3'>
                                                <h5 className='m-0 text-success'>Datos del cliente</h5>
                                                <hr className='mt-0'></hr>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className="form-floating">
                                                            <input 
                                                                type="text"
                                                                className="form-control " 
                                                                id="nombre" 
                                                                name="nombre" 
                                                                value={form.nombre} 
                                                                onChange={handleInputChangeForm} 
                                                            />
                                                            <label htmlFor="nombre" className="form-label mt-0">Nombre</label>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12 mt-3'>
                                                        <div className="form-floating">
                                                            <input 
                                                                type="text"
                                                                className="form-control " 
                                                                id="celular" 
                                                                name="celular" 
                                                                value={form.celular} 
                                                                maxLength={10}
                                                                onChange={handleInputChangeForm} 
                                                            />
                                                            <label htmlFor="nombre" className="form-label mt-0">Celular</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :''}

                            {step === 2 ?
                                <div className='text-center
                                '>
                                    <i className="bi bi-check-circle fs-1 text-success"></i>
                                    <h4>Venta Exitosa</h4>
                                    <h4>Transacción {idTransaccion}</h4>
                                    <p>Para ver mas información de esta venta vaya a el menu principal y seleccione detalle.</p>
                                </div>
                            :''}

                        </div>
                        <div className="modal-footer">
                            <div className='div-total-venta'>
                                <div className='div-texto-total'>
                                    {step === 1 ?                         
                                    <h4 className='m-0'>Total venta: <span className='text-success'><b>${formatearMonedaValue(form.total)}</b></span></h4>
                                    :''}
                                </div>

                                {step !== 2 ?
                                <div className='div-btns'>
                                    {step === 0 ?
                                        <>
                                            <button type="button" className="btn btn-secondary mx-2" data-bs-dismiss="modal" onClick={() =>setStep(0)}>Cancelar</button>
                                            <button type="button" className="btn btn-primary" onClick={handleNextStep}>Siguiente</button>
                                        </>
                                    :   
                                        <>
                                            <button type="button" className="btn btn-secondary mx-2" onClick={handleBeforeStep}>Atras</button>
                                            <button 
                                                type="button" 
                                                className="btn btn-success"
                                                disabled={(form.numeros.length <= 0 || form.total == 0) && loading == false }
                                                onClick={handleConfirmarVenta}
                                            >Crear venta
                                            </button>
                                        </>
                                    }
                                </div>
                                :   
                                    <div className='div-btns'>                                        
                                        <button 
                                            type="button" 
                                            className="btn btn-success mx-2"
                                            disabled={form.numeros.length <= 0 || form.total == 0}
                                            onClick={HandleFinalizarVenta}
                                        >Finalizar
                                        </button>
                                        <button 
                                            type="button" 
                                            className="btn btn-dark"
                                            disabled={form.numeros.length <= 0 || form.total == 0}
                                            onClick={generarPDF}
                                        >Imprimir
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapSateToProps = state => {
    return {
        loterias_hoy: state.loterias_hoy,
        id_categoria_modalidad_juego: state.id_categoria_modalidad_juego
    }
}

export default  connect(mapSateToProps, null)(VentaApuesta);