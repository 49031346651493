import React, {useEffect, useState} from "react";
import { connect } from 'react-redux';
import HeaderModulo from "../../../header/HeaderModulo";
import Header from "../../../header/Header";
import Aside from "../../../aside/Aside";
import Tabla from "./Tabla";
import EditarUsuario from "./EditarUsuario";

function Usuarios(props){

    useEffect(() => {
      
    },[]);

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <section className="section">
                    <Tabla></Tabla>
                    <EditarUsuario></EditarUsuario>
                </section>
            </main>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        data_sesion: state.data_sesion,
    }
}

export default connect(mapSateToProps,null)(Usuarios);